import React, { useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text
} from "@chakra-ui/react";
import { PageCardComponent } from "../../core/components/page-card.component";
import { OtpModalComponent } from "./otp-modal.component";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import { useTypedDispatch } from "hooks/use-typed-dispatch.hook";
import { reset } from "../state/patient.slice";
import { FaTimes } from "react-icons/fa";

interface SearchPatientComponentProps {}

export const SearchPatientComponent: React.FC<SearchPatientComponentProps> = (
  props: SearchPatientComponentProps
) => {
  const dispatch = useTypedDispatch();
  const { verificationState } = useTypedSelector((state) => state.patient);
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const handleReset = () => {
    dispatch(reset());
    setPhoneNumber("");
  };

  return (
    <PageCardComponent>
      <Text>Your Phone Number</Text>

      <Stack direction={{ base: "column", lg: "row" }}>
        <InputGroup size={{ base: "md", lg: "lg" }}>
          <InputLeftAddon children={"+88"} />
          <Input
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            disabled={verificationState === "verified"}
          />
        </InputGroup>

        <Stack direction="row">
          <OtpModalComponent phoneNumber={phoneNumber} />
          <Button
            width="50%"
            size={{ base: "md", lg: "lg" }}
            leftIcon={<FaTimes />}
            colorScheme={"red"}
            onClick={handleReset}
          >
            Reset
          </Button>
        </Stack>
      </Stack>
    </PageCardComponent>
  );
};
