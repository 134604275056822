import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Address, Patient, PatientWithDetails, Person } from "app/api/type";
import dayjs from "dayjs";

type InitialState = {
  verificationState: "initialized";
  sessionExpirationTime: null;
  phoneNumber: null;
  nextOtpSendingTime: null;
  otp: null;
  listOfPatientWithDetails: [];
};

type UnverifiedState = {
  verificationState: "unverified";
  sessionExpirationTime: null;
  phoneNumber: string;
  nextOtpSendingTime: Date;
  otp: null;
  listOfPatientWithDetails: [];
};

type VerifiedState = {
  verificationState: "verified";
  sessionExpirationTime: Date;
  phoneNumber: string;
  nextOtpSendingTime: Date;
  otp: string;
  listOfPatientWithDetails: {
    patient: Patient;
    person: Person;
    address?: Address;
  }[];
};

type PatientState = InitialState | UnverifiedState | VerifiedState;

const initialState = {
  verificationState: "initialized",
  sessionExpirationTime: null,
  phoneNumber: null,
  nextOtpSendingTime: null,
  otp: null,
  listOfPatientWithDetails: []
} as PatientState;

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    sendOtpRequest: (
      state,
      action: PayloadAction<{
        phoneNumber: string;
      }>
    ) => {
      state = {
        verificationState: "unverified",
        sessionExpirationTime: null,
        phoneNumber: action.payload.phoneNumber,
        nextOtpSendingTime: dayjs().add(1, "minutes").toDate(),
        otp: null,
        listOfPatientWithDetails: []
      };
      return state;
    },

    verifyPhoneNumber: (
      state,
      action: PayloadAction<{
        otp: string;
      }>
    ) => {
      state = {
        verificationState: "verified",
        sessionExpirationTime: dayjs().add(10, "minutes").toDate(),
        phoneNumber: state.phoneNumber!,
        nextOtpSendingTime: state.nextOtpSendingTime!,
        otp: action.payload.otp,
        listOfPatientWithDetails: state.listOfPatientWithDetails
      };
      return state;
    },

    registerNewPatient: (
      state,
      action: PayloadAction<{
        patientWithDetails: {
          patient: Patient;
          person: Person;
          address?: Address;
        };
      }>
    ) => {
      state.listOfPatientWithDetails = [
        action.payload.patientWithDetails,
        ...state.listOfPatientWithDetails
      ];
    },

    savePatientList: (
      state,
      action: PayloadAction<{
        listOfPatientWithDetails: PatientWithDetails[];
      }>
    ) => {
      state.listOfPatientWithDetails = action.payload.listOfPatientWithDetails;
      return state;
    },

    reset: (state) => {
      state = {
        verificationState: "initialized",
        sessionExpirationTime: null,
        phoneNumber: null,
        nextOtpSendingTime: null,
        otp: null,
        listOfPatientWithDetails: []
      };
      return state;
    }
  }
});

export const {
  sendOtpRequest,
  verifyPhoneNumber,
  registerNewPatient,
  savePatientList,
  reset
} = patientSlice.actions;
