import {
  Button,
  Heading,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import { SupportingOrganizationComponent } from "modules/patient/components/supporting-organization.component";
import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BindulogicCreditsComponent } from "./bindulogic-credits.component";
import { PageFooterComponent } from "./page-footer.component";
interface AboutUsComponentProps {}

export const AboutUsComponent: React.FC<AboutUsComponentProps> = (
  props: AboutUsComponentProps
) => {
  const navigate = useNavigate();
  return (
    <Stack height="100vh" justify="space-between">
      <Stack align="flex-end">
        <Button
          colorScheme="red"
          margin="4"
          leftIcon={<FaArrowLeft />}
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Stack>
      <Stack align={"center"}>
        <Heading textDecoration={"underline"}>About Us</Heading>
        <Text fontSize="xl" fontWeight={"bold"} pb="4">
          A Tradition Of High Quality Software Solutions
        </Text>
        <Stack
          textAlign={"justify"}
          justify={"center"}
          align="center"
          width={{ base: "90%", md: "50%" }}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          <UnorderedList>
            <ListItem pb={"4"}>
              Binduhealth, a concern of BINDULOGIC LIMITED, is a user
              experience-focused software development firm, specialising in
              Healthcare Industry. We create scalable custom enterprise-class
              applications.
            </ListItem>
            <ListItem pb={"4"}>
              Binduhealth, a concern of BINDULOGIC LIMITED, is on a mission to
              elevate healthcare to new heights through innovative software
              solutions. As a specialised healthcare software development
              company, we specialise in providing healthcare automation software
              and localised customisations of OpenMRS for global use. In
              addition, we are also proud to offer OpenSRP location-based mobile
              app health technology products.
            </ListItem>
            <ListItem pb={"4"}>
              Our vision is to bring the world together through technology,
              creating a unified platform for healthcare automation that
              transcends borders and boundaries. We are proud to represent the
              software prowess of Bangladesh and aim to make our country a
              source of pride in the global arena.
            </ListItem>
            <ListItem>
              Guided by our commitment to international standards and seamless
              integration, we dream of a future where healthcare is accessible,
              efficient, and centralised for all, powered by the expertise of
              Binduhealth.
            </ListItem>
          </UnorderedList>
        </Stack>
      </Stack>
      <Stack>
        <SupportingOrganizationComponent />
        <BindulogicCreditsComponent />
      </Stack>
    </Stack>
  );
};
