import { IDashboard, IPage } from "app/types";
import { patientPublicRoutes } from "modules/patient/routes";

// Routes that are publicly available (only if not authenticated)
export const authRoutes: IPage[] = [];

// Routes that are privately available (only if authenticated)
export const dashboardList: IDashboard[] = [];

// Routes that are always available (both authenticated or not authenticated)
export const publicRoutes: IPage[] = [...patientPublicRoutes];

// Starting route
export const rootRoute = "/patient/booking";
