import React from "react";
import { Center, Text } from "@chakra-ui/react";
import { PageCardComponent } from "../../core/components/page-card.component";
import { PatientSummaryComponent } from "./patient-summary.component";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import { RegisterPatientComponent } from "./register-patient.component";

interface FoundPatientListComponentProps {}

export const FoundPatientListComponent: React.FC<
  FoundPatientListComponentProps
> = (props: FoundPatientListComponentProps) => {
  const {
    listOfPatientWithDetails: foundPatients,
    verificationState,
    phoneNumber
  } = useTypedSelector((state) => state.patient);

  return (
    <PageCardComponent>
      {verificationState === "verified" ? (
        <RegisterPatientComponent phoneNumber={phoneNumber} />
      ) : (
        <></>
      )}
      {verificationState === "verified" ? (
        foundPatients.length ? (
          foundPatients.map((patient) => {
            return (
              <PatientSummaryComponent
                key={patient.patient.id}
                targetPatient={patient}
              />
            );
          })
        ) : (
          <Center>
            <Text color="gray.300">No patients found</Text>
          </Center>
        )
      ) : (
        <></>
      )}
    </PageCardComponent>
  );
};
