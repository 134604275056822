import React from "react";
import {
  Center,
  Heading,
  Stack,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";

interface RefundPolicyComponentProps {}

export const RefundPolicyComponent: React.FC<RefundPolicyComponentProps> = (
  props: RefundPolicyComponentProps
) => {
  return (
    <Stack>
      <Heading>Refund Policy</Heading>
      <Stack textAlign={"justify"}>
        <UnorderedList>
          <ListItem>
            All payments made through Binduhealth, a concern of BINDULOGIC LIMITED are non-refundable.
          </ListItem>
          <ListItem>
            By proceeding with the payment, you acknowledge and accept this
            policy.
          </ListItem>
          <ListItem>
            In the event of a provider cancellation, the healthcare provider may
            reschedule the appointment, but no refunds will be processed.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Stack>
  );
};
