import React from "react";
import {
  Center,
  Heading,
  Stack,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";

interface ReturnOrExchangePolicyComponentProps {}

export const ReturnOrExchangePolicyComponent: React.FC<
  ReturnOrExchangePolicyComponentProps
> = (props: ReturnOrExchangePolicyComponentProps) => {
  return (
    <Stack>
      <Heading>Return/Exchange Policy</Heading>
      <Stack textAlign={"justify"}>
        <UnorderedList>
          <ListItem>
            As Binduhealth, a concern of BINDULOGIC LIMITED offers non-tangible,
            appointment-based services, there is no return or exchange
            applicable.
          </ListItem>
          <ListItem>
            Any concerns or issues should be addressed directly with the
            healthcare provider involved.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Stack>
  );
};
