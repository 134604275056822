export const occupationList = [
  {
    value: "Accountant",
    label: "Accountant"
  },
  {
    value: "Architect",
    label: "Architect"
  },
  {
    value: "Artist",
    label: "Artist"
  },
  {
    value: "Autonomous",
    label: "Autonomous"
  },
  {
    value: "Bechelor",
    label: "Bechelor"
  },
  {
    value: "Banker",
    label: "Banker"
  },
  {
    value: "Barber",
    label: "Barber"
  },
  {
    value: "Barriester at Law",
    label: "Barriester at Law"
  },
  {
    value: "Blacksmith",
    label: "Blacksmith"
  },
  {
    value: "Boatman",
    label: "Boatman"
  },
  {
    value: "Business",
    label: "Business"
  },
  {
    value: "Cheif Justice",
    label: "Cheif Justice"
  },
  {
    value: "Cleaner",
    label: "Cleaner"
  },
  {
    value: "Contractor",
    label: "Contractor"
  },
  {
    value: "Cook",
    label: "Cook"
  },
  {
    value: "Department of Non-Diplomatic Official/Staff in Foreign Mission",
    label: "Department of Non-Diplomatic Official/Staff in Foreign Mission"
  },
  {
    value: "Department of Non-Diploma",
    label: "Department of Non-Diploma"
  },
  {
    value: "Doctor",
    label: "Doctor"
  },
  {
    value: "Driver",
    label: "Driver"
  },
  {
    value: "Engineer",
    label: "Engineer"
  },
  {
    value: "Farmer",
    label: "Farmer"
  },
  {
    value: "Fisherman",
    label: "Fisherman"
  },
  {
    value: "Government Service",
    label: "Government Service"
  },
  {
    value: "Guard",
    label: "Guard"
  },
  {
    value: "House Wife",
    label: "House Wife"
  },
  {
    value: "Journalist",
    label: "Journalist"
  },
  {
    value: "Judge",
    label: "Judge"
  },
  {
    value: "Justice",
    label: "Justice"
  },
  {
    value: "Labour",
    label: "Labour"
  },
  {
    value: "Lawyer",
    label: "Lawyer"
  },
  {
    value: "Mechanic",
    label: "Mechanic"
  },
  {
    value: "Member of Local Government",
    label: "Member of Local Government"
  },
  {
    value: "Mamber of Parliament",
    label: "Mamber of Parliament"
  },
  {
    value: "Marchent Marine Officer",
    label: "Marchent Marine Officer"
  },
  {
    value: "Minister",
    label: "Minister"
  },
  {
    value: "Nurse",
    label: "Nurse"
  },
  {
    value: "Other",
    label: "Other"
  },
  {
    value: "Painter",
    label: "Painter"
  },
  {
    value: "Permanent Officer/Staff of Autonomous Organization",
    label: "Permanent Officer/Staff of Autonomous Organization"
  },
  {
    value: "Permanent Officer/Staff of Nationalised Organization",
    label: "Permanent Officer/Staff of Nationalised Organization"
  },
  {
    value: "Pharmacist",
    label: "Pharmacist"
  },
  {
    value: "Pilot",
    label: "Pilot"
  },
  {
    value: "Politician",
    label: "Politician"
  },
  {
    value: "Porter",
    label: "Porter"
  },
  {
    value: "Potter",
    label: "Potter"
  },
  {
    value: "President",
    label: "President"
  },
  {
    value: "Prime Minister",
    label: "Prime Minister"
  },
  {
    value: "Private Service ",
    label: "Private Service "
  },
  {
    value: "Project Employee",
    label: "Project Employee"
  },
  {
    value: "Retired (Government Service)",
    label: "Retired (Government Service)"
  },
  {
    value: "Retired (Private Service)",
    label: "Retired (Private Service)"
  },
  {
    value: "Retired Technocart",
    label: "Retired Technocart"
  },
  {
    value: "Salesman",
    label: "Salesman"
  },
  {
    value: "Satuary Body",
    label: "Satuary Body"
  },
  {
    value: "Semi Government Service",
    label: "Semi Government Service"
  },
  {
    value: "Speaker",
    label: "Speaker"
  },
  {
    value: "Student",
    label: "Student"
  },
  {
    value: "Sweeper",
    label: "Sweeper"
  },
  {
    value: "Tailor",
    label: "Tailor"
  },
  {
    value: "Teacher",
    label: "Teacher"
  },
  {
    value: "Unemployed",
    label: "Unemployed"
  },
  {
    value: "Unkown",
    label: "Unkown"
  },
  {
    value: "Washerman",
    label: "Washerman"
  }
];
