import React, { useEffect } from "react";
import { Center, Stack, Text } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";

interface UnsuccessfulPaymentPageProps {}

export const UnsuccessfulPaymentPage: React.FC<UnsuccessfulPaymentPageProps> = (
  props: UnsuccessfulPaymentPageProps
) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const ticketId = searchParams.get("ticketId") as string;

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(`/patient/booking`);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Stack>
      <Center height="90vh" width="100vw">
        <Stack width="512px" spacing="12">
          <Text textAlign={"center"} fontSize={"3xl"} color="red.600">
            Ticket Booking Failed
          </Text>
          <Text textAlign={"center"} fontSize={"xl"} color="gray.600">
            Redirecting to ticket booking page...
          </Text>
        </Stack>
      </Center>
    </Stack>
  );
};
