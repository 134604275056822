import React from "react";
import {
  Center,
  Heading,
  Stack,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";

interface CancellationPolicyComponentProps {}

export const CancellationPolicyComponent: React.FC<
  CancellationPolicyComponentProps
> = (props: CancellationPolicyComponentProps) => {
  return (
    <Stack>
      <Heading>Cancellation Policy</Heading>
      <Stack textAlign={"justify"}>
        <UnorderedList>
          <ListItem>
            Cancellations are subject to the policy of the respective healthcare
            provider.
          </ListItem>
          <ListItem>
            If you wish to cancel or reschedule, you must contact the provider
            directly as soon as possible.
          </ListItem>
          <ListItem>
            Binduhealth, a concern of BINDULOGIC LIMITED is not responsible for
            any provider-specific cancellation policies or fees.
          </ListItem>
          <ListItem>
            No refunds will be issued for cancellations, as all payments are
            non-refundable.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Stack>
  );
};
