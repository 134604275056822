import { Center, IconButton, Link, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { SiCrunchbase } from "react-icons/si";
import { TermsAndConditionComponent } from "./terms-and-condition.component";

interface PageFooterComponentProps {}

export const PageFooterComponent: React.FC<PageFooterComponentProps> = () => {
  return (
    <Stack mt="4" position={"relative"} bottom={"0"} w="100%">
      <Stack
        direction={"row"}
        align={"center"}
        justify={"left"}
        textAlign={"left"}
      ></Stack>
      <Stack>
        <Stack justify={"space-between"} m="4" color="gray.500">
          <Stack
            fontSize={{ base: "xs", sm: "sm" }}
            direction={"row"}
            justify={"space-between"}
          >
            <Link href="/terms-and-conditions">Terms & Conditions</Link>
            <Link href="/terms-and-conditions">Privacy Policy </Link>
            <Link href="/terms-and-conditions">Delivery Policy</Link>
            <Link href="/terms-and-conditions">Refund Policy</Link>
            <Link href="/terms-and-conditions">Return/Exchange Policy</Link>
            <Link href="/terms-and-conditions">Cancellation Policy</Link>
            <Link href="/about">About Us</Link>
          </Stack>
        </Stack>
        <Center>
          <Text pb="4" fontSize={"sm"} color="gray.500">
            © 2024 Bindulogic Limited All rights reserved.
          </Text>
        </Center>
      </Stack>
    </Stack>
  );
};
