import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Center,
  Spinner
} from "@chakra-ui/react";
import { PaginationState } from "@tanstack/react-table";
import { FaHistory } from "react-icons/fa";
import dayjs from "dayjs";
import {
  useRequestAamarpayPaymentMutation,
  useGetHospitalQuery,
  useGetPatientTicketHistoryQuery,
  useStartPaymentMutation
} from "../api";
import { printTicket } from "utils/jspdf/print-ticket.util";
import { PatientWithDetails } from "app/api/type";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PrintableOpdTicketComponent } from "./printable-opd-ticket.component";
import { TableGenerator } from "../../core/components/table-generator.component";
import { useNavigate } from "react-router-dom";
import PrintButton from "../../core/components/print-button.component";

interface TicketHistoryModalComponentProps {
  patientWithDetails: PatientWithDetails;
}

export const TicketHistoryModalComponent: React.FC<
  TicketHistoryModalComponentProps
> = (props: TicketHistoryModalComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const today = dayjs().startOf("day");

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  const getPatientTicketHistoryResult = useGetPatientTicketHistoryQuery({
    patientId: props.patientWithDetails.patient.id,
    pageIndex: pageIndex,
    pageSize: pageSize
  });
  const [isLoading, setIsLoading] = useState(false);
  const [startPayment, startPaymentResult] = useStartPaymentMutation();

  const getHospitalResult = useGetHospitalQuery({});

  const tableData = getPatientTicketHistoryResult.data?.listOfTickets.map(
    (ticketWithDetails, index) => {
      const TicketPrintButton = getHospitalResult.data ? (
        ticketWithDetails.ticket.isPaid ? (
          <PrintButton
            doc={
              <PrintableOpdTicketComponent
                ticketWithDetails={ticketWithDetails}
                patientWithDetails={{
                  patient: ticketWithDetails.patient,
                  person: ticketWithDetails.person
                }}
                personIdentifiers={ticketWithDetails.personIdentifiers}
                hospitalLocation={getHospitalResult.data!.location}
                printingLocation={ticketWithDetails.sourceLocation}
                printerPerson={props.patientWithDetails.person}
              />
            }
            title={ticketWithDetails.ticket.id}
            buttonText={"Print"}
            colorScheme={"blue"}
          />
        ) : dayjs(ticketWithDetails.ticket.issueTime).isSame(today, "day") ? (
          <Button
            colorScheme="blue"
            onClick={() => {
              setIsLoading(true);
              startPayment({
                data: {
                  ticketId: ticketWithDetails.ticket.id
                }
              });
            }}
            isLoading={isLoading}
          >
            Pay Now
          </Button>
        ) : (
          <Button colorScheme="blue" isDisabled>
            Expired
          </Button>
        )
      ) : (
        <> </>
      );
      return {
        "No.": pageIndex * pageSize + index + 1,
        "Ticket ID": ticketWithDetails.ticket.id,
        "Ticket Category": ticketWithDetails.ticket.ticketCategoryName,
        "Issue Time": dayjs(ticketWithDetails.ticket.issueTime).format(
          "hh:mm a, MMM DD, YYYY"
        ),
        "Booking Date": dayjs(ticketWithDetails.ticket.bookedFor).format(
          "MMM DD, YYYY"
        ),
        "Issued From": ticketWithDetails.sourceLocation.name,
        "Issued To": ticketWithDetails.assignedLocation.name,
        "Patient ID": ticketWithDetails.patient.id,
        "Patient Name": ticketWithDetails.person.name || "-",
        Symptoms: ticketWithDetails.ticket.symptom || "-",
        Print: TicketPrintButton
      };
    }
  );

  useEffect(() => {
    if (startPaymentResult.isSuccess) {
      window.location.href = startPaymentResult.data.url;
    }
  }, [startPaymentResult.data]);

  useEffect(() => {
    if (isOpen) {
      // Fetch the updated ticket history data
      getPatientTicketHistoryResult.refetch();
    }
  }, [isOpen]);

  return (
    <>
      <Button colorScheme={"blue"} leftIcon={<FaHistory />} onClick={onOpen}>
        Ticket History
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="full"
        closeOnOverlayClick={false}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Ticket History (Patient: {props.patientWithDetails.person.name})
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="6">
            {getPatientTicketHistoryResult.data ? (
              <TableGenerator
                data={tableData!}
                headers={[
                  "No.",
                  "Ticket ID",
                  "Ticket Category",
                  "Issue Time",
                  "Booking Date",
                  "Issued From",
                  "Issued To",
                  "Patient ID",
                  "Patient Name",
                  "Symptoms",
                  "Print"
                ]}
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPagination={setPagination}
                pageCount={getPatientTicketHistoryResult.data.pageCount}
              />
            ) : (
              <Center>
                <Spinner />
              </Center>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
