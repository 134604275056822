import React from "react";
import { Center, Stack } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { TicketDetailsComponent } from "../components/ticket-details.component";

interface TicketDetailsPageProps {}

export const TicketDetailsPage: React.FC<TicketDetailsPageProps> = (
  props: TicketDetailsPageProps
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const ticketId = searchParams.get("ticketId") as string;

  return (
    <Stack>
      <Center height="90vh" width="100vw">
        <Stack width="512px" spacing="12">
          <TicketDetailsComponent ticketId={ticketId} />
        </Stack>
      </Center>
    </Stack>
  );
};
