import { forwardRef, LegacyRef, useCallback, useEffect, useRef } from 'react'
import { PDFDownloadLink, } from '@react-pdf/renderer'
import { Button, Modal, VisuallyHidden, useDisclosure } from '@chakra-ui/react';


interface PrintButtonProps {
  doc: React.ReactElement;
  title?: string;
  buttonText?: string;
  colorScheme?: string;
  isDisabled?: boolean;  
  isLoading?: boolean;
  size?: string;
  variant?: string;
  loadingText?: string;
}


const PrintButton : React.FC< 
 PrintButtonProps
> = (props: PrintButtonProps) => {
  useEffect(() => {
  }, []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const linkRef = useRef<HTMLButtonElement>(null)

  const onLoadingFinished = useCallback(() => {
    const elem = linkRef?.current;
    if (elem !== null) {
      elem.click();
    };
    setTimeout(() => {
      onClose();
    }, 3000);
  }, [])

  return (
    <>
      <Button 
        isLoading={isOpen || props.isLoading}  
        onClick={onOpen}
        colorScheme={props.colorScheme || 'blue'}
        isDisabled={props.isDisabled || false}
        size={props.size || 'md'}
        variant={props.variant || 'solid'}
        loadingText={props.loadingText || ""}
        >
          {props.buttonText || 'Download PDF'}
        </Button>
      <VisuallyHidden>
      <Modal isOpen={isOpen} onClose={onClose}>
      <PDFDownloadLink
        document={props.doc}
        fileName={`${props.title}.pdf` || 'document.pdf'}
      >
        {({ url, blob, loading, error}) => 
        <RefButton ref={linkRef} loading={loading} onLoadingFinished={onLoadingFinished} />
        }
      </PDFDownloadLink>
      </Modal>
      </VisuallyHidden>
    </>
  )
}


const RefButton = forwardRef((props: { loading: boolean, onLoadingFinished: () => void }, ref: LegacyRef<HTMLButtonElement>) => {
  let loading = props.loading;
  let onLoadingFinished = props.onLoadingFinished;
  useEffect(() => {
    if (!loading) {
      onLoadingFinished();
    }
  }, [loading])

  return (
    <button ref={ref}>
      {props.loading ? 'Loading...' : 'Download PDF'}
    </button>
  )
})

export default PrintButton;