import { Stack } from "@chakra-ui/react";
import React from "react";
import { AboutUsComponent } from "../components/about-us.component";

interface AboutUsPageProps {}

export const AboutUsPage: React.FC<AboutUsPageProps> = (
  props: AboutUsPageProps
) => {
  return (
    <Stack>
      <AboutUsComponent />
    </Stack>
  );
};
