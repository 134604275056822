import React from "react";
import { Center, Divider, Spacer, Stack, Text } from "@chakra-ui/layout";
import { TermsAndConditionComponent } from "../components/terms-and-condition.component";
import { PrivacyPolicyComponent } from "../components/privacy-policy.component";
import { DeliveryPolicyComponent } from "../components/delivery-policy.component";
import { RefundPolicyComponent } from "../components/refund-policy.component";
import { ReturnOrExchangePolicyComponent } from "../components/return-or-exchange-policy.component";
import { CancellationPolicyComponent } from "../components/cancellation-policy.component";
import { Button } from "@chakra-ui/react";
import { BindulogicCreditsComponent } from "../components/bindulogic-credits.component";
import { SupportingOrganizationComponent } from "modules/patient/components/supporting-organization.component";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

interface TermsPrivacyRefundPoliciesPageProps {}

export const TermsPrivacyRefundPoliciesPage: React.FC<
  TermsPrivacyRefundPoliciesPageProps
> = (props: TermsPrivacyRefundPoliciesPageProps) => {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack align="flex-end">
        <Button
          colorScheme="red"
          margin="4"
          leftIcon={<FaArrowLeft />}
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Stack>
      <Stack width={"100%"} alignItems={"center"} justify={"center"}>
        <Stack width={["80%", "40%"]}>
          <TermsAndConditionComponent />
          <Spacer />
          <PrivacyPolicyComponent />
          <Spacer />
          <DeliveryPolicyComponent />
          <Spacer />
          <RefundPolicyComponent />
          <Spacer />
          <ReturnOrExchangePolicyComponent />
          <Spacer />
          <CancellationPolicyComponent />
        </Stack>
      </Stack>
      <SupportingOrganizationComponent />
      <BindulogicCreditsComponent />
    </Stack>
  );
};
