export namespace RegexMatcher {
  export const nationalIdentification = /^(\d{10}|\d{13}|\d{17})$/;
  export const phoneNumber = /^01\d{9}$/;
  export const ticketId = /^RX\d{10}$/;
  export const patientId = /^PT\d{10}$/;
  export const voucherId = /^(PV|IV)\d{10}$/;
  export const healthId = /^[1-9]\d{10}$/;

  export function match(targetString: string, regex: RegExp): boolean {
    return regex.test(targetString);
  }
}
