import React from "react";
import {
  Center,
  Heading,
  Stack,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";

interface TermsAndConditionComponentProps {}

export const TermsAndConditionComponent: React.FC<
  TermsAndConditionComponentProps
> = (props: TermsAndConditionComponentProps) => {
  return (
    <Stack>
      <Heading>Terms & Conditions</Heading>
      <Stack textAlign={"justify"}>
        <UnorderedList>
          <ListItem>
            By using the services of Binduhealth, a concern of BINDULOGIC LIMITED, you agree to be bound
            by these Terms & Conditions.
          </ListItem>
          <ListItem>
            All fees collected through our platform are for the purpose of
            booking appointments and consultations with healthcare providers.
          </ListItem>
          <ListItem>
            Fees are non-refundable, and payment confirms your commitment to the
            scheduled service.
          </ListItem>
          <ListItem>
            Any changes to your appointment must be communicated directly with
            the healthcare provider.
          </ListItem>
          <ListItem>
            Binduhealth, a concern of BINDULOGIC LIMITED is not responsible for the quality of services
            provided by healthcare professionals or any disputes arising
            therefrom.
          </ListItem>
          <ListItem>
            We reserve the right to amend these terms at any time without prior
            notice.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Stack>
  );
};
