import React from "react";
import {
  Center,
  Heading,
  Stack,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";

interface PrivacyPolicyComponentProps {}

export const PrivacyPolicyComponent: React.FC<PrivacyPolicyComponentProps> = (
  props: PrivacyPolicyComponentProps
) => {
  return (
    <Stack>
      <Heading>Privacy Policy</Heading>
      <Stack textAlign={"justify"}>
        <UnorderedList>
          <ListItem>
            Binduhealth, a concern of BINDULOGIC LIMITED is committed to
            protecting your personal data. We collect only the information
            necessary for processing payments and facilitating appointments.
          </ListItem>
          <ListItem>
            Your data will not be shared with third parties, except as required
            by law or necessary for payment processing.
          </ListItem>
          <ListItem>
            All transactions are secured with industry-standard encryption to
            protect your information.
          </ListItem>
          <ListItem>
            You have the right to access, correct, or delete your personal data
            by contacting us.
          </ListItem>
          <ListItem>
            We may use anonymized data for analytical purposes to improve our
            services.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Stack>
  );
};
