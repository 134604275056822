export const divisions = [
  {
    divisionName: "DHAKA",
    divisionCode: 30,
    districts: [
      {
        districtName: "DHAKA",
        districtCode: 26,
        subdistricts: [
          {
            subdistrictName: "ADABOR",
            subdistrictCode: 2,
            unions: [
              { unionName: "WARD NO-30 (43)", unionCode: 30 },
              { unionName: "WARD NO-33 (PART) (46)", unionCode: 33 }
            ]
          },
          {
            subdistrictName: "BADDA",
            subdistrictCode: 4,
            unions: [
              { unionName: "WARD NO-17 (PART)", unionCode: 17 },
              { unionName: "WARD NO-21", unionCode: 21 },
              { unionName: "BADDA", unionCode: 13 },
              { unionName: "BERAID", unionCode: 19 },
              { unionName: "SATARKUL", unionCode: 82 }
            ]
          },
          {
            subdistrictName: "BANGSHAL",
            subdistrictCode: 5,
            unions: [
              { unionName: "WARD NO-27 (63) (PART)", unionCode: 27 },
              { unionName: "WARD NO-30 (66) (PART)", unionCode: 30 },
              { unionName: "WARD NO-31 (67) (PART)", unionCode: 31 },
              { unionName: "WARD NO-32 (68) (PART)", unionCode: 32 },
              { unionName: "WARD NO-33 (69)", unionCode: 33 },
              { unionName: "WARD NO-34 (70)", unionCode: 34 },
              { unionName: "WARD NO-35 (71) (PART)", unionCode: 35 }
            ]
          },
          {
            subdistrictName: "BIMAN BANDAR",
            subdistrictCode: 6,
            unions: [
              { unionName: "WARD NO-01(PART)", unionCode: 1 },
              { unionName: "WARD NO-98(REST. AREA PART)", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "BANANI",
            subdistrictCode: 7,
            unions: [
              { unionName: "WARD NO_19 (PART)", unionCode: 19 },
              { unionName: "WARD NO-20 (PART)", unionCode: 20 }
            ]
          },
          {
            subdistrictName: "CANTONMENT",
            subdistrictCode: 8,
            unions: [
              { unionName: "WARD NO-15 (PART)", unionCode: 15 },
              { unionName: "WARD NO-98 (REST. AREA)", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "CHAK BAZAR",
            subdistrictCode: 9,
            unions: [
              { unionName: "WARD NO-27 (63) (PART)", unionCode: 27 },
              { unionName: "WARD NO-28 (64)", unionCode: 28 },
              { unionName: "WARD NO-29 (65)", unionCode: 29 },
              { unionName: "WARD NO-30 (66) (PART)", unionCode: 30 },
              { unionName: "WARD NO-31 (67) (PART)", unionCode: 31 }
            ]
          },
          {
            subdistrictName: "DAKSHINKHAN",
            subdistrictCode: 10,
            unions: [{ unionName: "DAKSHINKHAN(PART)", unionCode: 38 }]
          },
          {
            subdistrictName: "DARUS SALAM",
            subdistrictCode: 11,
            unions: [
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 }
            ]
          },
          {
            subdistrictName: "DEMRA",
            subdistrictCode: 12,
            unions: [
              { unionName: "DEMRA", unionCode: 65 },
              { unionName: "MATUAIL (PART)", unionCode: 80 },
              { unionName: "SARALIA", unionCode: 93 }
            ]
          },
          {
            subdistrictName: "DHAMRAI",
            subdistrictCode: 14,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMTA", unionCode: 10 },
              { unionName: "BAISAKANDA", unionCode: 11 },
              { unionName: "BALIA", unionCode: 17 },
              { unionName: "BHARARIA", unionCode: 23 },
              { unionName: "CHAUHAT", unionCode: 29 },
              { unionName: "DHAMRAI", unionCode: 35 },
              { unionName: "GANGUTIA", unionCode: 41 },
              { unionName: "JADABPUR", unionCode: 47 },
              { unionName: "KULLA", unionCode: 53 },
              { unionName: "KUSHURA", unionCode: 59 },
              { unionName: "NANNAR", unionCode: 65 },
              { unionName: "ROWAIL", unionCode: 71 },
              { unionName: "SANORA", unionCode: 77 },
              { unionName: "SOMBHAG", unionCode: 83 },
              { unionName: "SUAPUR", unionCode: 88 },
              { unionName: "SUTI PARA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DHANMONDI(PART)",
            subdistrictCode: 16,
            unions: [
              { unionName: "WARD NO-14 (48) (PART)", unionCode: 14 },
              { unionName: "WARD NO-15 (49)", unionCode: 15 },
              { unionName: "WARD NO-34 (PART) (47)", unionCode: 34 }
            ]
          },
          {
            subdistrictName: "DOHAR",
            subdistrictCode: 18,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BILASPUR", unionCode: 13 },
              { unionName: "KUSHUMHATI", unionCode: 21 },
              { unionName: "MAHMUDPUR", unionCode: 31 },
              { unionName: "MUKSUDPUR", unionCode: 42 },
              { unionName: "NARISHA", unionCode: 52 },
              { unionName: "NAYABARI", unionCode: 63 },
              { unionName: "ROYPARA", unionCode: 73 },
              { unionName: "SUTAR PARA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "BHASAN TEK",
            subdistrictCode: 21,
            unions: [{ unionName: "WARD NO-15 (PART)", unionCode: 15 }]
          },
          {
            subdistrictName: "BHATARA",
            subdistrictCode: 22,
            unions: [
              { unionName: "WARD NO-17 (PART)", unionCode: 17 },
              { unionName: "WARD NO-18 (PART)", unionCode: 18 },
              { unionName: "BHATARA", unionCode: 57 }
            ]
          },
          {
            subdistrictName: "GENDARIA",
            subdistrictCode: 24,
            unions: [
              { unionName: "WARD NO-40 (76) (PART)", unionCode: 40 },
              { unionName: "WARD NO-44 (80) (PART)", unionCode: 44 },
              { unionName: "WARD NO-45 (81)", unionCode: 45 },
              { unionName: "WARD NO-46 (82)", unionCode: 46 }
            ]
          },
          {
            subdistrictName: "GULSHAN",
            subdistrictCode: 26,
            unions: [
              { unionName: "WARD NO-18 (PART)", unionCode: 18 },
              { unionName: "WARD NO-19 (PART)", unionCode: 19 }
            ]
          },
          {
            subdistrictName: "HAZARIBAGH(PART)",
            subdistrictCode: 28,
            unions: [
              { unionName: "WARD NO-14 (48) (PART)", unionCode: 14 },
              { unionName: "WARD NO-22 (58)", unionCode: 22 },
              { unionName: "WARD NO-33 (PART) (46)", unionCode: 33 }
            ]
          },
          {
            subdistrictName: "JATRABARI",
            subdistrictCode: 29,
            unions: [
              { unionName: "WARD NO-40 (76) (PART)", unionCode: 40 },
              { unionName: "WARD NO-48 (84)", unionCode: 48 },
              { unionName: "WARD NO-49 (85)", unionCode: 49 },
              { unionName: "WARD NO-50 (86)", unionCode: 50 },
              { unionName: "DHANIA (PART)", unionCode: 35 },
              { unionName: "MATUAIL (PART)", unionCode: 80 }
            ]
          },
          {
            subdistrictName: "KAFRUL",
            subdistrictCode: 30,
            unions: [
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-14 (PART)", unionCode: 14 },
              { unionName: "WARD NO-16 (PART)", unionCode: 16 }
            ]
          },
          {
            subdistrictName: "KADAMTALI",
            subdistrictCode: 32,
            unions: [
              { unionName: "WARD NO-52 (88)", unionCode: 52 },
              { unionName: "WARD NO-53 (89)", unionCode: 53 },
              { unionName: "DHANIA (PART)", unionCode: 35 },
              { unionName: "MATUAIL(PART)", unionCode: 80 },
              { unionName: "SHYAMPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "KALABAGAN",
            subdistrictCode: 33,
            unions: [
              { unionName: "WARD NO-16 (50)", unionCode: 16 },
              { unionName: "WARD NO-17 (51) (PART)", unionCode: 17 }
            ]
          },
          {
            subdistrictName: "KAMRANGIR CHAR",
            subdistrictCode: 34,
            unions: [{ unionName: "SULTANGANJ", unionCode: 51 }]
          },
          {
            subdistrictName: "KHILGAON",
            subdistrictCode: 36,
            unions: [
              { unionName: "WARD NO-01 (24)", unionCode: 1 },
              { unionName: "WARD NO-02 (25)", unionCode: 2 },
              { unionName: "WARD NO-03 (26) (PART)", unionCode: 3 },
              { unionName: "DAKSHINGAON (PART)", unionCode: 60 },
              { unionName: "NASIRABAD", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "KHILKHET",
            subdistrictCode: 37,
            unions: [
              { unionName: "WARD NO-17 (PART)", unionCode: 17 },
              { unionName: "DAKSHINKHAN (PART)", unionCode: 38 },
              { unionName: "DUMNI", unionCode: 49 }
            ]
          },
          {
            subdistrictName: "KERANIGANJ",
            subdistrictCode: 38,
            unions: [
              { unionName: "AGANAGAR", unionCode: 11 },
              { unionName: "BASTA", unionCode: 13 },
              { unionName: "HAZRATPUR", unionCode: 17 },
              { unionName: "KALATIA", unionCode: 25 },
              { unionName: "KALINDI", unionCode: 34 },
              { unionName: "KONDA", unionCode: 43 },
              { unionName: "RUHITPUR", unionCode: 51 },
              { unionName: "SAKTA", unionCode: 60 },
              { unionName: "SUBHADYA", unionCode: 69 },
              { unionName: "TARANAGAR", unionCode: 77 },
              { unionName: "TEGHARIA", unionCode: 86 },
              { unionName: "ZINJIRA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KOTWALI",
            subdistrictCode: 40,
            unions: [
              { unionName: "WARD NO-32 (68) (PART)", unionCode: 32 },
              { unionName: "WARD NO-35 (71) (PART)", unionCode: 35 },
              { unionName: "WARD NO-36 (72)", unionCode: 36 },
              { unionName: "WARD NO-37 (73)", unionCode: 37 }
            ]
          },
          {
            subdistrictName: "LALBAGH",
            subdistrictCode: 42,
            unions: [
              { unionName: "WARD NO-20 (56) (PART)", unionCode: 20 },
              { unionName: "WARD NO-23 (59)", unionCode: 23 },
              { unionName: "WARD NO-24 (60)", unionCode: 24 },
              { unionName: "WARD NO-25 (61)", unionCode: 25 },
              { unionName: "WARD NO-26 (62)", unionCode: 26 },
              { unionName: "WARD NO-55 (91)", unionCode: 55 },
              { unionName: "WARD NO-56 (92)", unionCode: 56 }
            ]
          },
          {
            subdistrictName: "MIRPUR",
            subdistrictCode: 48,
            unions: [
              { unionName: "WARD NO-07 (PART)", unionCode: 7 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14 (PART)", unionCode: 14 }
            ]
          },
          {
            subdistrictName: "MOHAMMADPUR(PART)",
            subdistrictCode: 50,
            unions: [
              { unionName: "WARD NO-29 (42)", unionCode: 29 },
              { unionName: "WARD NO-31 (44)", unionCode: 31 },
              { unionName: "WARD NO-32 (45)", unionCode: 32 },
              { unionName: "WARD NO-33 (PART) (46)", unionCode: 33 },
              { unionName: "WARD NO-34 (PART) (47)", unionCode: 34 }
            ]
          },
          {
            subdistrictName: "MOTIJHEEL",
            subdistrictCode: 54,
            unions: [
              { unionName: "WARD NO-08 (PART) (31)", unionCode: 8 },
              { unionName: "WARD NO-09 (32)", unionCode: 9 },
              { unionName: "WARD NO-10 (33)", unionCode: 10 }
            ]
          },
          {
            subdistrictName: "MUGDA PARA",
            subdistrictCode: 57,
            unions: [
              { unionName: "WARD NO-06 (29)", unionCode: 6 },
              { unionName: "WARD NO-07 (30)", unionCode: 7 },
              { unionName: "MANDA", unionCode: 82 }
            ]
          },
          {
            subdistrictName: "NAWABGANJ",
            subdistrictCode: 62,
            unions: [
              { unionName: "AGLA", unionCode: 11 },
              { unionName: "BAKSHANAGAR", unionCode: 13 },
              { unionName: "BANDURA", unionCode: 20 },
              { unionName: "BARRAH", unionCode: 27 },
              { unionName: "BARUAKHALI", unionCode: 33 },
              { unionName: "CHURAIN", unionCode: 40 },
              { unionName: "GALIMPUR", unionCode: 47 },
              { unionName: "JANTRAIL", unionCode: 54 },
              { unionName: "JOYKRISHNAPUR", unionCode: 61 },
              { unionName: "KAILAIL", unionCode: 67 },
              { unionName: "KALAKOPA", unionCode: 74 },
              { unionName: "NAYANSREE", unionCode: 81 },
              { unionName: "SHIKARI PARA", unionCode: 88 },
              { unionName: "SHOLLA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NEW MARKET",
            subdistrictCode: 63,
            unions: [{ unionName: "WARD NO-18 (52)", unionCode: 18 }]
          },
          {
            subdistrictName: "PALLABI",
            subdistrictCode: 64,
            unions: [
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06 (PART)", unionCode: 6 },
              { unionName: "WARD NO-15(PART)", unionCode: 15 }
            ]
          },
          {
            subdistrictName: "PALTAN",
            subdistrictCode: 65,
            unions: [{ unionName: "WARD NO-13 (36)", unionCode: 13 }]
          },
          {
            subdistrictName: "RAMNA(PART)",
            subdistrictCode: 66,
            unions: [
              { unionName: "WARD NO-19 (53)", unionCode: 19 },
              { unionName: "WARD NO-35 (54)", unionCode: 35 },
              { unionName: "WARD NO-36 (55)", unionCode: 36 }
            ]
          },
          {
            subdistrictName: "RAMPURA",
            subdistrictCode: 67,
            unions: [
              { unionName: "WARD NO-22", unionCode: 22 },
              { unionName: "WARD NO-23", unionCode: 23 }
            ]
          },
          {
            subdistrictName: "SABUJBAGH",
            subdistrictCode: 68,
            unions: [
              { unionName: "WARD NO-04 (27)", unionCode: 4 },
              { unionName: "WARD NO-05 (28)", unionCode: 5 },
              { unionName: "DAKSHINGAON (PART)", unionCode: 60 }
            ]
          },
          {
            subdistrictName: "RUPNAGAR",
            subdistrictCode: 70,
            unions: [
              { unionName: "WARD NO-06 (PART)", unionCode: 6 },
              { unionName: "WARD NO-07 (PART)", unionCode: 7 }
            ]
          },
          {
            subdistrictName: "SAVAR",
            subdistrictCode: 72,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMIN BAZAR", unionCode: 15 },
              { unionName: "ASHULIA", unionCode: 18 },
              { unionName: "BANAGRAM", unionCode: 22 },
              { unionName: "BHAKURTA", unionCode: 27 },
              { unionName: "BIRALIA", unionCode: 33 },
              { unionName: "DHAMSANA", unionCode: 39 },
              { unionName: "KAUNDIA", unionCode: 50 },
              { unionName: "PATHALIA", unionCode: 72 },
              { unionName: "SAVAR", unionCode: 78 },
              { unionName: "SHIMULIA", unionCode: 83 },
              { unionName: "TETULJHORA", unionCode: 89 },
              { unionName: "YEARPUR", unionCode: 94 },
              { unionName: "SAVAR CANTOMENT", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "SHAHJAHANPUR",
            subdistrictCode: 73,
            unions: [
              { unionName: "WARD NO-08 (PART) (31)", unionCode: 8 },
              { unionName: "WARD NO-11 (34)", unionCode: 11 },
              { unionName: "WARD NO-12 (35)", unionCode: 12 }
            ]
          },
          {
            subdistrictName: "SHAH ALI",
            subdistrictCode: 74,
            unions: [
              { unionName: "WARD NO-07 (PART)", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 }
            ]
          },
          {
            subdistrictName: "SHAHBAGH",
            subdistrictCode: 75,
            unions: [
              { unionName: "WARD NO-20 (56) (PART)", unionCode: 20 },
              { unionName: "WARD NO-21 (57)", unionCode: 21 }
            ]
          },
          {
            subdistrictName: "SHYAMPUR",
            subdistrictCode: 76,
            unions: [
              { unionName: "WARD NO-47 (83)", unionCode: 47 },
              { unionName: "WARD NO-51 (87)", unionCode: 51 },
              { unionName: "WARD NO-54 (90)", unionCode: 54 }
            ]
          },
          {
            subdistrictName: "SHER-E-BANGLA NAGAR",
            subdistrictCode: 80,
            unions: [
              { unionName: "WARD NO-17 (51) (PART)", unionCode: 17 },
              { unionName: "WARD NO-16 (PART)", unionCode: 16 },
              { unionName: "WARD NO-27 (PART) (40)", unionCode: 27 },
              { unionName: "WARD NO-28 (41)", unionCode: 28 }
            ]
          },
          {
            subdistrictName: "SUTRAPUR",
            subdistrictCode: 88,
            unions: [
              { unionName: "WARD NO-42 (78)", unionCode: 42 },
              { unionName: "WARD NO-43 (79)", unionCode: 43 },
              { unionName: "WARD NO-44 (80) (PART)", unionCode: 44 }
            ]
          },
          {
            subdistrictName: "TEJGAON",
            subdistrictCode: 90,
            unions: [
              { unionName: "WARD NO-25 (PART) (38)", unionCode: 25 },
              { unionName: "WARD NO-26 (39)", unionCode: 26 },
              { unionName: "WARD NO-27(PART) (40)", unionCode: 27 }
            ]
          },
          {
            subdistrictName: "TEJGAON IND. AREA",
            subdistrictCode: 92,
            unions: [
              { unionName: "WARD NO-20 (PART)", unionCode: 20 },
              { unionName: "WARD NO- 24(37)", unionCode: 24 },
              { unionName: "WARD NO-25 (PART) (38)", unionCode: 25 }
            ]
          },
          {
            subdistrictName: "TURAG",
            subdistrictCode: 93,
            unions: [{ unionName: "HARIRAMPUR", unionCode: 51 }]
          },
          {
            subdistrictName: "UTTARA",
            subdistrictCode: 94,
            unions: [{ unionName: "WARD NO-01 (PART)", unionCode: 1 }]
          },
          {
            subdistrictName: "UTTARA",
            subdistrictCode: 95,
            unions: [{ unionName: "WARD NO-01(PART)", unionCode: 1 }]
          },
          {
            subdistrictName: "UTTAR KHAN",
            subdistrictCode: 96,
            unions: [{ unionName: "UTTAR KHAN", unionCode: 76 }]
          },
          {
            subdistrictName: "WARI",
            subdistrictCode: 98,
            unions: [
              { unionName: "WARD NO-38 (74)", unionCode: 38 },
              { unionName: "WARD NO-39 (75)", unionCode: 39 },
              { unionName: "WARD NO-40 (76) (PART)", unionCode: 40 },
              { unionName: "WARD NO-41 (77)", unionCode: 41 }
            ]
          }
        ]
      },
      {
        districtName: "FARIDPUR",
        districtCode: 29,
        subdistricts: [
          {
            subdistrictName: "ALFADANGA",
            subdistrictCode: 3,
            unions: [
              { unionName: "ALFADANGA", unionCode: 10 },
              { unionName: "BANA", unionCode: 21 },
              { unionName: "BURAICH", unionCode: 31 },
              { unionName: "GOPALPUR", unionCode: 42 },
              { unionName: "PANCHURIA", unionCode: 52 },
              { unionName: "TAGARBANDA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "BHANGA",
            subdistrictCode: 10,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALGI", unionCode: 13 },
              { unionName: "AZIMNAGAR", unionCode: 15 },
              { unionName: "CHUMORDI", unionCode: 25 },
              { unionName: "CHANDRA", unionCode: 31 },
              { unionName: "GHARUA", unionCode: 39 },
              { unionName: "HAMIRDI", unionCode: 47 },
              { unionName: "KALAMRIDHA", unionCode: 55 },
              { unionName: "KAOLIBERA", unionCode: 63 },
              { unionName: "MANIKDAHA", unionCode: 71 },
              { unionName: "NASIRABAD", unionCode: 79 },
              { unionName: "NURULLAGANJ", unionCode: 87 },
              { unionName: "TUZARPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BOALMARI",
            subdistrictCode: 18,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BOALMARI", unionCode: 15 },
              { unionName: "CHANDPUR", unionCode: 20 },
              { unionName: "CHATUL", unionCode: 25 },
              { unionName: "DADPUR", unionCode: 30 },
              { unionName: "GHOSHPUR", unionCode: 35 },
              { unionName: "GUNBAHA", unionCode: 40 },
              { unionName: "MOYNA", unionCode: 60 },
              { unionName: "PARAMESHWARDI", unionCode: 75 },
              { unionName: "RUPAPAT", unionCode: 85 },
              { unionName: "SATAIR", unionCode: 90 },
              { unionName: "SHEKHAR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "CHAR BHADRASAN",
            subdistrictCode: 21,
            unions: [
              { unionName: "CHAR BHADRASAN", unionCode: 19 },
              { unionName: "CHAR HARIRAMPUR", unionCode: 38 },
              { unionName: "CHAR JHAUKANDA", unionCode: 57 },
              { unionName: "GAZIRTEK", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "FARIDPUR SADAR",
            subdistrictCode: 47,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALIABAD", unionCode: 13 },
              { unionName: "AMBIKAPUR", unionCode: 15 },
              { unionName: "CHAR MADHABDIA", unionCode: 23 },
              { unionName: "DECREERCHAR", unionCode: 31 },
              { unionName: "GREDA", unionCode: 39 },
              { unionName: "ISHAN GOPALPUR", unionCode: 47 },
              { unionName: "KAIJURI", unionCode: 55 },
              { unionName: "KANAIPUR", unionCode: 63 },
              { unionName: "KRISHNANAGAR", unionCode: 71 },
              { unionName: "MAJCHAR", unionCode: 79 },
              { unionName: "UTTAR CHANNEL", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "MADHUKHALI",
            subdistrictCode: 56,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAGAT", unionCode: 10 },
              { unionName: "ARPARA", unionCode: 13 },
              { unionName: "DUMAIN", unionCode: 21 },
              { unionName: "GAJNA", unionCode: 31 },
              { unionName: "JAHAPUR", unionCode: 37 },
              { unionName: "KAMARKHALI", unionCode: 42 },
              { unionName: "KORAKDI", unionCode: 47 },
              { unionName: "MADHUKHALI", unionCode: 52 },
              { unionName: "MEGCHAMI", unionCode: 63 },
              { unionName: "NOAPARA", unionCode: 73 },
              { unionName: "RAIPUR", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "NAGARKANDA",
            subdistrictCode: 62,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHAR JASORDI", unionCode: 22 },
              { unionName: "DANGI", unionCode: 27 },
              { unionName: "PHULSUTI", unionCode: 33 },
              { unionName: "KAICHAIL", unionCode: 50 },
              { unionName: "LASKARDIA", unionCode: 55 },
              { unionName: "KODALIA SHOHIDNAGAR", unionCode: 67 },
              { unionName: "PURA PARA", unionCode: 72 },
              { unionName: "RAMNAGAR", unionCode: 83 },
              { unionName: "TALMA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SADARPUR",
            subdistrictCode: 84,
            unions: [
              { unionName: "AKTER CHAR", unionCode: 15 },
              { unionName: "BHASHANCHAR", unionCode: 19 },
              { unionName: "CHAR BISHNUPUR", unionCode: 28 },
              { unionName: "CHAR MANAIR", unionCode: 38 },
              { unionName: "CHAR NASIRPUR", unionCode: 47 },
              { unionName: "DHEUKHALI", unionCode: 57 },
              { unionName: "KRISHNAPUR", unionCode: 66 },
              { unionName: "NARIKELBARIA", unionCode: 76 },
              { unionName: "SADARPUR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "SALTHA",
            subdistrictCode: 90,
            unions: [
              { unionName: "ATGHAR", unionCode: 10 },
              { unionName: "BALLABHDI", unionCode: 11 },
              { unionName: "BHAWAL", unionCode: 16 },
              { unionName: "GATTI", unionCode: 39 },
              { unionName: "JADUNANDI", unionCode: 44 },
              { unionName: "MAJHARDIA", unionCode: 61 },
              { unionName: "RAMKANTAPUR", unionCode: 78 },
              { unionName: "SONAPUR", unionCode: 89 }
            ]
          }
        ]
      },
      {
        districtName: "GAZIPUR",
        districtCode: 33,
        subdistricts: [
          {
            subdistrictName: "GAZIPUR SADAR",
            subdistrictCode: 30,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18", unionCode: 18 },
              { unionName: "WARD NO-19", unionCode: 19 },
              { unionName: "WARD NO-20", unionCode: 20 },
              { unionName: "WARD NO-21", unionCode: 21 },
              { unionName: "WARD NO-22", unionCode: 22 },
              { unionName: "WARD NO-23", unionCode: 23 },
              { unionName: "WARD NO-24", unionCode: 24 },
              { unionName: "WARD NO-25", unionCode: 31 },
              { unionName: "WARD NO-26", unionCode: 32 },
              { unionName: "WARD NO-27", unionCode: 33 },
              { unionName: "WARD NO-28", unionCode: 34 },
              { unionName: "WARD NO-29", unionCode: 35 },
              { unionName: "WARD NO-30", unionCode: 36 },
              { unionName: "WARD NO-31", unionCode: 37 },
              { unionName: "WARD NO-32", unionCode: 38 },
              { unionName: "WARD NO-33", unionCode: 39 },
              { unionName: "WARD NO-34", unionCode: 40 },
              { unionName: "WARD NO-35", unionCode: 41 },
              { unionName: "WARD NO-36", unionCode: 42 },
              { unionName: "WARD NO-37", unionCode: 43 },
              { unionName: "WARD NO-38", unionCode: 44 },
              { unionName: "WARD NO-39", unionCode: 45 },
              { unionName: "WARD NO-40", unionCode: 46 },
              { unionName: "WARD NO-41", unionCode: 47 },
              { unionName: "WARD NO-42", unionCode: 48 },
              { unionName: "WARD NO-43", unionCode: 49 },
              { unionName: "WARD NO-44", unionCode: 50 },
              { unionName: "WARD NO-45", unionCode: 51 },
              { unionName: "WARD NO-46", unionCode: 52 },
              { unionName: "WARD NO-47", unionCode: 53 },
              { unionName: "WARD NO-48", unionCode: 54 },
              { unionName: "WARD NO-49", unionCode: 55 },
              { unionName: "WARD NO-50", unionCode: 56 },
              { unionName: "WARD NO-51", unionCode: 57 },
              { unionName: "WARD NO-52", unionCode: 58 },
              { unionName: "WARD NO-53", unionCode: 59 },
              { unionName: "WARD NO-54", unionCode: 60 },
              { unionName: "WARD NO-55", unionCode: 61 },
              { unionName: "WARD NO-56", unionCode: 62 },
              { unionName: "WARD NO-57", unionCode: 63 },
              { unionName: "BARIA", unionCode: 25 },
              { unionName: "BHAWALGARH", unionCode: 28 },
              { unionName: "MIRZAPUR", unionCode: 67 },
              { unionName: "PIRUJALI", unionCode: 70 },
              { unionName: "GAZIPUR CANT.", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "KALIAKAIR",
            subdistrictCode: 32,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ATABAHA", unionCode: 13 },
              { unionName: "BOALI", unionCode: 19 },
              { unionName: "CHAPAIR", unionCode: 28 },
              { unionName: "DHALJORA", unionCode: 38 },
              { unionName: "FULBARIA", unionCode: 47 },
              { unionName: "MADHYAPARA", unionCode: 57 },
              { unionName: "MOUCHAK", unionCode: 66 },
              { unionName: "SREEFALTALI", unionCode: 76 },
              { unionName: "SUTRAPUR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "KALIGANJ",
            subdistrictCode: 34,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAHADURSADI", unionCode: 15 },
              { unionName: "BAKTARPUR", unionCode: 17 },
              { unionName: "JAMALPUR", unionCode: 60 },
              { unionName: "JANGALIA", unionCode: 69 },
              { unionName: "MOKTARPUR", unionCode: 94 },
              { unionName: "NAGARI", unionCode: 96 },
              { unionName: "TUMULIA", unionCode: 97 }
            ]
          },
          {
            subdistrictName: "KAPASIA",
            subdistrictCode: 36,
            unions: [
              { unionName: "BARISHABA", unionCode: 13 },
              { unionName: "CHANDPUR", unionCode: 17 },
              { unionName: "DURGAPUR", unionCode: 25 },
              { unionName: "GHAGOTIA", unionCode: 34 },
              { unionName: "KAPASIA", unionCode: 43 },
              { unionName: "KARIHATA", unionCode: 51 },
              { unionName: "RAYED", unionCode: 60 },
              { unionName: "SANMANIA", unionCode: 69 },
              { unionName: "SINGASREE", unionCode: 77 },
              { unionName: "TARGAON", unionCode: 86 },
              { unionName: "TOKE", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SREEPUR",
            subdistrictCode: 86,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARMI", unionCode: 21 },
              { unionName: "GAZIPUR", unionCode: 25 },
              { unionName: "GOSINGA", unionCode: 28 },
              { unionName: "KAORAID", unionCode: 38 },
              { unionName: "MAONA", unionCode: 47 },
              { unionName: "PRAHLADPUR", unionCode: 57 },
              { unionName: "RAJABARI", unionCode: 66 },
              { unionName: "TELIHATI", unionCode: 85 }
            ]
          }
        ]
      },
      {
        districtName: "GOPALGANJ",
        districtCode: 35,
        subdistricts: [
          {
            subdistrictName: "GOPALGANJ SADAR",
            subdistrictCode: 32,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BORASI", unionCode: 11 },
              { unionName: "BAULTALI", unionCode: 13 },
              { unionName: "CHANDRA DIGHALIA", unionCode: 15 },
              { unionName: "DURGAPUR", unionCode: 17 },
              { unionName: "GOBRA", unionCode: 21 },
              { unionName: "GOPINATHPUR", unionCode: 30 },
              { unionName: "HARIDASPUR", unionCode: 34 },
              { unionName: "JALALABAD", unionCode: 38 },
              { unionName: "KAJULIA", unionCode: 43 },
              { unionName: "KARPARA", unionCode: 47 },
              { unionName: "KATI", unionCode: 51 },
              { unionName: "LATIFPUR", unionCode: 56 },
              { unionName: "MAJHIGATI", unionCode: 60 },
              { unionName: "NIZRA", unionCode: 64 },
              { unionName: "PAIKKANDI", unionCode: 69 },
              { unionName: "RAGHUNATHPUR", unionCode: 73 },
              { unionName: "SAHAPUR", unionCode: 77 },
              { unionName: "SATPAR", unionCode: 82 },
              { unionName: "SUKTAIL", unionCode: 86 },
              { unionName: "ULPUR", unionCode: 90 },
              { unionName: "URAFI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KASHIANI",
            subdistrictCode: 43,
            unions: [
              { unionName: "BETHURI", unionCode: 11 },
              { unionName: "FUKURA", unionCode: 13 },
              { unionName: "HATIARA", unionCode: 20 },
              { unionName: "KASHIANI", unionCode: 27 },
              { unionName: "MAMUDPUR", unionCode: 33 },
              { unionName: "MAHESHPUR", unionCode: 40 },
              { unionName: "NIJAMKANDI", unionCode: 47 },
              { unionName: "ORAKANDI", unionCode: 54 },
              { unionName: "PARULIA", unionCode: 61 },
              { unionName: "PUISUR", unionCode: 67 },
              { unionName: "RAJPAT", unionCode: 74 },
              { unionName: "RATAIL", unionCode: 81 },
              { unionName: "SAJAIL", unionCode: 88 },
              { unionName: "SINGA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KOTALIPARA",
            subdistrictCode: 51,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMTALI", unionCode: 13 },
              { unionName: "BANDHABARI", unionCode: 15 },
              { unionName: "GHAGAR", unionCode: 23 },
              { unionName: "HIRAN", unionCode: 31 },
              { unionName: "KALABARI", unionCode: 39 },
              { unionName: "KANDI", unionCode: 47 },
              { unionName: "KUSHLA", unionCode: 55 },
              { unionName: "PINJURI", unionCode: 63 },
              { unionName: "RADHAGANJ", unionCode: 71 },
              { unionName: "RAMSHIL", unionCode: 79 },
              { unionName: "SADULLAPUR", unionCode: 87 },
              { unionName: "SUAGRAM", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MUKSUDPUR",
            subdistrictCode: 58,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHABRASUR", unionCode: 10 },
              { unionName: "BAHUGRAM", unionCode: 11 },
              { unionName: "BANSHBARIA", unionCode: 16 },
              { unionName: "BATIKAMARI", unionCode: 22 },
              { unionName: "DIGNAGAR", unionCode: 27 },
              { unionName: "GOBINDAPUR", unionCode: 33 },
              { unionName: "GOHALA", unionCode: 39 },
              { unionName: "JALIRPAR", unionCode: 44 },
              { unionName: "KASALIA", unionCode: 50 },
              { unionName: "KHANDARPAR", unionCode: 55 },
              { unionName: "MAHARAJPUR", unionCode: 61 },
              { unionName: "MOCHNA", unionCode: 67 },
              { unionName: "NANIKSHIR", unionCode: 72 },
              { unionName: "PASARGATI", unionCode: 78 },
              { unionName: "RAGHDI", unionCode: 83 },
              { unionName: "UJANI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "TUNGIPARA",
            subdistrictCode: 91,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARNI", unionCode: 19 },
              { unionName: "DUMURIA", unionCode: 28 },
              { unionName: "GOPALPUR", unionCode: 38 },
              { unionName: "KUSHLI", unionCode: 66 },
              { unionName: "PATGATI", unionCode: 76 }
            ]
          }
        ]
      },
      {
        districtName: "KISHOREGONJ",
        districtCode: 48,
        subdistricts: [
          {
            subdistrictName: "AUSTAGRAM",
            subdistrictCode: 2,
            unions: [
              { unionName: "ADAMPUR", unionCode: 11 },
              { unionName: "AUSTAGRAM", unionCode: 23 },
              { unionName: "BANGAL PARA", unionCode: 35 },
              { unionName: "DEOGHAR", unionCode: 47 },
              { unionName: "KALMA", unionCode: 59 },
              { unionName: "KASTAIL", unionCode: 71 },
              { unionName: "KHAYERPUR ABDULLAHPUR", unionCode: 83 },
              { unionName: "PURBA AUSTAGRAM", unionCode: 91 }
            ]
          },
          {
            subdistrictName: "BAJITPUR",
            subdistrictCode: 6,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BALIARDI", unionCode: 17 },
              { unionName: "DIGHIRPAR", unionCode: 25 },
              { unionName: "DILALPUR", unionCode: 34 },
              { unionName: "GAZIR CHAR", unionCode: 43 },
              { unionName: "HALIMPUR", unionCode: 51 },
              { unionName: "HILACHIA", unionCode: 60 },
              { unionName: "HUMAIPUR", unionCode: 69 },
              { unionName: "KAILAG", unionCode: 72 },
              { unionName: "MAIJ CHAR", unionCode: 77 },
              { unionName: "PIRIJPUR", unionCode: 86 },
              { unionName: "SARAR CHAR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BHAIRAB",
            subdistrictCode: 11,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "AGANAGAR", unionCode: 21 },
              { unionName: "GAZARIA", unionCode: 35 },
              { unionName: "KALIKA PRASAD", unionCode: 47 },
              { unionName: "SADAKPUR", unionCode: 59 },
              { unionName: "SHIBPUR", unionCode: 71 },
              { unionName: "SHIMULKANDI", unionCode: 83 },
              { unionName: "SREENAGAR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "HOSSAINPUR",
            subdistrictCode: 27,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ARAIBARIA", unionCode: 13 },
              { unionName: "GOBINDAPUR", unionCode: 27 },
              { unionName: "JINARI", unionCode: 40 },
              { unionName: "PUMDI", unionCode: 54 },
              { unionName: "SAHEDAL", unionCode: 67 },
              { unionName: "SIDHLA", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "ITNA",
            subdistrictCode: 33,
            unions: [
              { unionName: "BADLA", unionCode: 17 },
              { unionName: "BARIBARI", unionCode: 25 },
              { unionName: "CHAUGANGA", unionCode: 26 },
              { unionName: "DHANPUR", unionCode: 43 },
              { unionName: "ELONGJURI", unionCode: 47 },
              { unionName: "ITNA", unionCode: 51 },
              { unionName: "JOY SIDDHI", unionCode: 60 },
              { unionName: "MRIGA", unionCode: 86 },
              { unionName: "RAITUTI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KARIMGANJ",
            subdistrictCode: 42,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARAGHARIA", unionCode: 13 },
              { unionName: "DEHUNDA", unionCode: 17 },
              { unionName: "GUNDHAR", unionCode: 25 },
              { unionName: "GUJADIA", unionCode: 34 },
              { unionName: "JAFARABAD", unionCode: 43 },
              { unionName: "JOYKA", unionCode: 51 },
              { unionName: "KADIR JANGAL", unionCode: 60 },
              { unionName: "KIRATAN", unionCode: 71 },
              { unionName: "NIAMATPUR", unionCode: 77 },
              { unionName: "NOABAD", unionCode: 86 },
              { unionName: "SUTAR PARA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KATIADI",
            subdistrictCode: 45,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ACHMITA", unionCode: 13 },
              { unionName: "BANAGRAM", unionCode: 19 },
              { unionName: "CHANDPUR", unionCode: 28 },
              { unionName: "JALALPUR", unionCode: 38 },
              { unionName: "KARGAON", unionCode: 47 },
              { unionName: "LOHAJURI", unionCode: 66 },
              { unionName: "MASUA", unionCode: 76 },
              { unionName: "MUMURDIA", unionCode: 85 },
              { unionName: "SHAHASRAM DHULDIA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "KISHOREGANJ SADAR",
            subdistrictCode: 49,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BINNATI", unionCode: 16 },
              { unionName: "BAULAI", unionCode: 17 },
              { unionName: "CHAUDDASATA", unionCode: 25 },
              { unionName: "DANA PATALI", unionCode: 34 },
              { unionName: "JASODAL", unionCode: 43 },
              { unionName: "KORSHA KARIAIL", unionCode: 51 },
              { unionName: "LATIBABAD", unionCode: 60 },
              { unionName: "MAHINANDA", unionCode: 69 },
              { unionName: "MAIJ KHAPAN", unionCode: 77 },
              { unionName: "MARIA", unionCode: 86 },
              { unionName: "RASHIDABAD", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KULIAR CHAR",
            subdistrictCode: 54,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHHAYSUTI", unionCode: 11 },
              { unionName: "FARIDPUR", unionCode: 23 },
              { unionName: "GOBARIA ABDULLAHPUR", unionCode: 35 },
              { unionName: "OSMANPUR", unionCode: 59 },
              { unionName: "RAMDI", unionCode: 71 },
              { unionName: "SALUA", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "MITHAMAIN",
            subdistrictCode: 59,
            unions: [
              { unionName: "BAIRATI", unionCode: 11 },
              { unionName: "DHAKI", unionCode: 13 },
              { unionName: "GHAGRA", unionCode: 27 },
              { unionName: "GOPEDIGHI", unionCode: 40 },
              { unionName: "KHATKHAL", unionCode: 54 },
              { unionName: "KEORJORI", unionCode: 67 },
              { unionName: "MITHAMAIN", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "NIKLI",
            subdistrictCode: 76,
            unions: [
              { unionName: "CHHATIR CHAR", unionCode: 23 },
              { unionName: "DAMPARA", unionCode: 27 },
              { unionName: "GURAI", unionCode: 38 },
              { unionName: "JARAITALA", unionCode: 47 },
              { unionName: "KARPASHA", unionCode: 57 },
              { unionName: "NIKLI", unionCode: 76 },
              { unionName: "SINGPUR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "PAKUNDIA",
            subdistrictCode: 79,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARUDIA", unionCode: 15 },
              { unionName: "CHANDI PASHA", unionCode: 19 },
              { unionName: "CHAR FARADI", unionCode: 28 },
              { unionName: "EGARASINDUR", unionCode: 38 },
              { unionName: "HOSENDI", unionCode: 47 },
              { unionName: "JANGALIA", unionCode: 57 },
              { unionName: "NARANDI", unionCode: 66 },
              { unionName: "PATUABHANGA", unionCode: 85 },
              { unionName: "SUKHIA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "TARAIL",
            subdistrictCode: 92,
            unions: [
              { unionName: "DAMIHA", unionCode: 13 },
              { unionName: "DHALA", unionCode: 27 },
              { unionName: "DIGDAIR", unionCode: 40 },
              { unionName: "JAWAR", unionCode: 54 },
              { unionName: "RAUTI", unionCode: 67 },
              { unionName: "TALGANGA", unionCode: 81 },
              { unionName: "TARAIL SACHAIL", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "MADARIPUR",
        districtCode: 54,
        subdistricts: [
          {
            subdistrictName: "KALKINI",
            subdistrictCode: 40,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALINAGAR", unionCode: 11 },
              { unionName: "BALIGRAM", unionCode: 12 },
              { unionName: "BANSHGARI", unionCode: 18 },
              { unionName: "CHAR DAULAT KHAN", unionCode: 25 },
              { unionName: "DASAR", unionCode: 31 },
              { unionName: "ENAYETNAGAR", unionCode: 37 },
              { unionName: "GOPALPUR", unionCode: 44 },
              { unionName: "KAYARIA", unionCode: 56 },
              { unionName: "KAZIBAKAI", unionCode: 63 },
              { unionName: "LAKSHMIPUR", unionCode: 69 },
              { unionName: "NABAGRAM", unionCode: 75 },
              { unionName: "RAMJANPUR", unionCode: 82 },
              { unionName: "PURBA ENAYETNAGAR", unionCode: 85 },
              { unionName: "SAHEBRAMPUR", unionCode: 88 },
              { unionName: "SHIKAR MANGAL", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MADARIPUR SADAR",
            subdistrictCode: 54,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAHADURPUR", unionCode: 10 },
              { unionName: "CHILAR CHAR", unionCode: 11 },
              { unionName: "DHURAIL", unionCode: 17 },
              { unionName: "DUDKHALI", unionCode: 23 },
              { unionName: "GHATMAJHI", unionCode: 29 },
              { unionName: "JHAUDI", unionCode: 35 },
              { unionName: "KALIKAPUR", unionCode: 41 },
              { unionName: "KENDUA", unionCode: 47 },
              { unionName: "KHOAJPUR", unionCode: 53 },
              { unionName: "KUNIA", unionCode: 59 },
              { unionName: "MUSTAFAPUR", unionCode: 71 },
              { unionName: "PANCHKHOLA", unionCode: 77 },
              { unionName: "PEARPUR", unionCode: 83 },
              { unionName: "RASTI", unionCode: 89 },
              { unionName: "SIRKHARA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "RAJOIR",
            subdistrictCode: 80,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMGRAM", unionCode: 13 },
              { unionName: "BADAR PASHA", unionCode: 19 },
              { unionName: "BAJITPUR", unionCode: 28 },
              { unionName: "HARIDASDI MAHENDRADI", unionCode: 31 },
              { unionName: "HOSSAINPUR", unionCode: 38 },
              { unionName: "ISIBPUR", unionCode: 47 },
              { unionName: "KABIRAJPUR", unionCode: 57 },
              { unionName: "KADAMBARI", unionCode: 66 },
              { unionName: "KHALIA", unionCode: 76 },
              { unionName: "PAIK PARA", unionCode: 85 },
              { unionName: "RAJOIR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "SHIBCHAR",
            subdistrictCode: 87,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BANDARKHOLA", unionCode: 11 },
              { unionName: "BANSHKANDI", unionCode: 13 },
              { unionName: "BAHERATALA DAKSHIN", unionCode: 15 },
              { unionName: "BAHERATALA UTTAR", unionCode: 18 },
              { unionName: "BHADRASAN", unionCode: 21 },
              { unionName: "BHANDARIKANDI", unionCode: 26 },
              { unionName: "CHAR JANAJAT", unionCode: 31 },
              { unionName: "DATTA PARA", unionCode: 36 },
              { unionName: "DITIYAKHANDA", unionCode: 42 },
              { unionName: "KADIRPUR", unionCode: 47 },
              { unionName: "KANTHALBARI", unionCode: 52 },
              { unionName: "KUTUBPUR", unionCode: 58 },
              { unionName: "MATBARER CHAR", unionCode: 63 },
              { unionName: "NILAKHI", unionCode: 68 },
              { unionName: "PANCH CHAR", unionCode: 73 },
              { unionName: "SANNYASIR CHAR", unionCode: 79 },
              { unionName: "SHIBCHAR", unionCode: 84 },
              { unionName: "SIRUAIL", unionCode: 89 },
              { unionName: "UMEDPUR", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "MANIKGANJ",
        districtCode: 56,
        subdistricts: [
          {
            subdistrictName: "DAULATPUR",
            subdistrictCode: 10,
            unions: [
              { unionName: "BACHAMARA", unionCode: 17 },
              { unionName: "BAGHUTIA", unionCode: 19 },
              { unionName: "CHAK MIRPUR", unionCode: 28 },
              { unionName: "CHARKATARI", unionCode: 38 },
              { unionName: "DHAMSAR", unionCode: 57 },
              { unionName: "JIYANPUR", unionCode: 66 },
              { unionName: "KALIA", unionCode: 76 },
              { unionName: "KHALSI", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "GHIOR",
            subdistrictCode: 22,
            unions: [
              { unionName: "BALIAKHORA", unionCode: 11 },
              { unionName: "BANIAJURI", unionCode: 23 },
              { unionName: "BARATIA", unionCode: 35 },
              { unionName: "GHIOR", unionCode: 47 },
              { unionName: "NALI", unionCode: 59 },
              { unionName: "PAILA", unionCode: 71 },
              { unionName: "SINGJURI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "HARIRAMPUR",
            subdistrictCode: 28,
            unions: [
              { unionName: "AZIMNAGAR", unionCode: 11 },
              { unionName: "BALLA", unionCode: 14 },
              { unionName: "BALARA", unionCode: 21 },
              { unionName: "BOYRA", unionCode: 29 },
              { unionName: "CHALA", unionCode: 36 },
              { unionName: "DHULSUNRA", unionCode: 43 },
              { unionName: "GALA", unionCode: 51 },
              { unionName: "GOPINATHPUR", unionCode: 58 },
              { unionName: "HARUKANDI", unionCode: 65 },
              { unionName: "KANCHANPUR", unionCode: 73 },
              { unionName: "LESRAGANJ", unionCode: 80 },
              { unionName: "RAMKRISHNAPUR", unionCode: 87 },
              { unionName: "SUTALARI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MANIKGANJ SADAR",
            subdistrictCode: 46,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BETILA MITARA", unionCode: 11 },
              { unionName: "ATIGRAM", unionCode: 13 },
              { unionName: "BHARARIA", unionCode: 15 },
              { unionName: "DIGHI", unionCode: 23 },
              { unionName: "GARPARA", unionCode: 31 },
              { unionName: "HATI PARA", unionCode: 39 },
              { unionName: "JAIGIR", unionCode: 55 },
              { unionName: "KRISHNAPUR", unionCode: 71 },
              { unionName: "NABAGRAM", unionCode: 87 },
              { unionName: "PUTAIL", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SATURIA",
            subdistrictCode: 70,
            unions: [
              { unionName: "BALIATI", unionCode: 19 },
              { unionName: "BARAID", unionCode: 28 },
              { unionName: "DARAGRAM", unionCode: 38 },
              { unionName: "DIGHALIA", unionCode: 47 },
              { unionName: "DHANKORA", unionCode: 57 },
              { unionName: "FUKURHATI", unionCode: 66 },
              { unionName: "HARGAZ", unionCode: 76 },
              { unionName: "SATURIA", unionCode: 85 },
              { unionName: "TILLI", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "SHIBALAYA",
            subdistrictCode: 78,
            unions: [
              { unionName: "ARUA", unionCode: 11 },
              { unionName: "MOHADEBPUR", unionCode: 23 },
              { unionName: "SHIMULIA", unionCode: 35 },
              { unionName: "SHIBALAYA", unionCode: 47 },
              { unionName: "TEOTA", unionCode: 59 },
              { unionName: "ULAIL", unionCode: 71 },
              { unionName: "UTHALI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "SINGAIR",
            subdistrictCode: 82,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BALDHARA", unionCode: 17 },
              { unionName: "BOYRA", unionCode: 19 },
              { unionName: "CHANDHAR", unionCode: 25 },
              { unionName: "CHARIGRAM", unionCode: 34 },
              { unionName: "DHALLA", unionCode: 43 },
              { unionName: "JOY MANTAP", unionCode: 51 },
              { unionName: "JAMIRTA", unionCode: 60 },
              { unionName: "JAMSHA", unionCode: 69 },
              { unionName: "SAISTA", unionCode: 77 },
              { unionName: "SINGAIR", unionCode: 86 },
              { unionName: "TALIBPUR", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "MUNSHIGANJ",
        districtCode: 59,
        subdistricts: [
          {
            subdistrictName: "GAZARIA",
            subdistrictCode: 24,
            unions: [
              { unionName: "BALUAKANDI", unionCode: 10 },
              { unionName: "BAUSIA", unionCode: 21 },
              { unionName: "BHABER CHAR", unionCode: 31 },
              { unionName: "GAZARIA", unionCode: 42 },
              { unionName: "GUAGACHHIA", unionCode: 52 },
              { unionName: "HOSSAINDI", unionCode: 63 },
              { unionName: "IMAMPUR", unionCode: 73 },
              { unionName: "TENGER CHAR", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "LOHAJANG",
            subdistrictCode: 44,
            unions: [
              { unionName: "BEJGAON", unionCode: 13 },
              { unionName: "BAULTALI", unionCode: 15 },
              { unionName: "GAODIA", unionCode: 31 },
              { unionName: "HALDIA", unionCode: 39 },
              { unionName: "KALMA", unionCode: 47 },
              { unionName: "KANAKSAR", unionCode: 55 },
              { unionName: "KHIDIR PARA", unionCode: 63 },
              { unionName: "KUMARBHOG", unionCode: 71 },
              { unionName: "MEDINI MANDAL", unionCode: 87 },
              { unionName: "LOHAJANG TEOTIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MUNSHIGANJ SADAR",
            subdistrictCode: 56,
            unions: [
              { unionName: "WARD NO-01 (MIRKADIM)", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "ADHARA", unionCode: 20 },
              { unionName: "BAJRA JOGINI", unionCode: 22 },
              { unionName: "BANGLA BAZAR", unionCode: 23 },
              { unionName: "CHAR KEWAR", unionCode: 28 },
              { unionName: "SILAI", unionCode: 38 },
              { unionName: "MAHAKALI", unionCode: 47 },
              { unionName: "MOLLAHKANDI", unionCode: 57 },
              { unionName: "PANCHASAR", unionCode: 76 },
              { unionName: "RAMPAL", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "SERAJDIKHAN",
            subdistrictCode: 74,
            unions: [
              { unionName: "BALUR CHAR", unionCode: 11 },
              { unionName: "BASAIL", unionCode: 13 },
              { unionName: "BAYARAGADI", unionCode: 20 },
              { unionName: "CHITRAKOT", unionCode: 27 },
              { unionName: "ICHHAPUR", unionCode: 33 },
              { unionName: "JAINSAR", unionCode: 40 },
              { unionName: "KAYAIN", unionCode: 47 },
              { unionName: "KOLA", unionCode: 54 },
              { unionName: "LATABDI", unionCode: 61 },
              { unionName: "MADHYAPARA", unionCode: 67 },
              { unionName: "MALKHANAGAR", unionCode: 74 },
              { unionName: "RAJANAGAR", unionCode: 81 },
              { unionName: "RASUNIA", unionCode: 88 },
              { unionName: "SEKHARNAGAR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SREENAGAR",
            subdistrictCode: 84,
            unions: [
              { unionName: "ATPARA", unionCode: 11 },
              { unionName: "BAGHRA", unionCode: 13 },
              { unionName: "BARAIKHALI", unionCode: 20 },
              { unionName: "BHAGYAKUL", unionCode: 27 },
              { unionName: "BIRTARA", unionCode: 33 },
              { unionName: "HASARA", unionCode: 40 },
              { unionName: "KOLA PARA", unionCode: 47 },
              { unionName: "KUKUTIA", unionCode: 54 },
              { unionName: "PATABHOG", unionCode: 61 },
              { unionName: "RARIKHAL", unionCode: 67 },
              { unionName: "SREENAGAR", unionCode: 74 },
              { unionName: "SHOLAGHAR", unionCode: 81 },
              { unionName: "SHYAMSIDDHI", unionCode: 88 },
              { unionName: "TANTAR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "TONGIBARI",
            subdistrictCode: 94,
            unions: [
              { unionName: "ABDULLAHPUR", unionCode: 11 },
              { unionName: "ARIAL", unionCode: 15 },
              { unionName: "AUTSHAHI", unionCode: 23 },
              { unionName: "BALIGAON", unionCode: 26 },
              { unionName: "BETKA", unionCode: 31 },
              { unionName: "DHIPUR", unionCode: 39 },
              { unionName: "DIGHIR PARA", unionCode: 47 },
              { unionName: "HASAIL BANARI", unionCode: 55 },
              { unionName: "JASHALONG", unionCode: 63 },
              { unionName: "KAMARKHARA", unionCode: 71 },
              { unionName: "KATHADIA SHIMULIA", unionCode: 79 },
              { unionName: "PANCHGAON", unionCode: 87 },
              { unionName: "SONARANG TONGIBARI", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "NARAYANGANJ",
        districtCode: 67,
        subdistricts: [
          {
            subdistrictName: "ARAIHAZAR",
            subdistrictCode: 2,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "ARAIHAZAR", unionCode: 23 },
              { unionName: "BISHNANDI", unionCode: 26 },
              { unionName: "BRAHMANDI", unionCode: 29 },
              { unionName: "DUPTARA", unionCode: 31 },
              { unionName: "FATEHPUR", unionCode: 39 },
              { unionName: "HAIZADI", unionCode: 47 },
              { unionName: "KALA PAHARIA", unionCode: 55 },
              { unionName: "KHAGAKANDA", unionCode: 63 },
              { unionName: "MAHMUDPUR", unionCode: 71 },
              { unionName: "SADASARDI", unionCode: 79 },
              { unionName: "SATGRAM", unionCode: 87 },
              { unionName: "UCHITPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SONARGAON",
            subdistrictCode: 4,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAIDYER BAZAR", unionCode: 24 },
              { unionName: "BARADI", unionCode: 27 },
              { unionName: "JAMPUR", unionCode: 34 },
              { unionName: "KACHPUR", unionCode: 43 },
              { unionName: "MUGRA PARA", unionCode: 51 },
              { unionName: "NOAGAON", unionCode: 60 },
              { unionName: "PIRIJPUR", unionCode: 69 },
              { unionName: "SADIPUR", unionCode: 77 },
              { unionName: "SHAMBHUPURA", unionCode: 86 },
              { unionName: "SANMANDI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BANDAR",
            subdistrictCode: 6,
            unions: [
              { unionName: "WARD NO-19 (CITY CORP.)", unionCode: 19 },
              { unionName: "WARD NO-20 (CITY CORP.)", unionCode: 20 },
              { unionName: "WARD NO-21 (CITY CORP.)", unionCode: 21 },
              { unionName: "WARD NO-22 (CITY CORP.)", unionCode: 22 },
              { unionName: "WARD NO-23 (CITY CORP.)", unionCode: 23 },
              { unionName: "WARD NO-24 (CITY CORP.)", unionCode: 24 },
              { unionName: "WARD NO-25 (CITY CORP.)", unionCode: 25 },
              { unionName: "WARD NO-26 (CITY CORP.)", unionCode: 26 },
              { unionName: "WARD NO-27 (CITY CORP.)", unionCode: 27 },
              { unionName: "BANDAR", unionCode: 15 },
              { unionName: "DHAMGAR", unionCode: 31 },
              { unionName: "KALAGACHHIA UNION", unionCode: 47 },
              { unionName: "MADANPUR", unionCode: 63 },
              { unionName: "MUSAPUR", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "NARAYANGANJ SADAR",
            subdistrictCode: 58,
            unions: [
              { unionName: "WARD NO-01 (CITY CORP.)", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18", unionCode: 18 },
              { unionName: "ALIR TEK", unionCode: 20 },
              { unionName: "BAKTABALLI", unionCode: 25 },
              { unionName: "ENAYETNAGAR", unionCode: 31 },
              { unionName: "FATULLAH", unionCode: 37 },
              { unionName: "GOGNAGAR", unionCode: 47 },
              { unionName: "KASHIPUR", unionCode: 63 },
              { unionName: "KUTUBPUR", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "RUPGANJ",
            subdistrictCode: 68,
            unions: [
              { unionName: "WARD NO-01 (KANCHAN)", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01 (TARABO)", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "BHOLABA", unionCode: 22 },
              { unionName: "BULTA", unionCode: 25 },
              { unionName: "DAUDPUR", unionCode: 31 },
              { unionName: "GOLAKANDAIL", unionCode: 39 },
              { unionName: "KAYET PARA", unionCode: 55 },
              { unionName: "MURA PARA", unionCode: 63 },
              { unionName: "RUPGANJ", unionCode: 79 }
            ]
          }
        ]
      },
      {
        districtName: "NARSINGDI",
        districtCode: 68,
        subdistricts: [
          {
            subdistrictName: "BELABO",
            subdistrictCode: 7,
            unions: [
              { unionName: "AMLABA", unionCode: 11 },
              { unionName: "BAJNABA", unionCode: 13 },
              { unionName: "BELABO", unionCode: 20 },
              { unionName: "BINYABAID", unionCode: 25 },
              { unionName: "CHAR UJILABA", unionCode: 31 },
              { unionName: "NARAYANPUR", unionCode: 59 },
              { unionName: "PATULI", unionCode: 71 },
              { unionName: "SALLABAD", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "MANOHARDI",
            subdistrictCode: 52,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA CHAPA", unionCode: 21 },
              { unionName: "CHALAK CHAR", unionCode: 27 },
              { unionName: "CHANDANBARI", unionCode: 33 },
              { unionName: "CHAR MANDALIA", unionCode: 40 },
              { unionName: "DAULATPUR", unionCode: 47 },
              { unionName: "EKDUARIA", unionCode: 54 },
              { unionName: "GOTASHIA", unionCode: 61 },
              { unionName: "KANCHIKATA", unionCode: 67 },
              { unionName: "KHIDIRPUR", unionCode: 74 },
              { unionName: "KRISHNAPUR", unionCode: 77 },
              { unionName: "LEBUTALA", unionCode: 81 },
              { unionName: "SHUKUNDI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NARSINGDI SADAR",
            subdistrictCode: 60,
            unions: [
              { unionName: "WARD NO-01 (MADHABDI)", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 34 },
              { unionName: "WARD NO-11", unionCode: 35 },
              { unionName: "WARD NO-12", unionCode: 36 },
              { unionName: "WARD NO-01 (NARSINGDI)", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "ALOKBALI", unionCode: 20 },
              { unionName: "AMDIA", unionCode: 21 },
              { unionName: "CHAR DIGHALDI", unionCode: 23 },
              { unionName: "CHINISHPUR", unionCode: 25 },
              { unionName: "HAJIPUR", unionCode: 29 },
              { unionName: "KARIMPUR", unionCode: 41 },
              { unionName: "KANTHALIA", unionCode: 47 },
              { unionName: "NURALLA PUR U/C", unionCode: 53 },
              { unionName: "MAHISHASURA", unionCode: 59 },
              { unionName: "MEHER PARA", unionCode: 65 },
              { unionName: "NAZARPUR", unionCode: 77 },
              { unionName: "PAIKAR CHAR", unionCode: 83 },
              { unionName: "PANCHDONA", unionCode: 89 },
              { unionName: "SILMANDI", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "PALASH",
            subdistrictCode: 63,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHAR SINDUR", unionCode: 15 },
              { unionName: "DANGA", unionCode: 31 },
              { unionName: "GAZARIA", unionCode: 47 },
              { unionName: "JINARDI", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "ROYPURA",
            subdistrictCode: 64,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMIRGANJ", unionCode: 10 },
              { unionName: "ADIABAD", unionCode: 12 },
              { unionName: "ALIPURA", unionCode: 14 },
              { unionName: "BANSHGARI", unionCode: 16 },
              { unionName: "CHANDERKANDI", unionCode: 23 },
              { unionName: "CHANDPUR", unionCode: 27 },
              { unionName: "CHAR ARALIA", unionCode: 30 },
              { unionName: "CHAR MADHUA", unionCode: 33 },
              { unionName: "CHAR SUBUDDI", unionCode: 37 },
              { unionName: "DAUKAR CHAR", unionCode: 40 },
              { unionName: "HAIRMARA", unionCode: 44 },
              { unionName: "MAHESHPUR", unionCode: 47 },
              { unionName: "MARJAL", unionCode: 50 },
              { unionName: "MIRZANAGAR", unionCode: 54 },
              { unionName: "MIRZAPUR", unionCode: 57 },
              { unionName: "MIRZAR CHAR", unionCode: 61 },
              { unionName: "MUSAPUR", unionCode: 64 },
              { unionName: "NILAKHYA", unionCode: 71 },
              { unionName: "PALASHTALI", unionCode: 74 },
              { unionName: "PARATALI", unionCode: 78 },
              { unionName: "RADHANAGAR", unionCode: 81 },
              { unionName: "ROYPURA", unionCode: 84 },
              { unionName: "SREENAGAR", unionCode: 91 },
              { unionName: "UTTAR BAKHARNAGAR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SHIBPUR",
            subdistrictCode: 76,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AYUBPUR", unionCode: 10 },
              { unionName: "BAGHABA", unionCode: 21 },
              { unionName: "CHAK RADHA", unionCode: 31 },
              { unionName: "DULALPUR", unionCode: 42 },
              { unionName: "JOSAR", unionCode: 52 },
              { unionName: "JOYNAGAR", unionCode: 63 },
              { unionName: "MASIMPUR", unionCode: 73 },
              { unionName: "PUTIA", unionCode: 84 },
              { unionName: "SADHAR CHAR", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "RAJBARI",
        districtCode: 82,
        subdistricts: [
          {
            subdistrictName: "BALIAKANDI",
            subdistrictCode: 7,
            unions: [
              { unionName: "BAHARPUR", unionCode: 17 },
              { unionName: "BALIAKANDI", unionCode: 19 },
              { unionName: "ISLAMPUR", unionCode: 47 },
              { unionName: "JAMALPUR", unionCode: 57 },
              { unionName: "JANGAL", unionCode: 66 },
              { unionName: "NARUA", unionCode: 85 },
              { unionName: "NAWABPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "GOALANDA",
            subdistrictCode: 29,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHHOTA BHAKLA", unionCode: 19 },
              { unionName: "DAULATDIA", unionCode: 38 },
              { unionName: "DEBAGRAM", unionCode: 57 },
              { unionName: "UJAN CHAR", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "KALUKHALI",
            subdistrictCode: 47,
            unions: [
              { unionName: "BOALIA", unionCode: 18 },
              { unionName: "KALIKAPUR", unionCode: 30 },
              { unionName: "MAJHBARI", unionCode: 55 },
              { unionName: "MADAPUR", unionCode: 60 },
              { unionName: "MRIGI", unionCode: 70 },
              { unionName: "RATANDIA", unionCode: 85 },
              { unionName: "SAORAIL", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "PANGSHA",
            subdistrictCode: 73,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAHADURPUR", unionCode: 16 },
              { unionName: "HABASPUR", unionCode: 20 },
              { unionName: "JASHAI", unionCode: 25 },
              { unionName: "KALIMAHAR", unionCode: 35 },
              { unionName: "KASBA MAJHAIL", unionCode: 40 },
              { unionName: "MACHH PARA", unionCode: 50 },
              { unionName: "MAURAT", unionCode: 65 },
              { unionName: "BABUPARA", unionCode: 75 },
              { unionName: "PATTA", unionCode: 80 },
              { unionName: "SARISHA", unionCode: 90 }
            ]
          },
          {
            subdistrictName: "RAJBARI SADAR",
            subdistrictCode: 76,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALIPUR", unionCode: 12 },
              { unionName: "BANIBAHA", unionCode: 14 },
              { unionName: "BARAT", unionCode: 21 },
              { unionName: "BASANTAPUR", unionCode: 29 },
              { unionName: "CHANDANI", unionCode: 32 },
              { unionName: "DADSHI", unionCode: 36 },
              { unionName: "KHANKHANAPUR", unionCode: 43 },
              { unionName: "KHANGANJ", unionCode: 45 },
              { unionName: "MIZANPUR", unionCode: 51 },
              { unionName: "MULGHAR", unionCode: 58 },
              { unionName: "PANCHURIA", unionCode: 65 },
              { unionName: "RAMKANTAPUR", unionCode: 80 },
              { unionName: "SHAHID WAHABPUR", unionCode: 87 },
              { unionName: "SULTANPUR", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "SHARIATPUR",
        districtCode: 86,
        subdistricts: [
          {
            subdistrictName: "BHEDARGANJ",
            subdistrictCode: 14,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ARSHI NAGAR", unionCode: 12 },
              { unionName: "CHAR BHAGA", unionCode: 15 },
              { unionName: "CHAR KUMARIA", unionCode: 17 },
              { unionName: "CHHAYGAON", unionCode: 25 },
              { unionName: "CHAR CENSUS", unionCode: 28 },
              { unionName: "DHAKHIN TARABUNIA", unionCode: 32 },
              { unionName: "DIGAR MAHISHKHALI", unionCode: 43 },
              { unionName: "KACHIKATA", unionCode: 51 },
              { unionName: "MAHISAR", unionCode: 60 },
              { unionName: "NARAYANPUR", unionCode: 69 },
              { unionName: "RAMBHADRAPUR", unionCode: 77 },
              { unionName: "SAKHIPUR", unionCode: 86 },
              { unionName: "TARABUNIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DAMUDYA",
            subdistrictCode: 25,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DARUL AMAN", unionCode: 23 },
              { unionName: "DHANKATI", unionCode: 35 },
              { unionName: "ISLAM PUR", unionCode: 40 },
              { unionName: "KANESHWAR", unionCode: 47 },
              { unionName: "PURBA DAMUDYA", unionCode: 59 },
              { unionName: "SIDULKURA", unionCode: 71 },
              { unionName: "SIDYA", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "GOSAIRHAT",
            subdistrictCode: 36,
            unions: [
              { unionName: "ALADLPUR CHAR", unionCode: 11 },
              { unionName: "GOSAIRHAT", unionCode: 23 },
              { unionName: "IDILPUR", unionCode: 35 },
              { unionName: "KODALPUR", unionCode: 47 },
              { unionName: "KUCHAIPATTI", unionCode: 51 },
              { unionName: "NAGER PARA", unionCode: 59 },
              { unionName: "NALMURI", unionCode: 71 },
              { unionName: "SAMANTASAR", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "NARIA",
            subdistrictCode: 65,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHOJESHWAR", unionCode: 11 },
              { unionName: "BHUMKHARA", unionCode: 12 },
              { unionName: "BIJHARI", unionCode: 18 },
              { unionName: "CHAMTA", unionCode: 25 },
              { unionName: "CHAR ATRA", unionCode: 31 },
              { unionName: "DINGA MANIK", unionCode: 37 },
              { unionName: "FATEH JANGAPUR", unionCode: 44 },
              { unionName: "GHARISAR", unionCode: 50 },
              { unionName: "JAPSA", unionCode: 56 },
              { unionName: "KEDARPUR", unionCode: 63 },
              { unionName: "MUKTARER CHAR", unionCode: 69 },
              { unionName: "NASASAN", unionCode: 82 },
              { unionName: "NOAPARA", unionCode: 88 },
              { unionName: "RAJNAGAR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SHARIATPUR SADAR",
            subdistrictCode: 69,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ANGARIA", unionCode: 17 },
              { unionName: "BINODPUR", unionCode: 19 },
              { unionName: "CHANDRAPUR", unionCode: 28 },
              { unionName: "CHIKANDI", unionCode: 38 },
              { unionName: "CHITALIA", unionCode: 47 },
              { unionName: "DOMSAR", unionCode: 57 },
              { unionName: "MOHAMMADPUR", unionCode: 62 },
              { unionName: "PALONG", unionCode: 66 },
              { unionName: "RUDRAKAR", unionCode: 76 },
              { unionName: "SHAUL PARA", unionCode: 85 },
              { unionName: "TULASAR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "ZANJIRA",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA GOPALPUR", unionCode: 12 },
              { unionName: "BARAKANDI", unionCode: 14 },
              { unionName: "BARA KRISHNAGAR", unionCode: 21 },
              { unionName: "BILASPUR", unionCode: 29 },
              { unionName: "JOYNAGAR", unionCode: 43 },
              { unionName: "KUNDER CHAR", unionCode: 51 },
              { unionName: "MULNA", unionCode: 58 },
              { unionName: "NAODOBA", unionCode: 65 },
              { unionName: "PALER CHAR", unionCode: 73 },
              { unionName: "PURBA NAODOBA", unionCode: 80 },
              { unionName: "SENER CHAR", unionCode: 87 },
              { unionName: "ZANJIRA", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "TANGAIL",
        districtCode: 93,
        subdistricts: [
          {
            subdistrictName: "BASAIL",
            subdistrictCode: 9,
            unions: [
              { unionName: "WARD-01", unionCode: 1 },
              { unionName: "WARD-02", unionCode: 2 },
              { unionName: "WARD-03", unionCode: 3 },
              { unionName: "WARD-04", unionCode: 4 },
              { unionName: "WARD-05", unionCode: 5 },
              { unionName: "WARD-06", unionCode: 6 },
              { unionName: "WARD-07", unionCode: 7 },
              { unionName: "WARD-08", unionCode: 8 },
              { unionName: "WARD-09", unionCode: 9 },
              { unionName: "BASAIL", unionCode: 11 },
              { unionName: "FULKI", unionCode: 35 },
              { unionName: "HABLA", unionCode: 47 },
              { unionName: "KANCHANPUR", unionCode: 59 },
              { unionName: "KASHIL", unionCode: 71 },
              { unionName: "KAOALJANI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "BHUAPUR",
            subdistrictCode: 19,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ARJUNA", unionCode: 13 },
              { unionName: "ALOA", unionCode: 27 },
              { unionName: "FALDA", unionCode: 40 },
              { unionName: "GABSARA", unionCode: 54 },
              { unionName: "GOBINDASI", unionCode: 67 },
              { unionName: "NIKRAIL", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "DELDUAR",
            subdistrictCode: 23,
            unions: [
              { unionName: "ATIA", unionCode: 11 },
              { unionName: "DELDUAR", unionCode: 23 },
              { unionName: "DEOLI", unionCode: 35 },
              { unionName: "DUBAIL", unionCode: 41 },
              { unionName: "ELASIN", unionCode: 47 },
              { unionName: "FAZILHATI", unionCode: 59 },
              { unionName: "LAUHATI", unionCode: 65 },
              { unionName: "PATHRAIL", unionCode: 71 }
            ]
          },
          {
            subdistrictName: "DHANBARI",
            subdistrictCode: 25,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BALIBHADRA", unionCode: 21 },
              { unionName: "BANIAJAN", unionCode: 25 },
              { unionName: "BIRTARA", unionCode: 38 },
              { unionName: "JADUNATHPUR", unionCode: 47 },
              { unionName: "DHOPAKHALI", unionCode: 57 },
              { unionName: "JADUNATHPUR", unionCode: 68 },
              { unionName: "MUSUDDI", unionCode: 80 },
              { unionName: "PAISKA", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "GHATAIL",
            subdistrictCode: 28,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ANEHOLA", unionCode: 15 },
              { unionName: "DEULABARI", unionCode: 17 },
              { unionName: "DEOPARA", unionCode: 25 },
              { unionName: "DHALA PARA", unionCode: 34 },
              { unionName: "DIGALKANDI", unionCode: 43 },
              { unionName: "DIGAR", unionCode: 51 },
              { unionName: "GHATAIL", unionCode: 60 },
              { unionName: "JAMURIA", unionCode: 69 },
              { unionName: "LAKHER PARA", unionCode: 77 },
              { unionName: "RASULPUR", unionCode: 86 },
              { unionName: "SANDHANPUR", unionCode: 94 },
              { unionName: "GHATAIL CANTT.", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "GOPALPUR",
            subdistrictCode: 38,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALAMNAGAR", unionCode: 17 },
              { unionName: "DHOPAKANDI", unionCode: 21 },
              { unionName: "HADIRA", unionCode: 58 },
              { unionName: "HEMNAGAR", unionCode: 65 },
              { unionName: "JHAWAIL", unionCode: 73 },
              { unionName: "MIRZAPUR", unionCode: 80 },
              { unionName: "NAGDA SIMLA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KALIHATI",
            subdistrictCode: 47,
            unions: [
              { unionName: "WARD NO-01", unionCode: 31 },
              { unionName: "WARD NO-02", unionCode: 32 },
              { unionName: "WARD NO-03", unionCode: 33 },
              { unionName: "WARD NO-04", unionCode: 34 },
              { unionName: "WARD NO-05", unionCode: 35 },
              { unionName: "WARD NO-06", unionCode: 36 },
              { unionName: "WARD NO-07", unionCode: 37 },
              { unionName: "WARD NO-08", unionCode: 38 },
              { unionName: "WARD NO-09", unionCode: 39 },
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BALLA", unionCode: 12 },
              { unionName: "BANGRA", unionCode: 14 },
              { unionName: "BIR BASUNDA", unionCode: 21 },
              { unionName: "DASHKIA", unionCode: 25 },
              { unionName: "DURGAPUR", unionCode: 29 },
              { unionName: "GOHALIABARI", unionCode: 43 },
              { unionName: "KOK DAHARA", unionCode: 58 },
              { unionName: "NAGBARI", unionCode: 65 },
              { unionName: "NARANDIA", unionCode: 73 },
              { unionName: "PAIKARA", unionCode: 80 },
              { unionName: "PARKI", unionCode: 83 },
              { unionName: "SALLA", unionCode: 87 },
              { unionName: "SAHADEBPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MADHUPUR",
            subdistrictCode: 57,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALOKDIA", unionCode: 21 },
              { unionName: "ARANKHOLA", unionCode: 24 },
              { unionName: "AUSNARA", unionCode: 28 },
              { unionName: "BERI BAID", unionCode: 35 },
              { unionName: "FULBAG CHALA", unionCode: 48 },
              { unionName: "GOLABARI", unionCode: 66 },
              { unionName: "KURAGACHHA", unionCode: 69 },
              { unionName: "KURALIA", unionCode: 72 },
              { unionName: "MAHISHMARA", unionCode: 75 },
              { unionName: "MIRZABARI", unionCode: 78 },
              { unionName: "SOLAKURI", unionCode: 92 }
            ]
          },
          {
            subdistrictName: "MIRZAPUR",
            subdistrictCode: 66,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AJGANA", unionCode: 13 },
              { unionName: "ANAITARA", unionCode: 15 },
              { unionName: "BAHURIA", unionCode: 23 },
              { unionName: "BANAIL", unionCode: 31 },
              { unionName: "BANSHTAIL", unionCode: 34 },
              { unionName: "BHAORA", unionCode: 36 },
              { unionName: "BHATGRAM", unionCode: 39 },
              { unionName: "FATEHPUR", unionCode: 47 },
              { unionName: "GORAI", unionCode: 55 },
              { unionName: "JAMURKI", unionCode: 63 },
              { unionName: "LATIFPUR", unionCode: 67 },
              { unionName: "MAHERA", unionCode: 71 },
              { unionName: "TARAFPUR", unionCode: 87 },
              { unionName: "UARSI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NAGARPUR",
            subdistrictCode: 76,
            unions: [
              { unionName: "BEKRA", unionCode: 10 },
              { unionName: "BHADRA", unionCode: 12 },
              { unionName: "BHARA", unionCode: 14 },
              { unionName: "DUPTIAIR", unionCode: 21 },
              { unionName: "DHUBARIA", unionCode: 36 },
              { unionName: "GAYHATA", unionCode: 43 },
              { unionName: "MAMUDNAGAR", unionCode: 58 },
              { unionName: "MOKHNA", unionCode: 65 },
              { unionName: "NAGARPUR", unionCode: 73 },
              { unionName: "PAKUTIA", unionCode: 80 },
              { unionName: "SAHABATPUR", unionCode: 87 },
              { unionName: "SALIMABAD", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SAKHIPUR",
            subdistrictCode: 85,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAHERATAIL", unionCode: 13 },
              { unionName: "DARIAPUR", unionCode: 21 },
              { unionName: "GAZARIA", unionCode: 27 },
              { unionName: "HATIBANDHA", unionCode: 40 },
              { unionName: "JADABPUR", unionCode: 54 },
              { unionName: "KAKRAJAN", unionCode: 67 },
              { unionName: "KALIA", unionCode: 81 },
              { unionName: "KALMEGHA", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "TANGAIL SADAR",
            subdistrictCode: 95,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14 (PART)", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18", unionCode: 18 },
              { unionName: "BAGHIL", unionCode: 37 },
              { unionName: "DANYA", unionCode: 39 },
              { unionName: "GALA", unionCode: 41 },
              { unionName: "GHARINDA", unionCode: 47 },
              { unionName: "HUGRA", unionCode: 53 },
              { unionName: "KARATIA", unionCode: 59 },
              { unionName: "KATULI", unionCode: 65 },
              { unionName: "KAKUA", unionCode: 71 },
              { unionName: "MAGRA", unionCode: 77 },
              { unionName: "MAHAMUDNAGAR", unionCode: 80 },
              { unionName: "SILIMPUR", unionCode: 83 },
              { unionName: "PORABARI", unionCode: 90 }
            ]
          }
        ]
      }
    ]
  },
  {
    divisionName: "CHITTAGONG",
    divisionCode: 20,
    districts: [
      {
        districtName: "BANDARBAN",
        districtCode: 3,
        subdistricts: [
          {
            subdistrictName: "ALIKADAM",
            subdistrictCode: 4,
            unions: [
              { unionName: "ALIKADAM", unionCode: 31 },
              { unionName: "CHOKHYONG", unionCode: 63 },
              { unionName: "KURUPPATA", unionCode: 70 },
              { unionName: "NAYAPARA", unionCode: 80 }
            ]
          },
          {
            subdistrictName: "BANDARBAN SADAR",
            subdistrictCode: 14,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BANDARBAN", unionCode: 15 },
              { unionName: "KUHALONG", unionCode: 31 },
              { unionName: "RAJBILA", unionCode: 47 },
              { unionName: "SUWALAK", unionCode: 63 },
              { unionName: "TANKABATI", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "LAMA",
            subdistrictCode: 51,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AZIZNAGAR", unionCode: 15 },
              { unionName: "FAITANG", unionCode: 27 },
              { unionName: "FASYAKHALI", unionCode: 31 },
              { unionName: "GAJALIA", unionCode: 47 },
              { unionName: "LAMA", unionCode: 63 },
              { unionName: "RUPSHIPARA", unionCode: 68 },
              { unionName: "SARAI", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "NAIKHONGCHHARI",
            subdistrictCode: 73,
            unions: [
              { unionName: "BAISHARI", unionCode: 19 },
              { unionName: "DOCHHARI", unionCode: 38 },
              { unionName: "GHUMDUM", unionCode: 57 },
              { unionName: "NAIKHONGCHHARI", unionCode: 76 },
              { unionName: "SONAICHHARI", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "ROWANGCHHARI",
            subdistrictCode: 89,
            unions: [
              { unionName: "ALIKHONG", unionCode: 19 },
              { unionName: "NOWA PATANG", unionCode: 38 },
              { unionName: "ROWANGCHHARI", unionCode: 57 },
              { unionName: "TARACHHA", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "RUMA",
            subdistrictCode: 91,
            unions: [
              { unionName: "GHALANGYA", unionCode: 19 },
              { unionName: "PAINDU", unionCode: 38 },
              { unionName: "REMAKRI PRANSA", unionCode: 57 },
              { unionName: "RUMA", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "THANCHI",
            subdistrictCode: 95,
            unions: [
              { unionName: "BALIPARA", unionCode: 19 },
              { unionName: "REMAKRY", unionCode: 38 },
              { unionName: "THANCHI", unionCode: 57 },
              { unionName: "TINDU", unionCode: 76 }
            ]
          }
        ]
      },
      {
        districtName: "BRAHMANBARIA",
        districtCode: 12,
        subdistricts: [
          {
            subdistrictName: "AKHAURA",
            subdistrictCode: 2,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DAKSHIN AKHAURA", unionCode: 19 },
              { unionName: "DHARKHAR", unionCode: 57 },
              { unionName: "MANIAND", unionCode: 76 },
              { unionName: "MOGRA", unionCode: 85 },
              { unionName: "UTTAR AKHAURA", unionCode: 90 }
            ]
          },
          {
            subdistrictName: "BANCHHARAMPUR",
            subdistrictCode: 4,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AYUBPUR", unionCode: 14 },
              { unionName: "DARIADAULAT", unionCode: 23 },
              { unionName: "PAHARIAKANDI", unionCode: 30 },
              { unionName: "SAIFULLAKANDI", unionCode: 36 },
              { unionName: "RUPASDI", unionCode: 45 },
              { unionName: "MANIKPUR", unionCode: 54 },
              { unionName: "DARIKANDI", unionCode: 59 },
              { unionName: "FARDABAD", unionCode: 68 },
              { unionName: "UJAN CHAR", unionCode: 77 },
              { unionName: "SALIMABAD", unionCode: 81 },
              { unionName: "SONARAMPUR", unionCode: 83 },
              { unionName: "TEZKHALI", unionCode: 88 },
              { unionName: "BANCHHARAMPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BIJOYNAGAR",
            subdistrictCode: 7,
            unions: [
              { unionName: "BUDHANTI", unionCode: 28 },
              { unionName: "CHANDURA", unionCode: 32 },
              { unionName: "CHAR ISLMAPUR", unionCode: 36 },
              { unionName: "CHAMPAKNAGAR", unionCode: 43 },
              { unionName: "DAKSHIN SINGERBIL", unionCode: 52 },
              { unionName: "HARASHPUR", unionCode: 56 },
              { unionName: "PAHARPUR", unionCode: 66 },
              { unionName: "PATTAN", unionCode: 72 },
              { unionName: "UTTAR ICHHAPUR", unionCode: 91 },
              { unionName: "BISHNUPUR", unionCode: 96 }
            ]
          },
          {
            subdistrictName: "BRAHMANBARIA SADAR",
            subdistrictCode: 13,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "BASUDEB", unionCode: 17 },
              { unionName: "DAKSHIN NATAI", unionCode: 48 },
              { unionName: "SHUHILPUR", unionCode: 50 },
              { unionName: "MACHHIHATA", unionCode: 59 },
              { unionName: "MAJLISHPUR", unionCode: 61 },
              { unionName: "PURBA TALSAHAR", unionCode: 81 },
              { unionName: "RAMRAIL", unionCode: 83 },
              { unionName: "SADEKPUR", unionCode: 86 },
              { unionName: "SULTANPUR", unionCode: 88 },
              { unionName: "UTTAR NATAI", unionCode: 93 },
              { unionName: "BUDHAL", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "ASHUGANJ",
            subdistrictCode: 33,
            unions: [
              { unionName: "ARAISIDHA", unionCode: 12 },
              { unionName: "ASHUGANG", unionCode: 14 },
              { unionName: "CHAR CHARTALA", unionCode: 26 },
              { unionName: "DURGAPUR", unionCode: 38 },
              { unionName: "LALPUR", unionCode: 42 },
              { unionName: "PASCHIM TALSAHAR", unionCode: 74 },
              { unionName: "SHARIFPUR", unionCode: 77 },
              { unionName: "TARUA", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "KASBA",
            subdistrictCode: 63,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BADAIR", unionCode: 18 },
              { unionName: "BAYEK", unionCode: 31 },
              { unionName: "BINAUTI", unionCode: 37 },
              { unionName: "GOPINATHPUR", unionCode: 50 },
              { unionName: "KAIMPUR", unionCode: 56 },
              { unionName: "KASBA PASCHIM", unionCode: 63 },
              { unionName: "KHERERA", unionCode: 65 },
              { unionName: "KUTI", unionCode: 69 },
              { unionName: "MEHARI", unionCode: 82 },
              { unionName: "MULGRAM", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NABINAGAR",
            subdistrictCode: 85,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARIKANDI", unionCode: 12 },
              { unionName: "BIDDYAKUT", unionCode: 13 },
              { unionName: "BIRGAON", unionCode: 18 },
              { unionName: "BITGHAR (TIARA)", unionCode: 22 },
              { unionName: "IBRAHIMPUR", unionCode: 27 },
              { unionName: "JUNEDPUR", unionCode: 36 },
              { unionName: "KAITALA DAKSHIN", unionCode: 40 },
              { unionName: "KAITALA UTTAR", unionCode: 42 },
              { unionName: "KRISHNANAGAR", unionCode: 45 },
              { unionName: "LAUR FATEHPUR", unionCode: 47 },
              { unionName: "NATGHAR", unionCode: 58 },
              { unionName: "PASCHIM NABINAGAR", unionCode: 60 },
              { unionName: "PURBA NABINAGAR", unionCode: 62 },
              { unionName: "RASULLABAD", unionCode: 67 },
              { unionName: "RATANPUR", unionCode: 72 },
              { unionName: "SALIMGANJ", unionCode: 75 },
              { unionName: "BARAIL", unionCode: 81 },
              { unionName: "SATMURA", unionCode: 83 },
              { unionName: "SHIBPUR", unionCode: 86 },
              { unionName: "SHYAMGRAM", unionCode: 88 },
              { unionName: "SREERAMPUR", unionCode: 90 }
            ]
          },
          {
            subdistrictName: "NASIRNAGAR",
            subdistrictCode: 90,
            unions: [
              { unionName: "BURISHWAR", unionCode: 14 },
              { unionName: "BOLAKOT", unionCode: 17 },
              { unionName: "CHAPARTALA", unionCode: 21 },
              { unionName: "CHATALPAR", unionCode: 29 },
              { unionName: "DHAR MANDAL", unionCode: 36 },
              { unionName: "FANDAUK", unionCode: 43 },
              { unionName: "GOALNAGAR", unionCode: 51 },
              { unionName: "GOKARNA", unionCode: 58 },
              { unionName: "GUNIAK", unionCode: 65 },
              { unionName: "HARIPUR", unionCode: 73 },
              { unionName: "KUNDA", unionCode: 80 },
              { unionName: "NASIRNAGAR", unionCode: 87 },
              { unionName: "PURBABAGH", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SARAIL",
            subdistrictCode: 94,
            unions: [
              { unionName: "AORAIL", unionCode: 13 },
              { unionName: "CHUNTA", unionCode: 19 },
              { unionName: "KALIKACHCHHA", unionCode: 28 },
              { unionName: "NOAGAON", unionCode: 38 },
              { unionName: "PAK SHIMUL", unionCode: 47 },
              { unionName: "SARAIL", unionCode: 76 },
              { unionName: "SHAHBAZPUR", unionCode: 85 },
              { unionName: "SHAHJADAPUR", unionCode: 90 },
              { unionName: "UTTAR PANISAR", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "CHANDPUR",
        districtCode: 13,
        subdistricts: [
          {
            subdistrictName: "CHANDPUR SADAR",
            subdistrictCode: 22,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "ASHIKATI", unionCode: 18 },
              { unionName: "KALYANPUR", unionCode: 20 },
              { unionName: "BAGHADI", unionCode: 22 },
              { unionName: "BALIA", unionCode: 27 },
              { unionName: "BISHNUPUR", unionCode: 31 },
              { unionName: "CHANDRA", unionCode: 54 },
              { unionName: "HANAR CHAR", unionCode: 58 },
              { unionName: "IBRAHIMPUR", unionCode: 63 },
              { unionName: "MAISHADI", unionCode: 67 },
              { unionName: "RAJRAJESHWAR", unionCode: 76 },
              { unionName: "SHAH MAHMUDPUR", unionCode: 81 },
              { unionName: "RAMPUR", unionCode: 85 },
              { unionName: "LAKSHMIPUR MODEL", unionCode: 90 },
              { unionName: "TARPUR CHANDI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "FARIDGANJ",
            subdistrictCode: 45,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "PASCHIM BALUTHUPA", unionCode: 11 },
              { unionName: "PURBA BALUTHUPA", unionCode: 13 },
              { unionName: "PURBA CHAR DUKHIA", unionCode: 17 },
              { unionName: "PASCHIM CHAR DUKHIA", unionCode: 23 },
              { unionName: "DAKSHIN FARIDGANJ", unionCode: 35 },
              { unionName: "UTTAR GOBINDAPUR", unionCode: 41 },
              { unionName: "DAKSHIN GOBINDAPUR", unionCode: 47 },
              { unionName: "PURBA GUPTI", unionCode: 53 },
              { unionName: "PASCHIM GUPTI", unionCode: 59 },
              { unionName: "UTTAR PAIKPARA", unionCode: 65 },
              { unionName: "DAKSHIN PAIK PARA", unionCode: 71 },
              { unionName: "UTTAR RUPSA", unionCode: 77 },
              { unionName: "DAKSHIN RUPSA", unionCode: 83 },
              { unionName: "PURBA SUBIDPUR", unionCode: 89 },
              { unionName: "PASCHIM SUBIDPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "HAIM CHAR",
            subdistrictCode: 47,
            unions: [
              { unionName: "UTTAR ALGI DURGAPUR", unionCode: 11 },
              { unionName: "DAKSHIN ALGI DURGAPUR", unionCode: 23 },
              { unionName: "CHAR BHAIRABI", unionCode: 35 },
              { unionName: "HAIM CHAR", unionCode: 47 },
              { unionName: "GAZIPUR", unionCode: 59 },
              { unionName: "NILKAMAL", unionCode: 71 }
            ]
          },
          {
            subdistrictName: "HAJIGANJ",
            subdistrictCode: 49,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "PURBA BARKUL", unionCode: 18 },
              { unionName: "PASCHIM BARKUL", unionCode: 20 },
              { unionName: "UTTAR GANDHARABPUR", unionCode: 25 },
              { unionName: "DADOSH", unionCode: 28 },
              { unionName: "DAKSHIN GANDHARBAPUR", unionCode: 30 },
              { unionName: "HAJIGANJ", unionCode: 35 },
              { unionName: "UTTAR KALOCHO", unionCode: 50 },
              { unionName: "DAKSHIN KALOCHO", unionCode: 55 },
              { unionName: "PACHIM HATILA", unionCode: 60 },
              { unionName: "HATILA PURBA", unionCode: 65 },
              { unionName: "UTTAR RAJARGAON", unionCode: 70 },
              { unionName: "BAKILA", unionCode: 75 }
            ]
          },
          {
            subdistrictName: "KACHUA",
            subdistrictCode: 58,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ASHRAFPUR", unionCode: 13 },
              { unionName: "BITARA", unionCode: 15 },
              { unionName: "UTTAR GOHAT", unionCode: 23 },
              { unionName: "DAKSHIN GOHAT", unionCode: 31 },
              { unionName: "UTTAR KACHUA", unionCode: 39 },
              { unionName: "DAKSHIN KACHUA", unionCode: 47 },
              { unionName: "KADLA", unionCode: 55 },
              { unionName: "KARAIA", unionCode: 63 },
              { unionName: "SACHAR", unionCode: 71 },
              { unionName: "PATHAIR", unionCode: 79 },
              { unionName: "PURBA SAHADEBPUR", unionCode: 87 },
              { unionName: "PASCHIM SAHADEBPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MATLAB DAKSHIN",
            subdistrictCode: 76,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "KHADERGAON", unionCode: 34 },
              { unionName: "NARAYANPUR", unionCode: 43 },
              { unionName: "UTTAR NAYERGAON", unionCode: 56 },
              { unionName: "UTTAR UPADI", unionCode: 60 },
              { unionName: "DAKHSIN NAYERGAON", unionCode: 82 },
              { unionName: "DAKSHIN UPADI", unionCode: 86 }
            ]
          },
          {
            subdistrictName: "MATLAB UTTAR",
            subdistrictCode: 79,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAGANBARI", unionCode: 11 },
              { unionName: "FARAJIKANDI", unionCode: 13 },
              { unionName: "DURGAPUR", unionCode: 15 },
              { unionName: "PURBA FATEHPUR", unionCode: 17 },
              { unionName: "EKLASPUR", unionCode: 21 },
              { unionName: "GAJRA", unionCode: 23 },
              { unionName: "ISLAMABAD", unionCode: 25 },
              { unionName: "JAHIRABAD", unionCode: 30 },
              { unionName: "KALAKANDA", unionCode: 32 },
              { unionName: "MOHANPUR", unionCode: 38 },
              { unionName: "SADULLAPUR", unionCode: 64 },
              { unionName: "SATNAL", unionCode: 73 },
              { unionName: "SULTANABAD", unionCode: 90 },
              { unionName: "PASCHIM FATEHPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SHAHRASTI",
            subdistrictCode: 95,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "EAST CHITASI", unionCode: 15 },
              { unionName: "WEST CHITASI", unionCode: 20 },
              { unionName: "UTTAR MEHER", unionCode: 60 },
              { unionName: "DAKSHIN MEHER", unionCode: 65 },
              { unionName: "UTTAR ROYSREE", unionCode: 80 },
              { unionName: "DAKSHIN ROYSREE", unionCode: 82 },
              { unionName: "UTTAR SUCHI PARA", unionCode: 85 },
              { unionName: "DAKSHIN SUCHI PARA", unionCode: 90 },
              { unionName: "TAMTA DAKSHIN", unionCode: 95 },
              { unionName: "TAMTA UTTAR", unionCode: 96 }
            ]
          }
        ]
      },
      {
        districtName: "CHITTAGONG",
        districtCode: 15,
        subdistricts: [
          {
            subdistrictName: "ANOWARA",
            subdistrictCode: 4,
            unions: [
              { unionName: "ANOWARA", unionCode: 15 },
              { unionName: "BAIRAG (PART)", unionCode: 19 },
              { unionName: "BARAKHAIN", unionCode: 28 },
              { unionName: "BARASAT", unionCode: 38 },
              { unionName: "BURUMCHHARA", unionCode: 47 },
              { unionName: "BATTALI", unionCode: 57 },
              { unionName: "CHATARI", unionCode: 66 },
              { unionName: "HAILDHAR", unionCode: 76 },
              { unionName: "JUIDANDI", unionCode: 81 },
              { unionName: "PARAIKORA", unionCode: 85 },
              { unionName: "ROYPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "BAYEJID BOSTAMI",
            subdistrictCode: 6,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "CHITTAGANG CNT.", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "BANSHKHALI",
            subdistrictCode: 8,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAHARCHHARA", unionCode: 11 },
              { unionName: "BAILCHHARI", unionCode: 12 },
              { unionName: "CHAMBAL", unionCode: 18 },
              { unionName: "CHHANUA", unionCode: 25 },
              { unionName: "GANDAMARA", unionCode: 31 },
              { unionName: "KALIPUR", unionCode: 44 },
              { unionName: "KATHARIA", unionCode: 50 },
              { unionName: "KHANKHANABAD", unionCode: 56 },
              { unionName: "PUICHHARI", unionCode: 63 },
              { unionName: "PUKURIA", unionCode: 69 },
              { unionName: "SADHANPUR", unionCode: 75 },
              { unionName: "SARAL", unionCode: 82 },
              { unionName: "SEKHERKHIL", unionCode: 88 },
              { unionName: "SILKUP", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BAKALIA",
            subdistrictCode: 10,
            unions: [
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18", unionCode: 18 },
              { unionName: "WARD NO-19", unionCode: 19 },
              { unionName: "WARD NO-35 (PART)", unionCode: 35 }
            ]
          },
          {
            subdistrictName: "BOALKHALI",
            subdistrictCode: 12,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AHLA KARALDANGA", unionCode: 17 },
              { unionName: "AMUCHIA", unionCode: 19 },
              { unionName: "CHARANDWIP", unionCode: 28 },
              { unionName: "PURBA GOMDANDI", unionCode: 38 },
              { unionName: "KANDHURKHIL", unionCode: 47 },
              { unionName: "PASCHIM GOMDANDI", unionCode: 50 },
              { unionName: "POPADIA", unionCode: 57 },
              { unionName: "SAROATALI", unionCode: 66 },
              { unionName: "SAKPURA", unionCode: 76 },
              { unionName: "SREEPUR KHARANDWIP", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "CHANDANAISH",
            subdistrictCode: 18,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAILTALI", unionCode: 13 },
              { unionName: "BARKAL", unionCode: 19 },
              { unionName: "BARAMA", unionCode: 28 },
              { unionName: "DHOPACHHARI", unionCode: 38 },
              { unionName: "DOHAZARI", unionCode: 47 },
              { unionName: "HASHIMPUR", unionCode: 66 },
              { unionName: "JOARA", unionCode: 76 },
              { unionName: "KANCHANABAD", unionCode: 85 },
              { unionName: "SATBARIA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "CHANDGAON",
            subdistrictCode: 19,
            unions: [
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 }
            ]
          },
          {
            subdistrictName: "CHITTAGONG PORT",
            subdistrictCode: 20,
            unions: [
              { unionName: "WARD NO-37", unionCode: 37 },
              { unionName: "WARD NO-38", unionCode: 38 },
              { unionName: "WARD NO-39", unionCode: 39 }
            ]
          },
          {
            subdistrictName: "DOUBLE MOORING",
            subdistrictCode: 28,
            unions: [
              { unionName: "WARD NO-23", unionCode: 23 },
              { unionName: "WARD NO-24 (PART)", unionCode: 24 },
              { unionName: "WARD NO-27", unionCode: 27 },
              { unionName: "WARD NO-28", unionCode: 28 },
              { unionName: "WARD NO-29", unionCode: 29 },
              { unionName: "WARD NO-30", unionCode: 30 },
              { unionName: "WARD NO-36", unionCode: 36 }
            ]
          },
          {
            subdistrictName: "FATIKCHHARI",
            subdistrictCode: 33,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ABDULLAPUR", unionCode: 11 },
              { unionName: "BAGAN BAZAR", unionCode: 13 },
              { unionName: "BAKTAPUR", unionCode: 14 },
              { unionName: "BHUJPUR", unionCode: 19 },
              { unionName: "DANTMARA", unionCode: 23 },
              { unionName: "DHARMAPUR", unionCode: 28 },
              { unionName: "DAULATPUR", unionCode: 38 },
              { unionName: "HARWALCHHARI", unionCode: 42 },
              { unionName: "JAFARNAGAR", unionCode: 47 },
              { unionName: "KANCHAN NAGAR", unionCode: 52 },
              { unionName: "KHIRAM", unionCode: 55 },
              { unionName: "LELANG", unionCode: 57 },
              { unionName: "NANUPUR", unionCode: 61 },
              { unionName: "NARAYANHAT", unionCode: 66 },
              { unionName: "PAINDANGA", unionCode: 71 },
              { unionName: "ROUSHANGIRI", unionCode: 80 },
              { unionName: "SUABIL", unionCode: 85 },
              { unionName: "SAMITIRHAT", unionCode: 90 },
              { unionName: "SUNDARPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "HALISHAHAR",
            subdistrictCode: 35,
            unions: [
              { unionName: "WARD NO-11 (PART)", unionCode: 11 },
              { unionName: "WARD NO-24(PART)", unionCode: 24 },
              { unionName: "WARD NO-25", unionCode: 25 },
              { unionName: "WARD NO-26", unionCode: 26 }
            ]
          },
          {
            subdistrictName: "HATHAZARI",
            subdistrictCode: 37,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BURISH CHAR", unionCode: 10 },
              { unionName: "CHHIBATALI", unionCode: 11 },
              { unionName: "CHIKANDANDI", unionCode: 17 },
              { unionName: "DAKSHIN MADARSHA", unionCode: 23 },
              { unionName: "DHALAI", unionCode: 29 },
              { unionName: "FATEHPUR", unionCode: 35 },
              { unionName: "FORHADABAD", unionCode: 41 },
              { unionName: "GARDUARA", unionCode: 47 },
              { unionName: "GUMAN MARDAN", unionCode: 53 },
              { unionName: "HATHAZARI", unionCode: 59 },
              { unionName: "MEKHAL", unionCode: 65 },
              { unionName: "MIRZAPUR", unionCode: 71 },
              { unionName: "NANGALMORA", unionCode: 77 },
              { unionName: "SHIKARPUR", unionCode: 89 },
              { unionName: "UTTAR MADARSA", unionCode: 95 },
              { unionName: "CHITTAGANG CNT.", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "KOTWALI",
            subdistrictCode: 41,
            unions: [
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16 (PART)", unionCode: 16 },
              { unionName: "WARD NO-20", unionCode: 20 },
              { unionName: "WARD NO-21", unionCode: 21 },
              { unionName: "WARD NO-22", unionCode: 22 },
              { unionName: "WARD NO-31", unionCode: 31 },
              { unionName: "WARD NO-32", unionCode: 32 },
              { unionName: "WARD NO-33", unionCode: 33 },
              { unionName: "WARD NO-34", unionCode: 34 },
              { unionName: "WARD NO-35 (PART)", unionCode: 35 }
            ]
          },
          {
            subdistrictName: "KHULSHI",
            subdistrictCode: 43,
            unions: [
              { unionName: "WARD NO-08(PART)", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 }
            ]
          },
          {
            subdistrictName: "LOHAGARA",
            subdistrictCode: 47,
            unions: [
              { unionName: "AMIRABAD", unionCode: 10 },
              { unionName: "ADHUNAGAR", unionCode: 13 },
              { unionName: "BARAHATIA", unionCode: 18 },
              { unionName: "CHARAMBA", unionCode: 25 },
              { unionName: "CHUNATI", unionCode: 32 },
              { unionName: "KALAUZAN", unionCode: 43 },
              { unionName: "LOHAGARA", unionCode: 62 },
              { unionName: "PADUA", unionCode: 73 },
              { unionName: "PUTIBILA", unionCode: 80 }
            ]
          },
          {
            subdistrictName: "MIRSHARAI",
            subdistrictCode: 53,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "DHUM", unionCode: 22 },
              { unionName: "DURGAPUR", unionCode: 24 },
              { unionName: "HAITKANDI", unionCode: 27 },
              { unionName: "HINGULI", unionCode: 28 },
              { unionName: "ICHHAKHALI", unionCode: 29 },
              { unionName: "KARERHAT", unionCode: 35 },
              { unionName: "KATACHHARA", unionCode: 41 },
              { unionName: "KHAIYACHHARA", unionCode: 47 },
              { unionName: "MAYANI", unionCode: 53 },
              { unionName: "MIRSHARAI", unionCode: 59 },
              { unionName: "MITHANALA", unionCode: 65 },
              { unionName: "MAGHADIA", unionCode: 71 },
              { unionName: "OSMANPUR", unionCode: 77 },
              { unionName: "SAHERKHALI", unionCode: 83 },
              { unionName: "WAHEDPUR", unionCode: 89 },
              { unionName: "ZORWARGANJ", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "PAHARTALI",
            subdistrictCode: 55,
            unions: [
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11 (PART)", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 }
            ]
          },
          {
            subdistrictName: "PANCHLAISH",
            subdistrictCode: 57,
            unions: [
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08(PART)", unionCode: 8 },
              { unionName: "WARD NO-16 (PART)", unionCode: 16 }
            ]
          },
          {
            subdistrictName: "PATIYA",
            subdistrictCode: 61,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ASIA", unionCode: 12 },
              { unionName: "BARALIA", unionCode: 15 },
              { unionName: "BARA UTHAN", unionCode: 18 },
              { unionName: "BHATIKHAIN", unionCode: 19 },
              { unionName: "CHANHARA", unionCode: 21 },
              { unionName: "CHAR LAKSHYA", unionCode: 24 },
              { unionName: "CHAR PATHARGHATA", unionCode: 27 },
              { unionName: "DHALGHAT", unionCode: 30 },
              { unionName: "HABILAS DWIP", unionCode: 39 },
              { unionName: "HAIDGAON", unionCode: 42 },
              { unionName: "JANGLUKHAIN", unionCode: 52 },
              { unionName: "JULDHA", unionCode: 55 },
              { unionName: "KACHUAI", unionCode: 58 },
              { unionName: "KASIAIS", unionCode: 64 },
              { unionName: "KELISHAHAR", unionCode: 67 },
              { unionName: "KHARANA", unionCode: 70 },
              { unionName: "KOLAGAON", unionCode: 73 },
              { unionName: "KUSUMPURA", unionCode: 76 },
              { unionName: "SIKALBAHA", unionCode: 85 },
              { unionName: "SOBHANDANDI", unionCode: 88 },
              { unionName: "DAKHIN D.BHURSHI", unionCode: 91 },
              { unionName: "JIRI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "PATENGA",
            subdistrictCode: 65,
            unions: [
              { unionName: "WARD NO-40", unionCode: 40 },
              { unionName: "WARD NO-41", unionCode: 41 }
            ]
          },
          {
            subdistrictName: "RANGUNIA",
            subdistrictCode: 70,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BETAGI", unionCode: 15 },
              { unionName: "CHANDRAGHONA KADAMTALI", unionCode: 17 },
              { unionName: "RAJANAGAR", unionCode: 21 },
              { unionName: "HOSNABAD", unionCode: 25 },
              { unionName: "ISLAMPUR", unionCode: 27 },
              { unionName: "KODALA", unionCode: 30 },
              { unionName: "LALANAGAR", unionCode: 32 },
              { unionName: "MARIAMNAGAR", unionCode: 34 },
              { unionName: "PADUA", unionCode: 43 },
              { unionName: "PARUA", unionCode: 51 },
              { unionName: "POMARA", unionCode: 60 },
              { unionName: "DAKSHIN RAJANAGAR", unionCode: 69 },
              { unionName: "RANGUNIA", unionCode: 77 },
              { unionName: "SARAPBHATA", unionCode: 86 },
              { unionName: "SILOK", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "RAOZAN",
            subdistrictCode: 74,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAGOAN", unionCode: 11 },
              { unionName: "BINAJURI", unionCode: 12 },
              { unionName: "CHIKDAIR", unionCode: 18 },
              { unionName: "DABUA", unionCode: 25 },
              { unionName: "PURBA GUZARA", unionCode: 31 },
              { unionName: "GAHIRA", unionCode: 37 },
              { unionName: "HALADIA", unionCode: 44 },
              { unionName: "KADALPUR", unionCode: 50 },
              { unionName: "NOAJISPUR", unionCode: 56 },
              { unionName: "NOA PARA", unionCode: 63 },
              { unionName: "PAHARTALI", unionCode: 69 },
              { unionName: "RAOZAN", unionCode: 75 },
              { unionName: "URKIRCHAR", unionCode: 88 },
              { unionName: "PASCHIM GUZARA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SANDWIP",
            subdistrictCode: 78,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMANULLAH", unionCode: 11 },
              { unionName: "AZIMPUR", unionCode: 13 },
              { unionName: "BAURIA", unionCode: 20 },
              { unionName: "DIGGHAPAR", unionCode: 25 },
              { unionName: "GACHHUA", unionCode: 30 },
              { unionName: "HARAMIA", unionCode: 35 },
              { unionName: "HARISPUR", unionCode: 40 },
              { unionName: "KALAPANIA", unionCode: 55 },
              { unionName: "MAGDHARA", unionCode: 65 },
              { unionName: "MAITBHANGA", unionCode: 70 },
              { unionName: "MUSAPUR", unionCode: 75 },
              { unionName: "RAHMATPUR", unionCode: 85 },
              { unionName: "SANTOSHPUR", unionCode: 90 },
              { unionName: "SARIKAIT", unionCode: 92 },
              { unionName: "URIRCHAR", unionCode: 96 }
            ]
          },
          {
            subdistrictName: "SATKANIA",
            subdistrictCode: 82,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMILAIS", unionCode: 12 },
              { unionName: "EOCHIA", unionCode: 14 },
              { unionName: "BAZALIA", unionCode: 21 },
              { unionName: "CHARATI", unionCode: 29 },
              { unionName: "DHEMSA", unionCode: 36 },
              { unionName: "DHARMAPUR", unionCode: 40 },
              { unionName: "KALIAIS", unionCode: 47 },
              { unionName: "KANCHANA", unionCode: 51 },
              { unionName: "KEOCHIA", unionCode: 54 },
              { unionName: "KHAGARIA", unionCode: 58 },
              { unionName: "MADARSA", unionCode: 65 },
              { unionName: "NALUA", unionCode: 69 },
              { unionName: "PURANAGAR", unionCode: 76 },
              { unionName: "SADAHA", unionCode: 83 },
              { unionName: "SATKANIA", unionCode: 87 },
              { unionName: "SONAKANIA", unionCode: 91 },
              { unionName: "PASCHIM DHEMSA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SITAKUNDA",
            subdistrictCode: 86,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BANSHBARIA", unionCode: 16 },
              { unionName: "BARABKUNDA", unionCode: 19 },
              { unionName: "BARIADYALA", unionCode: 28 },
              { unionName: "BHATIARI", unionCode: 38 },
              { unionName: "KUMIRA", unionCode: 47 },
              { unionName: "MURADPUR", unionCode: 57 },
              { unionName: "SALIMPUR", unionCode: 66 },
              { unionName: "SONAICHHARI", unionCode: 85 },
              { unionName: "SAIDPUR", unionCode: 95 },
              { unionName: "BHATIARI CANTONMENT AREA", unionCode: 98 }
            ]
          }
        ]
      },
      {
        districtName: "COMILLA",
        districtCode: 19,
        subdistricts: [
          {
            subdistrictName: "BARURA",
            subdistrictCode: 9,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ADDA", unionCode: 11 },
              { unionName: "ADRA", unionCode: 12 },
              { unionName: "AGANAGAR", unionCode: 18 },
              { unionName: "BHABANIPUR", unionCode: 25 },
              { unionName: "BHAUKSAR", unionCode: 31 },
              { unionName: "GALIMPUR", unionCode: 44 },
              { unionName: "CHITADDA", unionCode: 50 },
              { unionName: "JALAM", unionCode: 56 },
              { unionName: "UTTAR KHOSBAS", unionCode: 63 },
              { unionName: "DAKSHIN KHOSBAS", unionCode: 69 },
              { unionName: "UTTAR PAYALGACHHA", unionCode: 75 },
              { unionName: "LAKSHMIPUR", unionCode: 82 },
              { unionName: "UTTAR SHILMURI", unionCode: 88 },
              { unionName: "SHAKPUR", unionCode: 91 },
              { unionName: "DAKSHIN SHILMURI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BRAHMAN PARA",
            subdistrictCode: 15,
            unions: [
              { unionName: "BRAHMAN PARA", unionCode: 18 },
              { unionName: "DULALPUR", unionCode: 30 },
              { unionName: "CHANDLA", unionCode: 44 },
              { unionName: "SIDLAI", unionCode: 50 },
              { unionName: "MADHABPUR", unionCode: 56 },
              { unionName: "MALAPARA", unionCode: 62 },
              { unionName: "SAHEBABAD", unionCode: 82 },
              { unionName: "SHASHIDAL", unionCode: 88 }
            ]
          },
          {
            subdistrictName: "BURICHANG",
            subdistrictCode: 18,
            unions: [
              { unionName: "BAKSIMAIL", unionCode: 11 },
              { unionName: "BHARELLA DAKSHIN", unionCode: 12 },
              { unionName: "BHARELLA UTTAR", unionCode: 15 },
              { unionName: "BURICHANG", unionCode: 31 },
              { unionName: "MAINAMATI", unionCode: 63 },
              { unionName: "MOKAM", unionCode: 69 },
              { unionName: "PIR JATRAPUR", unionCode: 72 },
              { unionName: "RAJAPUR", unionCode: 75 },
              { unionName: "SHOLANAL", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "CHANDINA",
            subdistrictCode: 27,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "KERAN KHAL", unionCode: 13 },
              { unionName: "BARERA", unionCode: 15 },
              { unionName: "MAHICHAL", unionCode: 20 },
              { unionName: "BARA KARAI", unionCode: 23 },
              { unionName: "JOYAG", unionCode: 31 },
              { unionName: "BARKAIT", unionCode: 39 },
              { unionName: "ATBAR PUR", unionCode: 47 },
              { unionName: "GALLAI", unionCode: 55 },
              { unionName: "DOLLAI NOWABAB PUR", unionCode: 63 },
              { unionName: "MADHYA", unionCode: 71 },
              { unionName: "MAIJKHAR", unionCode: 79 },
              { unionName: "BATAKASH", unionCode: 87 },
              { unionName: "SUHILPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "CHAUDDAGRAM",
            subdistrictCode: 31,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALKARA", unionCode: 10 },
              { unionName: "BATISHA", unionCode: 15 },
              { unionName: "CHEORA", unionCode: 19 },
              { unionName: "GHOLPASHA", unionCode: 38 },
              { unionName: "GUNABATI", unionCode: 42 },
              { unionName: "JAGANNATH DIGHI", unionCode: 47 },
              { unionName: "KALIKAPUR", unionCode: 52 },
              { unionName: "KANKAPAIT", unionCode: 57 },
              { unionName: "KASHINAGAR", unionCode: 61 },
              { unionName: "MUNSHIRHAT", unionCode: 71 },
              { unionName: "SHUBHAPUR", unionCode: 85 },
              { unionName: "SREEPUR", unionCode: 90 },
              { unionName: "UJIRPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "COMILLA SADAR DAKSHIN",
            subdistrictCode: 33,
            unions: [
              { unionName: "WARD NO-19", unionCode: 59 },
              { unionName: "WARD NO-20", unionCode: 60 },
              { unionName: "WARD NO-21", unionCode: 61 },
              { unionName: "WARD NO-22", unionCode: 62 },
              { unionName: "WARD NO-23", unionCode: 63 },
              { unionName: "WARD NO-24", unionCode: 64 },
              { unionName: "WARD NO-25", unionCode: 65 },
              { unionName: "WARD NO-26", unionCode: 66 },
              { unionName: "WARD NO-27", unionCode: 67 },
              { unionName: "BAGHMARA DAKSHIN", unionCode: 12 },
              { unionName: "BAGHMARA UTTAR", unionCode: 13 },
              { unionName: "BARA PARA", unionCode: 24 },
              { unionName: "BELGHAR DAKSHIN", unionCode: 28 },
              { unionName: "BELGHAR UTTAR", unionCode: 29 },
              { unionName: "BHOLAIN (DAKSHIN)", unionCode: 31 },
              { unionName: "BHOLAIN (UTTAR)", unionCode: 33 },
              { unionName: "BIJOYPUR", unionCode: 35 },
              { unionName: "CHOUARA", unionCode: 39 },
              { unionName: "GALIARA", unionCode: 58 },
              { unionName: "PURBA JOREKARAN", unionCode: 73 },
              { unionName: "PASCHIM JOREKARAN", unionCode: 80 },
              { unionName: "PERUL (DAKSHIN)", unionCode: 83 },
              { unionName: "PERUL (UTTAR)", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "DAUDKANDI",
            subdistrictCode: 36,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DAUDKANDI UTTAR", unionCode: 21 },
              { unionName: "BITESHWAR", unionCode: 25 },
              { unionName: "ELLIOTGANJ UTTAR", unionCode: 30 },
              { unionName: "ELLIOTGANJ DAKSHIN", unionCode: 34 },
              { unionName: "GOALMARI", unionCode: 38 },
              { unionName: "JINGLATALI", unionCode: 47 },
              { unionName: "GAURIPUR", unionCode: 51 },
              { unionName: "MARUKA", unionCode: 64 },
              { unionName: "MOHAMMADPUR PASCHIM", unionCode: 73 },
              { unionName: "DAULATPUR (PURBA PANCHGACHHIA)", unionCode: 82 },
              { unionName: "MOHAMMADPUR PURBA", unionCode: 84 },
              { unionName: "PADUA", unionCode: 85 },
              { unionName: "PANCHGACHHIA PASCHIM", unionCode: 86 },
              { unionName: "BARAPARA", unionCode: 90 },
              { unionName: "SUNDALPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DEBIDWAR",
            subdistrictCode: 40,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA SHALGHAR", unionCode: 10 },
              { unionName: "ISAB PUR", unionCode: 11 },
              { unionName: "MOHAN PUR", unionCode: 17 },
              { unionName: "BARKAMTA", unionCode: 23 },
              { unionName: "DHAMTI", unionCode: 35 },
              { unionName: "SULTANPUR", unionCode: 41 },
              { unionName: "FATEHABAD", unionCode: 47 },
              { unionName: "UTTAR GUNAIGHAR", unionCode: 53 },
              { unionName: "DAKSHIN GUNAIGHAR", unionCode: 59 },
              { unionName: "JAFARGANJ", unionCode: 65 },
              { unionName: "ELAHABAD", unionCode: 71 },
              { unionName: "RAJAMEHAR", unionCode: 77 },
              { unionName: "BHANI", unionCode: 83 },
              { unionName: "RASULPUR", unionCode: 89 },
              { unionName: "SUBIL", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "HOMNA",
            subdistrictCode: 54,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ASADPUR", unionCode: 15 },
              { unionName: "BHASANIA", unionCode: 20 },
              { unionName: "DULAL PUR", unionCode: 22 },
              { unionName: "JOYPUR", unionCode: 25 },
              { unionName: "CHANDER CHAR", unionCode: 28 },
              { unionName: "GHAGUTIA", unionCode: 47 },
              { unionName: "GARMORA", unionCode: 50 },
              { unionName: "MATHABANGA", unionCode: 76 },
              { unionName: "NILAKHI", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "COMILLA ADARSHA SADAR",
            subdistrictCode: 67,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18", unionCode: 18 },
              { unionName: "AMRATALI", unionCode: 22 },
              { unionName: "DAKSHIN DURGAPUR", unionCode: 51 },
              { unionName: "JAGANNATHPUR", unionCode: 65 },
              { unionName: "KALIR BAZAR", unionCode: 68 },
              { unionName: "PANCHTHUBI", unionCode: 90 },
              { unionName: "UTTAR DURGAPUR", unionCode: 94 },
              { unionName: "COMILLA CANTT", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "LAKSAM",
            subdistrictCode: 72,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAKAI", unionCode: 17 },
              { unionName: "GOBINDAPUR", unionCode: 35 },
              { unionName: "LAKSAM", unionCode: 52 },
              { unionName: "MUDAFARGANJ", unionCode: 63 },
              { unionName: "KANDIRPAR", unionCode: 77 },
              { unionName: "AJGARA", unionCode: 84 },
              { unionName: "UTTARDAH", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "MANOHARGANJ",
            subdistrictCode: 74,
            unions: [
              { unionName: "BAISHGAON", unionCode: 13 },
              { unionName: "BIPULASAR", unionCode: 17 },
              { unionName: "HASNABAD", unionCode: 31 },
              { unionName: "JHALAM (UTTAR)", unionCode: 42 },
              { unionName: "JHALAM (DAKSHIN)", unionCode: 45 },
              { unionName: "KHILA", unionCode: 53 },
              { unionName: "LAKSHMANPUR", unionCode: 59 },
              { unionName: "MAISATUA", unionCode: 63 },
              { unionName: "NATHER PETUA", unionCode: 70 },
              { unionName: "SARASHPUR", unionCode: 82 },
              { unionName: "UTTAR HAWLA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MEGHNA",
            subdistrictCode: 75,
            unions: [
              { unionName: "BAORKHOLA", unionCode: 11 },
              { unionName: "BARAKANDA", unionCode: 12 },
              { unionName: "CHANDANPUR", unionCode: 19 },
              { unionName: "CHALIBHANGA", unionCode: 21 },
              { unionName: "GOBINDAPUR", unionCode: 43 },
              { unionName: "LUTER CHAR", unionCode: 53 },
              { unionName: "MANIKER CHAR", unionCode: 71 },
              { unionName: "RADHANAGAR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "MURADNAGAR",
            subdistrictCode: 81,
            unions: [
              { unionName: "AKUBPUR", unionCode: 10 },
              { unionName: "ANDIKOT", unionCode: 11 },
              { unionName: "PURBA BANGARA", unionCode: 13 },
              { unionName: "PASCHIM BANGARA", unionCode: 18 },
              { unionName: "CHAPITALA", unionCode: 22 },
              { unionName: "DARORA", unionCode: 27 },
              { unionName: "CHHALIAKANDI", unionCode: 31 },
              { unionName: "DHAMGHAR", unionCode: 36 },
              { unionName: "JAHAPUR", unionCode: 40 },
              { unionName: "JATRAPUR", unionCode: 45 },
              { unionName: "KAMALLA", unionCode: 49 },
              { unionName: "MURADNAGAR", unionCode: 54 },
              { unionName: "PURBA NABIPUR", unionCode: 58 },
              { unionName: "PASCHIM NABIPUR", unionCode: 63 },
              { unionName: "PAHARPUR", unionCode: 67 },
              { unionName: "BABUTI PARA", unionCode: 72 },
              { unionName: "PURBA PURBADHAIR", unionCode: 76 },
              { unionName: "PASCHIM PURBADHAIR", unionCode: 81 },
              { unionName: "UTTAR RAMCHANDRAPUR", unionCode: 85 },
              { unionName: "DAKSHIN RAMCHANDRAPUR", unionCode: 90 },
              { unionName: "TANKI", unionCode: 91 },
              { unionName: "SREEKAIL", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NANGALKOT",
            subdistrictCode: 87,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ADRA", unionCode: 11 },
              { unionName: "BANGODDA", unionCode: 17 },
              { unionName: "BAKSHAGANJ", unionCode: 34 },
              { unionName: "DHALUA", unionCode: 43 },
              { unionName: "DAULKHAR", unionCode: 51 },
              { unionName: "HESAKHAL", unionCode: 57 },
              { unionName: "JODDA", unionCode: 60 },
              { unionName: "MOKARA", unionCode: 69 },
              { unionName: "MOKRABPUR", unionCode: 73 },
              { unionName: "PERIA", unionCode: 80 },
              { unionName: "ROYKOT", unionCode: 86 },
              { unionName: "SATBARIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "TITAS",
            subdistrictCode: 94,
            unions: [
              { unionName: "BALARAMPUR", unionCode: 10 },
              { unionName: "BITIKANDI", unionCode: 17 },
              { unionName: "JAGATPUR", unionCode: 35 },
              { unionName: "JIARKANDI", unionCode: 45 },
              { unionName: "KALAKANDI", unionCode: 62 },
              { unionName: "KARIKANDI", unionCode: 67 },
              { unionName: "MAJIDPUR", unionCode: 69 },
              { unionName: "NARAYANDIA", unionCode: 77 },
              { unionName: "SATANI", unionCode: 85 }
            ]
          }
        ]
      },
      {
        districtName: "COX'S BAZAR",
        districtCode: 22,
        subdistricts: [
          {
            subdistrictName: "CHAKARIA",
            subdistrictCode: 16,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BADARKHALI", unionCode: 10 },
              { unionName: "BAMO BILCHARI", unionCode: 12 },
              { unionName: "BARAITALI", unionCode: 16 },
              { unionName: "BHEOLA MANIK CHAR", unionCode: 22 },
              { unionName: "CHIRINGA", unionCode: 27 },
              { unionName: "DEMUSIA", unionCode: 31 },
              { unionName: "DULAHAZARA", unionCode: 33 },
              { unionName: "PURBA BARABHEOLA", unionCode: 39 },
              { unionName: "FASIAKHALI", unionCode: 44 },
              { unionName: "HARBANG", unionCode: 50 },
              { unionName: "KAKARA", unionCode: 55 },
              { unionName: "KAIARBIL", unionCode: 61 },
              { unionName: "KHUNTAKHALI", unionCode: 67 },
              { unionName: "KONAKHALI", unionCode: 69 },
              { unionName: "LAKHYARCHAR", unionCode: 72 },
              { unionName: "SAHARBIL", unionCode: 80 },
              { unionName: "SURAJPUR MANIKPUR", unionCode: 87 },
              { unionName: "PASCHIM BARA BHEOLA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "COX'S BAZAR SADAR",
            subdistrictCode: 24,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "BHARUAKHALI", unionCode: 20 },
              { unionName: "CHAUFALDANDI", unionCode: 22 },
              { unionName: "IDGAON", unionCode: 35 },
              { unionName: "ISLAMPUR", unionCode: 38 },
              { unionName: "ISLAMABAD", unionCode: 42 },
              { unionName: "JALALABAD", unionCode: 45 },
              { unionName: "JHILWANJA", unionCode: 47 },
              { unionName: "KHURUSHKUL", unionCode: 59 },
              { unionName: "PATALI MACHHUAKHALI", unionCode: 71 },
              { unionName: "POKKHALI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "KUTUBDIA",
            subdistrictCode: 45,
            unions: [
              { unionName: "ALI AKBAR DEIL", unionCode: 13 },
              { unionName: "BARAGHOP", unionCode: 27 },
              { unionName: "DAKSHIN DHURUNG", unionCode: 40 },
              { unionName: "KAIYARBIL", unionCode: 54 },
              { unionName: "LEMSIKHALI", unionCode: 67 },
              { unionName: "UTTAR DHURUNG", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "MAHESHKHALI",
            subdistrictCode: 49,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA MAHESKHALI", unionCode: 11 },
              { unionName: "CHHOTAMOHES KHALI", unionCode: 18 },
              { unionName: "DHALGHATA", unionCode: 23 },
              { unionName: "HOANAK", unionCode: 47 },
              { unionName: "KALARMARCHHARA", unionCode: 59 },
              { unionName: "KUTUBJOM", unionCode: 62 },
              { unionName: "MATARBARI", unionCode: 71 },
              { unionName: "SAFLAPUR", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "PEKUA",
            subdistrictCode: 56,
            unions: [
              { unionName: "BARA BAKIA", unionCode: 11 },
              { unionName: "UJANTIA", unionCode: 55 },
              { unionName: "MAGNAMA", unionCode: 78 },
              { unionName: "PEKUA", unionCode: 83 },
              { unionName: "RAJAKHALI", unionCode: 89 },
              { unionName: "SHILKHALI", unionCode: 93 },
              { unionName: "TAITONG", unionCode: 96 }
            ]
          },
          {
            subdistrictName: "RAMU",
            subdistrictCode: 66,
            unions: [
              { unionName: "CHAKMARKUL", unionCode: 13 },
              { unionName: "FATEKHARKUL", unionCode: 15 },
              { unionName: "GARJANIA", unionCode: 19 },
              { unionName: "IDGAR", unionCode: 28 },
              { unionName: "JOARIANALA", unionCode: 38 },
              { unionName: "KACHHAPIA", unionCode: 47 },
              { unionName: "KHUNIAPALONG", unionCode: 57 },
              { unionName: "KAUARKHOP", unionCode: 66 },
              { unionName: "RASHID NAGAR", unionCode: 70 },
              { unionName: "RAJARKUL", unionCode: 76 },
              { unionName: "DAKSHIN MITHACHHARI", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "TEKNAF",
            subdistrictCode: 90,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAHARCHHARA", unionCode: 15 },
              { unionName: "NHILLA", unionCode: 31 },
              { unionName: "ST.MARTIN DWIP", unionCode: 39 },
              { unionName: "SABRANG", unionCode: 47 },
              { unionName: "TEKNAF", unionCode: 63 },
              { unionName: "WHYKONG", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "UKHIA",
            subdistrictCode: 94,
            unions: [
              { unionName: "HALDIA PALONG", unionCode: 15 },
              { unionName: "JALIA PALONG", unionCode: 31 },
              { unionName: "RAJA PALONG", unionCode: 47 },
              { unionName: "RATNA PALONG", unionCode: 63 },
              { unionName: "PALONG KHALI", unionCode: 79 }
            ]
          }
        ]
      },
      {
        districtName: "FENI",
        districtCode: 30,
        subdistricts: [
          {
            subdistrictName: "CHHAGALNAIYA",
            subdistrictCode: 14,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "GOPAL", unionCode: 47 },
              { unionName: "MOHAMAYA", unionCode: 57 },
              { unionName: "PATHANNAGAR", unionCode: 76 },
              { unionName: "RADHANAGAR", unionCode: 85 },
              { unionName: "SUBHAPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "DAGANBHUIYAN",
            subdistrictCode: 25,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DAGANBHUIYAN", unionCode: 12 },
              { unionName: "JAILASHKARA", unionCode: 38 },
              { unionName: "MATHU BHUIYAN", unionCode: 56 },
              { unionName: "PURBA CHANDRAPUR", unionCode: 69 },
              { unionName: "RAJAPUR", unionCode: 73 },
              { unionName: "RAMNAGAR", unionCode: 77 },
              { unionName: "SINDURPUR", unionCode: 90 },
              { unionName: "YAKUBPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "FENI SADAR",
            subdistrictCode: 29,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18", unionCode: 18 },
              { unionName: "BALIGAON", unionCode: 20 },
              { unionName: "DHALIA", unionCode: 22 },
              { unionName: "DHARMAPUR", unionCode: 23 },
              { unionName: "FARHADNAGAR", unionCode: 25 },
              { unionName: "FAZILPUR", unionCode: 30 },
              { unionName: "KALIDAH", unionCode: 43 },
              { unionName: "KAZIRBAG", unionCode: 47 },
              { unionName: "LEMUA", unionCode: 51 },
              { unionName: "MATABI", unionCode: 60 },
              { unionName: "PANCHGACHHIYA", unionCode: 64 },
              { unionName: "SANUA", unionCode: 82 },
              { unionName: "SHARSHADI", unionCode: 86 }
            ]
          },
          {
            subdistrictName: "FULGAZI",
            subdistrictCode: 41,
            unions: [
              { unionName: "AMJADHAT", unionCode: 13 },
              { unionName: "ANANDAPUR", unionCode: 27 },
              { unionName: "DARBARPUR", unionCode: 54 },
              { unionName: "FULGAZI", unionCode: 67 },
              { unionName: "G.M.HAT", unionCode: 74 },
              { unionName: "MUNSHIRHAT", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "PARSHURAM",
            subdistrictCode: 51,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHITHALIA", unionCode: 23 },
              { unionName: "BAKSH MOHAMMAD", unionCode: 40 },
              { unionName: "MIRZANAGAR", unionCode: 47 }
            ]
          },
          {
            subdistrictName: "SONAGAZI",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMIRABAD", unionCode: 15 },
              { unionName: "BAGADANA", unionCode: 19 },
              { unionName: "CHAR CHANDIA", unionCode: 28 },
              { unionName: "CHAR DARBESH", unionCode: 38 },
              { unionName: "CHAR MAJLISHPUR", unionCode: 47 },
              { unionName: "MANGALKANDI", unionCode: 57 },
              { unionName: "MATIGANJ", unionCode: 66 },
              { unionName: "NAWABPUR", unionCode: 76 },
              { unionName: "SONAGAZI", unionCode: 85 }
            ]
          }
        ]
      },
      {
        districtName: "KHAGRACHHARI",
        districtCode: 46,
        subdistricts: [
          {
            subdistrictName: "DIGHINALA",
            subdistrictCode: 43,
            unions: [
              { unionName: "BABUCHHARA", unionCode: 15 },
              { unionName: "BOALKHALI", unionCode: 31 },
              { unionName: "DIGHINALA", unionCode: 47 },
              { unionName: "KABAKHALI", unionCode: 63 },
              { unionName: "MERUNG", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "GUIMARA",
            subdistrictCode: 47,
            unions: [
              { unionName: "GUIMARA", unionCode: 35 },
              { unionName: "HAPCHHARI", unionCode: 38 },
              { unionName: "SINDUKCHHARI", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "KHAGRACHHARI SADAR",
            subdistrictCode: 49,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHAIBONCHHARA", unionCode: 15 },
              { unionName: "GOLABARI", unionCode: 25 },
              { unionName: "KAMALCHHARI", unionCode: 47 },
              { unionName: "KHAGRACHHARI", unionCode: 63 },
              { unionName: "PERACHHARA", unionCode: 75 }
            ]
          },
          {
            subdistrictName: "LAKSHMICHHARI",
            subdistrictCode: 61,
            unions: [
              { unionName: "BARMACHHARI", unionCode: 23 },
              { unionName: "DULYATALI", unionCode: 47 },
              { unionName: "LAKSHMICHHARI", unionCode: 71 }
            ]
          },
          {
            subdistrictName: "MAHALCHHARI",
            subdistrictCode: 65,
            unions: [
              { unionName: "KAYANGGHAT", unionCode: 15 },
              { unionName: "MAHALCHHARI", unionCode: 31 },
              { unionName: "MASCHHARI", unionCode: 47 },
              { unionName: "MUBACHHARI", unionCode: 63 }
            ]
          },
          {
            subdistrictName: "MANIKCHHARI",
            subdistrictCode: 67,
            unions: [
              { unionName: "BATNATALI", unionCode: 19 },
              { unionName: "JUGGACHHALA", unionCode: 42 },
              { unionName: "MANIKCHHARI", unionCode: 63 },
              { unionName: "TINTAHARI", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "MATIRANGA",
            subdistrictCode: 70,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMTALI", unionCode: 13 },
              { unionName: "BARANAL", unionCode: 17 },
              { unionName: "BELCHHARI", unionCode: 23 },
              { unionName: "GUMTI", unionCode: 47 },
              { unionName: "MATIRANGA", unionCode: 59 },
              { unionName: "TUBALCHHARI", unionCode: 76 },
              { unionName: "TAINDANG", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "PANCHHARI",
            subdistrictCode: 77,
            unions: [
              { unionName: "CHENGI", unionCode: 19 },
              { unionName: "LATIBAN", unionCode: 38 },
              { unionName: "LOGANG", unionCode: 57 },
              { unionName: "PANCHHARI", unionCode: 76 },
              { unionName: "ULTACHHARI", unionCode: 89 }
            ]
          },
          {
            subdistrictName: "RAMGARH",
            subdistrictCode: 80,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "PATHACHHARA", unionCode: 57 },
              { unionName: "RAMGARH", unionCode: 76 }
            ]
          }
        ]
      },
      {
        districtName: "LAKSHMIPUR",
        districtCode: 51,
        subdistricts: [
          {
            subdistrictName: "KAMALNAGAR",
            subdistrictCode: 33,
            unions: [
              { unionName: "CHAR FALCON", unionCode: 47 },
              { unionName: "CHAR KADIRA", unionCode: 63 },
              { unionName: "CHAR KALKINI", unionCode: 71 },
              { unionName: "CHAR LAWRENCE", unionCode: 79 },
              { unionName: "CHAR MARTIN", unionCode: 81 },
              { unionName: "HAJIRHAT", unionCode: 83 },
              { unionName: "PATARIR HAT", unionCode: 87 },
              { unionName: "TORABGANG", unionCode: 91 },
              { unionName: "SAHEBERHAT", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "LAKSHMIPUR SADAR",
            subdistrictCode: 43,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 16 },
              { unionName: "WARD NO-14", unionCode: 17 },
              { unionName: "WARD NO-15", unionCode: 18 },
              { unionName: "BANGAKHA", unionCode: 13 },
              { unionName: "BASIKPUR", unionCode: 14 },
              { unionName: "BHABANIGANJ", unionCode: 15 },
              { unionName: "CHANDRAGANJ", unionCode: 20 },
              { unionName: "CHARRAMANI MOHAN", unionCode: 23 },
              { unionName: "CHARSAI", unionCode: 25 },
              { unionName: "CHAR RUHITA", unionCode: 30 },
              { unionName: "DALAL BAZAR", unionCode: 35 },
              { unionName: "DATTA PARA", unionCode: 40 },
              { unionName: "DIGHALI", unionCode: 45 },
              { unionName: "HAJIR PARA", unionCode: 50 },
              { unionName: "KUSHAKHALI", unionCode: 55 },
              { unionName: "LAHARKANDI", unionCode: 60 },
              { unionName: "MANDARI", unionCode: 70 },
              { unionName: "UTTAR HAMCHADI", unionCode: 75 },
              { unionName: "PARBATINAGAR", unionCode: 80 },
              { unionName: "SHAK CHAR", unionCode: 85 },
              { unionName: "TIARIGANJ", unionCode: 87 },
              { unionName: "TUM CHAR", unionCode: 89 },
              { unionName: "DAKSHIN HAMCHADI", unionCode: 90 },
              { unionName: "UTTAR JOYPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "ROYPUR",
            subdistrictCode: 58,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAMNI", unionCode: 11 },
              { unionName: "NORTH CHAR ABABIL", unionCode: 23 },
              { unionName: "SOUTH CHAR ABABIL", unionCode: 28 },
              { unionName: "NORTH CHAR BANGSHI", unionCode: 35 },
              { unionName: "SOUTH CHAR BANGSHI", unionCode: 38 },
              { unionName: "CHAR MOHANA", unionCode: 47 },
              { unionName: "CHAR PATA", unionCode: 52 },
              { unionName: "KEROA", unionCode: 59 },
              { unionName: "ROYPUR", unionCode: 71 },
              { unionName: "SONAPUR", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "RAMGANJ",
            subdistrictCode: 65,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHADUR", unionCode: 13 },
              { unionName: "BHATRA", unionCode: 14 },
              { unionName: "BHOLAKOT", unionCode: 19 },
              { unionName: "CHANDIPUR", unionCode: 23 },
              { unionName: "DARBESHPUR", unionCode: 33 },
              { unionName: "ICHHAPUR", unionCode: 38 },
              { unionName: "KANCHANPUR", unionCode: 42 },
              { unionName: "KARPARA", unionCode: 47 },
              { unionName: "LAMCHAR", unionCode: 57 },
              { unionName: "NOAGAON", unionCode: 66 }
            ]
          },
          {
            subdistrictName: "RAMGATI",
            subdistrictCode: 73,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA KHERI", unionCode: 13 },
              { unionName: "CHAR ABDULLAH", unionCode: 15 },
              { unionName: "CHAR ALEXANDAR", unionCode: 23 },
              { unionName: "CHAR ALGI", unionCode: 31 },
              { unionName: "CHAR BADAM", unionCode: 39 },
              { unionName: "CHAR GAZI", unionCode: 55 },
              { unionName: "CHAR PORAGACHHA", unionCode: 65 },
              { unionName: "CHAR RAMIZ", unionCode: 87 }
            ]
          }
        ]
      },
      {
        districtName: "NOAKHALI",
        districtCode: 75,
        subdistricts: [
          {
            subdistrictName: "BEGUMGANJ",
            subdistrictCode: 7,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALYERAPUR", unionCode: 10 },
              { unionName: "AMANULLAPUR", unionCode: 12 },
              { unionName: "BEGUMGANJ", unionCode: 24 },
              { unionName: "CHHAYANI", unionCode: 28 },
              { unionName: "DURGAPUR", unionCode: 38 },
              { unionName: "EKLASHPUR", unionCode: 42 },
              { unionName: "GOPALPUR", unionCode: 45 },
              { unionName: "NAROTTAMPUR", unionCode: 49 },
              { unionName: "HAJIPUR", unionCode: 52 },
              { unionName: "JIRTALI", unionCode: 56 },
              { unionName: "KADIRPUR", unionCode: 63 },
              { unionName: "KUTUBPUR", unionCode: 66 },
              { unionName: "MIR WARISHPUR", unionCode: 70 },
              { unionName: "RAJGANJ", unionCode: 80 },
              { unionName: "RASULPUR", unionCode: 84 },
              { unionName: "SHARIFPUR", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "CHATKHIL",
            subdistrictCode: 10,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BADALKUT", unionCode: 17 },
              { unionName: "HATPUKURIA GHATLABAG", unionCode: 19 },
              { unionName: "KHIL PARA", unionCode: 28 },
              { unionName: "MOHAMMADPUR", unionCode: 38 },
              { unionName: "NAYAKHOLA", unionCode: 47 },
              { unionName: "PANCHGAON", unionCode: 57 },
              { unionName: "PARKOTE", unionCode: 66 },
              { unionName: "RAMNARAYANPUR", unionCode: 76 },
              { unionName: "SAHAPUR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "COMPANIGANJ",
            subdistrictCode: 21,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHAR ELAHI", unionCode: 10 },
              { unionName: "CHAR FAKIRA", unionCode: 11 },
              { unionName: "CHAR HAZARI", unionCode: 23 },
              { unionName: "CHAR KAKRA", unionCode: 35 },
              { unionName: "CHAR PARBATI", unionCode: 47 },
              { unionName: "MUSAPUR", unionCode: 59 },
              { unionName: "RAMPUR", unionCode: 71 },
              { unionName: "SIRAJPUR", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "HATIYA",
            subdistrictCode: 36,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BURIR CHAR", unionCode: 15 },
              { unionName: "CHANDNANDI", unionCode: 19 },
              { unionName: "CHAR ISHWAR", unionCode: 28 },
              { unionName: "CHAR KING", unionCode: 38 },
              { unionName: "HARNI", unionCode: 47 },
              { unionName: "JAHAJMARA", unionCode: 57 },
              { unionName: "NALCHIRA", unionCode: 66 },
              { unionName: "NIJUM DIP", unionCode: 69 },
              { unionName: "SONADIA", unionCode: 76 },
              { unionName: "SUKH CHAR", unionCode: 85 },
              { unionName: "TAMARUDDIN", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "KABIRHAT",
            subdistrictCode: 47,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BATAIYA", unionCode: 23 },
              { unionName: "CHAP RASHIR HAT", unionCode: 27 },
              { unionName: "DHAN SHALIK", unionCode: 35 },
              { unionName: "DHAN SIRI", unionCode: 45 },
              { unionName: "GHOSHBAGH", unionCode: 55 },
              { unionName: "NAROTTAMPUR", unionCode: 70 },
              { unionName: "SUNDALPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "SENBAGH",
            subdistrictCode: 80,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ARJUNTALA", unionCode: 13 },
              { unionName: "BEJOYBAGH", unionCode: 19 },
              { unionName: "CHHATARPAIA", unionCode: 28 },
              { unionName: "DUMURIA", unionCode: 38 },
              { unionName: "KABILPUR", unionCode: 47 },
              { unionName: "KADRA", unionCode: 57 },
              { unionName: "KESHARPAR", unionCode: 66 },
              { unionName: "MOHAMADPUR", unionCode: 76 },
              { unionName: "NABIPUR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "SONAIMURI",
            subdistrictCode: 83,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMBARNAGAR", unionCode: 13 },
              { unionName: "AMISHA PARA", unionCode: 15 },
              { unionName: "BARAGAON", unionCode: 17 },
              { unionName: "BAZRA", unionCode: 21 },
              { unionName: "CHASHIRHAT", unionCode: 31 },
              { unionName: "DEOTI", unionCode: 35 },
              { unionName: "JAYAG", unionCode: 59 },
              { unionName: "NADANA", unionCode: 73 },
              { unionName: "NATESHWAR", unionCode: 77 },
              { unionName: "SONAPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SUBARNACHAR",
            subdistrictCode: 85,
            unions: [
              { unionName: "CHAR AMANULLAH", unionCode: 29 },
              { unionName: "CHAR BATA", unionCode: 31 },
              { unionName: "CHAR CLERK", unionCode: 33 },
              { unionName: "CHAR JABBAR", unionCode: 35 },
              { unionName: "CHAR JUBILLE", unionCode: 36 },
              { unionName: "CHAR WAPDA", unionCode: 37 },
              { unionName: "MOHAMMADPUR", unionCode: 43 },
              { unionName: "PURBA CHAR BATA", unionCode: 47 }
            ]
          },
          {
            subdistrictName: "NOAKHALI SADAR",
            subdistrictCode: 87,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ANDERCHAR", unionCode: 21 },
              { unionName: "ASHWADIA", unionCode: 23 },
              { unionName: "BINODPUR", unionCode: 25 },
              { unionName: "CHAR MATUA", unionCode: 40 },
              { unionName: "DADPUR", unionCode: 45 },
              { unionName: "DHARMAPUR", unionCode: 47 },
              { unionName: "EWAZBALIA", unionCode: 50 },
              { unionName: "KADIR HANIF", unionCode: 60 },
              { unionName: "KALADARAF", unionCode: 65 },
              { unionName: "NIAZPUR", unionCode: 75 },
              { unionName: "NOAKHALI", unionCode: 80 },
              { unionName: "NOANNAI", unionCode: 85 },
              { unionName: "PURBA CHAR MATUA", unionCode: 90 }
            ]
          }
        ]
      },
      {
        districtName: "RANGAMATI",
        districtCode: 84,
        subdistricts: [
          {
            subdistrictName: "BAGHAICHHARI",
            subdistrictCode: 7,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMTALI", unionCode: 10 },
              { unionName: "BAGHAI CHHARI", unionCode: 11 },
              { unionName: "BANGALTALI", unionCode: 23 },
              { unionName: "SARBOATALI", unionCode: 35 },
              { unionName: "KEDARMARA", unionCode: 47 },
              { unionName: "MARISHYA", unionCode: 59 },
              { unionName: "RUPAKARI", unionCode: 71 },
              { unionName: "SAJEK", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "BARKAL UPAZILA",
            subdistrictCode: 21,
            unions: [
              { unionName: "AIMA CHHARA", unionCode: 15 },
              { unionName: "BARA HARINA", unionCode: 31 },
              { unionName: "BARKAL", unionCode: 47 },
              { unionName: "BHUSHAN CHHARA", unionCode: 63 },
              { unionName: "SHUBLONG", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "KAWKHALI (BETBUNIA)",
            subdistrictCode: 25,
            unions: [
              { unionName: "BETBUNIA", unionCode: 19 },
              { unionName: "FATIK CHHARI", unionCode: 38 },
              { unionName: "GHAGRA", unionCode: 57 },
              { unionName: "KALAMPATI", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "BELAI CHHARI",
            subdistrictCode: 29,
            unions: [
              { unionName: "BELAI CHHARI", unionCode: 23 },
              { unionName: "BARATHALI", unionCode: 30 },
              { unionName: "FARUA", unionCode: 47 },
              { unionName: "KANGARA CHHARI", unionCode: 71 }
            ]
          },
          {
            subdistrictName: "KAPTAI",
            subdistrictCode: 36,
            unions: [
              { unionName: "CHANDRAGHONA", unionCode: 19 },
              { unionName: "CHITMARAM", unionCode: 38 },
              { unionName: "KAPTAI", unionCode: 57 },
              { unionName: "RAIKHALI", unionCode: 76 },
              { unionName: "WAGGA", unionCode: 86 }
            ]
          },
          {
            subdistrictName: "JURAI CHHARI UPAZIL",
            subdistrictCode: 47,
            unions: [
              { unionName: "BANJUGI CHHARA", unionCode: 19 },
              { unionName: "DUMDUMYA", unionCode: 38 },
              { unionName: "JURAI CHHARI", unionCode: 57 },
              { unionName: "MAIDANG", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "LANGADU",
            subdistrictCode: 58,
            unions: [
              { unionName: "ATARAK CHARA", unionCode: 13 },
              { unionName: "BHASANYA ADAM", unionCode: 27 },
              { unionName: "BAGACHATAR", unionCode: 40 },
              { unionName: "GULSHAKHALI", unionCode: 54 },
              { unionName: "KALAPAKURJYA", unionCode: 60 },
              { unionName: "LANGADU", unionCode: 67 },
              { unionName: "MAYANIMUKH", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "NANIARCHAR",
            subdistrictCode: 75,
            unions: [
              { unionName: "BURIGHAT", unionCode: 19 },
              { unionName: "GHILA CHHARI", unionCode: 38 },
              { unionName: "NANIARCHAR", unionCode: 57 },
              { unionName: "SABEKHYONG", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "RAJASTHALI",
            subdistrictCode: 78,
            unions: [
              { unionName: "BANGALHALIA", unionCode: 23 },
              { unionName: "GHILA CHHARI", unionCode: 46 },
              { unionName: "GAINDA", unionCode: 71 }
            ]
          },
          {
            subdistrictName: "RANGAMATI SADAR",
            subdistrictCode: 87,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BANDUK BHANGA", unionCode: 13 },
              { unionName: "BALUKHALI", unionCode: 27 },
              { unionName: "JIBTALI", unionCode: 40 },
              { unionName: "KUTUK CHHARI", unionCode: 54 },
              { unionName: "MAGBAN", unionCode: 67 },
              { unionName: "SAPCHHARI", unionCode: 81 }
            ]
          }
        ]
      }
    ]
  },
  {
    divisionName: "RANGPUR",
    divisionCode: 55,
    districts: [
      {
        districtName: "DINAJPUR",
        districtCode: 27,
        subdistricts: [
          {
            subdistrictName: "BIRAMPUR",
            subdistrictCode: 10,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BENAIL", unionCode: 11 },
              { unionName: "MUKUNDAPUR", unionCode: 23 },
              { unionName: "DEOR", unionCode: 35 },
              { unionName: "JOTBANI", unionCode: 47 },
              { unionName: "KATLA", unionCode: 59 },
              { unionName: "KHANPUR", unionCode: 71 },
              { unionName: "PALI PRAYAGPUR", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "BIRGANJ",
            subdistrictCode: 12,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHOGNAGAR", unionCode: 13 },
              { unionName: "MARICHA", unionCode: 17 },
              { unionName: "MOHAMMADPUR", unionCode: 25 },
              { unionName: "MOHANPUR", unionCode: 34 },
              { unionName: "NIJPARA", unionCode: 43 },
              { unionName: "PALASHBARI", unionCode: 51 },
              { unionName: "PALTAPUR", unionCode: 60 },
              { unionName: "SHATAGRAM", unionCode: 69 },
              { unionName: "SATAIR", unionCode: 77 },
              { unionName: "SHIBRAMPUR", unionCode: 86 },
              { unionName: "SUJALPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BIRAL",
            subdistrictCode: 17,
            unions: [
              { unionName: "AZIMPUR", unionCode: 13 },
              { unionName: "BHANDARA", unionCode: 19 },
              { unionName: "BIRAL", unionCode: 28 },
              { unionName: "BIJORA", unionCode: 38 },
              { unionName: "DHAMAIR", unionCode: 47 },
              { unionName: "DHARMAPUR", unionCode: 57 },
              { unionName: "FARAKKABAD", unionCode: 66 },
              { unionName: "MANGALPUR", unionCode: 76 },
              { unionName: "PALASHBARI", unionCode: 80 },
              { unionName: "RANI PUKUR", unionCode: 85 },
              { unionName: "SAHARGRAM", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "BOCHAGANJ",
            subdistrictCode: 21,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ATGAON", unionCode: 13 },
              { unionName: "CHHATAIL", unionCode: 27 },
              { unionName: "ISHANIA", unionCode: 40 },
              { unionName: "MUSHIDHAT", unionCode: 54 },
              { unionName: "NAFANAGAR", unionCode: 67 },
              { unionName: "RANGAON", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "CHIRIRBANDAR",
            subdistrictCode: 30,
            unions: [
              { unionName: "ABDULPUR", unionCode: 13 },
              { unionName: "ALOKDIHI", unionCode: 15 },
              { unionName: "AMARPUR", unionCode: 23 },
              { unionName: "AULIA PUKUR", unionCode: 31 },
              { unionName: "BHIAIL", unionCode: 39 },
              { unionName: "FATEHJANAPUR", unionCode: 47 },
              { unionName: "ISABPUR", unionCode: 55 },
              { unionName: "NASRATPUR", unionCode: 63 },
              { unionName: "PUNATTI", unionCode: 71 },
              { unionName: "SAINTARA", unionCode: 79 },
              { unionName: "SATNALA", unionCode: 87 },
              { unionName: "TENTULIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "FULBARI",
            subdistrictCode: 38,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALADIPUR", unionCode: 25 },
              { unionName: "BETDIGHI", unionCode: 28 },
              { unionName: "DAULATPUR", unionCode: 38 },
              { unionName: "ELUARY", unionCode: 47 },
              { unionName: "KAZIHAL", unionCode: 66 },
              { unionName: "KHAYERBARI", unionCode: 76 },
              { unionName: "SHIBNAGAR", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "GHORAGHAT",
            subdistrictCode: 43,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "ARD NO-09", unionCode: 9 },
              { unionName: "BULAKIPUR", unionCode: 19 },
              { unionName: "GHORAGHAT", unionCode: 38 },
              { unionName: "PALSA", unionCode: 57 },
              { unionName: "SINGRA", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "HAKIMPUR",
            subdistrictCode: 47,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALIHAT", unionCode: 13 },
              { unionName: "BOALDAR", unionCode: 40 },
              { unionName: "KHATTA MADHAB PARA", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "KAHAROLE",
            subdistrictCode: 56,
            unions: [
              { unionName: "DABAR", unionCode: 15 },
              { unionName: "MUKUNDAPUR", unionCode: 31 },
              { unionName: "RAMCHANDRAPUR", unionCode: 47 },
              { unionName: "RASULPUR", unionCode: 63 },
              { unionName: "SUNDARPUR", unionCode: 79 },
              { unionName: "TARGAON", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KHANSAMA",
            subdistrictCode: 60,
            unions: [
              { unionName: "ALOKJHARI", unionCode: 15 },
              { unionName: "ANGAR PARA", unionCode: 31 },
              { unionName: "BHABKI", unionCode: 47 },
              { unionName: "BHERBHERI", unionCode: 63 },
              { unionName: "GOALDIHI", unionCode: 79 },
              { unionName: "KHAMAR PARA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DINAJPUR SADAR",
            subdistrictCode: 64,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "ASKARPUR", unionCode: 16 },
              { unionName: "AULIAPUR", unionCode: 17 },
              { unionName: "CHEHELGAZI", unionCode: 25 },
              { unionName: "FAZILPUR", unionCode: 34 },
              { unionName: "KAMALPUR", unionCode: 43 },
              { unionName: "SHANKARPUR", unionCode: 60 },
              { unionName: "SHASHARA", unionCode: 69 },
              { unionName: "SEKHPURA", unionCode: 77 },
              { unionName: "SUNDARBAN", unionCode: 86 },
              { unionName: "UTHRAIL", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NAWABGANJ",
            subdistrictCode: 69,
            unions: [
              { unionName: "BHADURIA", unionCode: 13 },
              { unionName: "BINODNAGAR", unionCode: 17 },
              { unionName: "DAUDPUR", unionCode: 25 },
              { unionName: "GOLAPGANJ", unionCode: 43 },
              { unionName: "JOYPUR", unionCode: 51 },
              { unionName: "KUSHDAHA", unionCode: 69 },
              { unionName: "MAHMUDPUR", unionCode: 77 },
              { unionName: "PUTIMARA", unionCode: 86 },
              { unionName: "SHALKHURIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "PARBATIPUR",
            subdistrictCode: 77,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BELAICHANDI", unionCode: 16 },
              { unionName: "CHANDIPUR", unionCode: 17 },
              { unionName: "HABRA", unionCode: 25 },
              { unionName: "HAMIDPUR", unionCode: 34 },
              { unionName: "HARIRAMPUR", unionCode: 43 },
              { unionName: "MANMATHAPUR", unionCode: 51 },
              { unionName: "MOMINPUR", unionCode: 60 },
              { unionName: "MOSTAFAPUR", unionCode: 69 },
              { unionName: "PALASHBARI", unionCode: 77 },
              { unionName: "RAMPUR", unionCode: 86 }
            ]
          }
        ]
      },
      {
        districtName: "GAIBANDHA",
        districtCode: 32,
        subdistricts: [
          {
            subdistrictName: "FULCHHARI",
            subdistrictCode: 21,
            unions: [
              { unionName: "ERENDABARI", unionCode: 11 },
              { unionName: "FAZLUPUR", unionCode: 23 },
              { unionName: "FULCHHARI", unionCode: 35 },
              { unionName: "GAZARIA", unionCode: 47 },
              { unionName: "KANCHI PARA", unionCode: 59 },
              { unionName: "UDAKHALI", unionCode: 71 },
              { unionName: "URIA", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "GAIBANDHA SADAR",
            subdistrictCode: 24,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BADIAKHALI", unionCode: 12 },
              { unionName: "BALLAMJHAR", unionCode: 14 },
              { unionName: "BOALI", unionCode: 21 },
              { unionName: "GHAGOA", unionCode: 36 },
              { unionName: "GIDARI", unionCode: 43 },
              { unionName: "KAMARJANI", unionCode: 51 },
              { unionName: "KHOLAHATI", unionCode: 58 },
              { unionName: "KUPTALA", unionCode: 65 },
              { unionName: "LAKSHMIPUR", unionCode: 73 },
              { unionName: "MALIBARI", unionCode: 80 },
              { unionName: "MOLLAR CHAR", unionCode: 85 },
              { unionName: "RAMCHANDRAPUR", unionCode: 87 },
              { unionName: "SAHA PARA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "GOBINDAGANJ",
            subdistrictCode: 30,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DARBASTA", unionCode: 10 },
              { unionName: "FULBARI", unionCode: 11 },
              { unionName: "GUMANIGANJ", unionCode: 16 },
              { unionName: "HARIRAMPUR", unionCode: 22 },
              { unionName: "KAMARDAHA", unionCode: 27 },
              { unionName: "KAMDIA", unionCode: 33 },
              { unionName: "KATABARI", unionCode: 39 },
              { unionName: "KOCHASAHAR", unionCode: 44 },
              { unionName: "MAHIMAGANJ", unionCode: 50 },
              { unionName: "NAKAI", unionCode: 55 },
              { unionName: "RAJAHAR", unionCode: 61 },
              { unionName: "RAKHAL BURUZ", unionCode: 67 },
              { unionName: "SHALMARA", unionCode: 72 },
              { unionName: "SAPMARA", unionCode: 78 },
              { unionName: "SHAKHAHAR", unionCode: 83 },
              { unionName: "SHIBPUR", unionCode: 89 },
              { unionName: "TALUK KANUPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "PALASHBARI",
            subdistrictCode: 67,
            unions: [
              { unionName: "PALASHBARI", unionCode: 85 },
              { unionName: "BARISAL", unionCode: 13 },
              { unionName: "BETKAPA", unionCode: 19 },
              { unionName: "HARINATHPUR", unionCode: 28 },
              { unionName: "HOSSAINPUR", unionCode: 38 },
              { unionName: "KISHOREGARI", unionCode: 47 },
              { unionName: "MOHADIPUR", unionCode: 57 },
              { unionName: "MANOHARPUR", unionCode: 66 },
              { unionName: "PABNAPUR", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "SADULLAPUR",
            subdistrictCode: 82,
            unions: [
              { unionName: "BANAGRAM", unionCode: 13 },
              { unionName: "BHATGRAM", unionCode: 17 },
              { unionName: "DAMODARPUR", unionCode: 25 },
              { unionName: "DHAPERHAT", unionCode: 34 },
              { unionName: "FARIDPUR", unionCode: 43 },
              { unionName: "IDILPUR", unionCode: 51 },
              { unionName: "JAMALPUR", unionCode: 60 },
              { unionName: "KHURDA KAMARPUR", unionCode: 69 },
              { unionName: "KAMAR PARA", unionCode: 77 },
              { unionName: "NALDANGA", unionCode: 86 },
              { unionName: "RASULPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SAGHATA",
            subdistrictCode: 88,
            unions: [
              { unionName: "BHARTKHALI", unionCode: 13 },
              { unionName: "BONAR PARA", unionCode: 19 },
              { unionName: "GHURIDAHA", unionCode: 28 },
              { unionName: "HALDIA", unionCode: 38 },
              { unionName: "JUMMERBARI", unionCode: 47 },
              { unionName: "KACHUA", unionCode: 57 },
              { unionName: "KAMALER PARA", unionCode: 66 },
              { unionName: "PADUMSAHAR", unionCode: 76 },
              { unionName: "SAGHATA", unionCode: 85 },
              { unionName: "MUKTANAGAR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "SUNDARGANJ",
            subdistrictCode: 91,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAMANDANGA", unionCode: 11 },
              { unionName: "BELKA", unionCode: 12 },
              { unionName: "CHANDIPUR", unionCode: 18 },
              { unionName: "CHHAPARHATI", unionCode: 25 },
              { unionName: "DAHABANDA", unionCode: 31 },
              { unionName: "DHOPADANGA", unionCode: 37 },
              { unionName: "HARIPUR", unionCode: 44 },
              { unionName: "KANCHIBARI", unionCode: 50 },
              { unionName: "KAPASIA", unionCode: 56 },
              { unionName: "RAMJIBAN", unionCode: 63 },
              { unionName: "SHANTIRAM", unionCode: 69 },
              { unionName: "SARBANANDA", unionCode: 75 },
              { unionName: "SONAROY", unionCode: 82 },
              { unionName: "SREEPUR", unionCode: 88 },
              { unionName: "TARAPUR", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "KURIGRAM",
        districtCode: 49,
        subdistricts: [
          {
            subdistrictName: "BHURUNGAMARI",
            subdistrictCode: 6,
            unions: [
              { unionName: "ANDHARI JHAR", unionCode: 13 },
              { unionName: "BHURUNGAMARI", unionCode: 19 },
              { unionName: "BOLDIA", unionCode: 28 },
              { unionName: "BANGASONAHAT", unionCode: 38 },
              { unionName: "CHAR BHURUNGAMARI", unionCode: 47 },
              { unionName: "JOYMANIRHAT", unionCode: 57 },
              { unionName: "PAIKER CHHARA", unionCode: 66 },
              { unionName: "PATHARDUBI", unionCode: 76 },
              { unionName: "SHILKHURI", unionCode: 85 },
              { unionName: "TILAI", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "CHAR RAJIBPUR",
            subdistrictCode: 8,
            unions: [
              { unionName: "CHAR RAJIBPUR", unionCode: 19 },
              { unionName: "KODAILKATI", unionCode: 57 },
              { unionName: "MOHANGANJ", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "CHILMARI",
            subdistrictCode: 9,
            unions: [
              { unionName: "ASHTAMIR CHAR", unionCode: 11 },
              { unionName: "CHILMARI", unionCode: 23 },
              { unionName: "NAYERHAT", unionCode: 47 },
              { unionName: "RAMNA", unionCode: 59 },
              { unionName: "RANIGANJ", unionCode: 71 },
              { unionName: "THANAHAT", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "PHULBARI",
            subdistrictCode: 18,
            unions: [
              { unionName: "BARA BHITA", unionCode: 13 },
              { unionName: "BHANAGMORE", unionCode: 27 },
              { unionName: "PHULBARI", unionCode: 40 },
              { unionName: "KASHIPUR", unionCode: 54 },
              { unionName: "NAODANGA", unionCode: 67 },
              { unionName: "SHIMULBARI", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "KURIGRAM SADAR",
            subdistrictCode: 52,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BELGACHHA", unionCode: 17 },
              { unionName: "BHOGDANGA", unionCode: 19 },
              { unionName: "GHOGADAHA", unionCode: 28 },
              { unionName: "HOLOKHANA", unionCode: 38 },
              { unionName: "JATRAPUR", unionCode: 47 },
              { unionName: "KANTHALBARI", unionCode: 57 },
              { unionName: "MOGALBACHHA", unionCode: 76 },
              { unionName: "PUNCHGACHHI", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "NAGESHWARI",
            subdistrictCode: 61,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BALLABHER KHAS", unionCode: 11 },
              { unionName: "BAMANDANGA", unionCode: 12 },
              { unionName: "BERUBARI", unionCode: 18 },
              { unionName: "BHITARBAND", unionCode: 25 },
              { unionName: "HASNABAD", unionCode: 31 },
              { unionName: "KACHAKATA", unionCode: 37 },
              { unionName: "KALIGANJ", unionCode: 44 },
              { unionName: "KEDAR", unionCode: 50 },
              { unionName: "NARAYANPUR", unionCode: 63 },
              { unionName: "NEWASHI", unionCode: 69 },
              { unionName: "NOONKHAWA", unionCode: 75 },
              { unionName: "ROYGANJ", unionCode: 82 },
              { unionName: "RAMKHANA", unionCode: 88 },
              { unionName: "SANTOSHPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "RAJARHAT",
            subdistrictCode: 77,
            unions: [
              { unionName: "BIDYANANDA", unionCode: 10 },
              { unionName: "CHAKIRPASHAR", unionCode: 21 },
              { unionName: "CHHINAI", unionCode: 31 },
              { unionName: "GHARIALDANGA", unionCode: 42 },
              { unionName: "NAZIMKHAN", unionCode: 52 },
              { unionName: "RAJARHAT", unionCode: 73 },
              { unionName: "OMAR MAJID", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "RAUMARI",
            subdistrictCode: 79,
            unions: [
              { unionName: "BANDABER", unionCode: 11 },
              { unionName: "CHAR SAULMARI", unionCode: 17 },
              { unionName: "DANTBHANGA", unionCode: 23 },
              { unionName: "JADUR CHAR", unionCode: 35 },
              { unionName: "RAUMARI", unionCode: 71 },
              { unionName: "SAULMARI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "ULIPUR",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BEGUMGANJ", unionCode: 14 },
              { unionName: "BAZRA", unionCode: 16 },
              { unionName: "BURABURI", unionCode: 22 },
              { unionName: "DALDALIA", unionCode: 33 },
              { unionName: "DHAMSERNI", unionCode: 39 },
              { unionName: "DHARANIBARI", unionCode: 44 },
              { unionName: "DURGAPUR", unionCode: 50 },
              { unionName: "GUNAIGACHH", unionCode: 55 },
              { unionName: "HATIA", unionCode: 61 },
              { unionName: "PANDUL", unionCode: 72 },
              { unionName: "SAHEBER ALGA", unionCode: 75 },
              { unionName: "TABAKPUR", unionCode: 78 },
              { unionName: "THETROY", unionCode: 83 }
            ]
          }
        ]
      },
      {
        districtName: "LALMONIRHAT",
        districtCode: 52,
        subdistricts: [
          {
            subdistrictName: "ADITMARI",
            subdistrictCode: 2,
            unions: [
              { unionName: "BHADAI", unionCode: 10 },
              { unionName: "BHELABARI", unionCode: 11 },
              { unionName: "DURGAPUR", unionCode: 41 },
              { unionName: "KAMALABARI", unionCode: 59 },
              { unionName: "MAHISHKHOCHA", unionCode: 71 },
              { unionName: "PALASHI", unionCode: 77 },
              { unionName: "SAPTIBARI", unionCode: 83 },
              { unionName: "SARPUKUR", unionCode: 89 }
            ]
          },
          {
            subdistrictName: "HATIBANDHA",
            subdistrictCode: 33,
            unions: [
              { unionName: "BARAKHATA", unionCode: 15 },
              { unionName: "BHALAGURI", unionCode: 19 },
              { unionName: "DAOABARI", unionCode: 28 },
              { unionName: "FAKIR PARA", unionCode: 31 },
              { unionName: "GODDIMARI", unionCode: 38 },
              { unionName: "GOTAMARI", unionCode: 47 },
              { unionName: "NOWDABASH", unionCode: 57 },
              { unionName: "PATIKA PARA", unionCode: 66 },
              { unionName: "SANIAJAN", unionCode: 74 },
              { unionName: "SHINGIMARI", unionCode: 76 },
              { unionName: "SINDURNA", unionCode: 85 },
              { unionName: "TANGBHANGA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "KALIGANJ",
            subdistrictCode: 39,
            unions: [
              { unionName: "BHOTEMARI", unionCode: 17 },
              { unionName: "CHALBALA", unionCode: 23 },
              { unionName: "CHANDRAPUR", unionCode: 29 },
              { unionName: "DALAGRAM", unionCode: 35 },
              { unionName: "GORAL", unionCode: 47 },
              { unionName: "KAKINA", unionCode: 53 },
              { unionName: "MADATI", unionCode: 65 },
              { unionName: "TUSHBHANDAR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "LALMONIRHAT SADAR",
            subdistrictCode: 55,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARABARI", unionCode: 20 },
              { unionName: "GOKUNDA", unionCode: 29 },
              { unionName: "HARATI", unionCode: 36 },
              { unionName: "KHUNIAGACHH", unionCode: 43 },
              { unionName: "KULAGHAT", unionCode: 51 },
              { unionName: "MOGALHAT", unionCode: 65 },
              { unionName: "MAHENDRANAGAR", unionCode: 73 },
              { unionName: "PANCHAGRAM", unionCode: 83 },
              { unionName: "RAJPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "PATGRAM",
            subdistrictCode: 70,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAURA", unionCode: 13 },
              { unionName: "BURIMARI", unionCode: 15 },
              { unionName: "DAHAGRAM", unionCode: 19 },
              { unionName: "JAGATBER", unionCode: 27 },
              { unionName: "JONGRA", unionCode: 40 },
              { unionName: "KUCHLIBARI", unionCode: 54 },
              { unionName: "PATGRAM", unionCode: 67 },
              { unionName: "SREERAMPUR", unionCode: 81 }
            ]
          }
        ]
      },
      {
        districtName: "NILPHAMARI",
        districtCode: 73,
        subdistricts: [
          {
            subdistrictName: "DIMLA",
            subdistrictCode: 12,
            unions: [
              { unionName: "BALA PARA", unionCode: 13 },
              { unionName: "DIMLA", unionCode: 19 },
              { unionName: "GAYABARI", unionCode: 28 },
              { unionName: "JHUNAGACHH CHAPANI", unionCode: 38 },
              { unionName: "KHALISA CHAPANI", unionCode: 47 },
              { unionName: "KHOGA KHARIBARI", unionCode: 57 },
              { unionName: "NAOTARA UNION", unionCode: 66 },
              { unionName: "PASCHIM CHHATNAI", unionCode: 76 },
              { unionName: "PURBA CHHATNAI", unionCode: 85 },
              { unionName: "TEPA KHARIBARI", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "DOMAR UPAZILA",
            subdistrictCode: 15,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAMUNIA UNION", unionCode: 17 },
              { unionName: "BHOGDABARI UNION", unionCode: 19 },
              { unionName: "BORAGARI UNION", unionCode: 28 },
              { unionName: "DOMAR UNION", unionCode: 38 },
              { unionName: "GOMNATI UNION", unionCode: 47 },
              { unionName: "HARINCHARA", unionCode: 57 },
              { unionName: "JORABARI", unionCode: 66 },
              { unionName: "KETKIBARI", unionCode: 76 },
              { unionName: "PANGA MATUKPUR", unionCode: 85 },
              { unionName: "SONAROY", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "JALDHAKA",
            subdistrictCode: 36,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BALAGRAM", unionCode: 13 },
              { unionName: "DAOABARI", unionCode: 14 },
              { unionName: "DHARMAPAL", unionCode: 21 },
              { unionName: "GOLMUNDA", unionCode: 29 },
              { unionName: "GOLNA", unionCode: 36 },
              { unionName: "KAIMARI", unionCode: 51 },
              { unionName: "KANTHALI", unionCode: 58 },
              { unionName: "KHUTAMARA", unionCode: 65 },
              { unionName: "MIRGANJ", unionCode: 80 },
              { unionName: "SHIMULBARI", unionCode: 87 },
              { unionName: "SAULMARI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KISHOREGANJ",
            subdistrictCode: 45,
            unions: [
              { unionName: "BAHAGILI", unionCode: 17 },
              { unionName: "BARABHITA", unionCode: 25 },
              { unionName: "CHANDKHANA", unionCode: 34 },
              { unionName: "GARAGRAM", unionCode: 43 },
              { unionName: "KISHOREGANJ", unionCode: 51 },
              { unionName: "MAGURA", unionCode: 60 },
              { unionName: "NITAI", unionCode: 69 },
              { unionName: "PUTIMARI", unionCode: 86 },
              { unionName: "RANACHANDI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NILPHAMARI SADAR",
            subdistrictCode: 64,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHAPRA SARAMJANI", unionCode: 11 },
              { unionName: "CHARAIKHOLA", unionCode: 12 },
              { unionName: "CHAORA BARGACHHA", unionCode: 18 },
              { unionName: "GORGRAM", unionCode: 25 },
              { unionName: "ITAKHOLA", unionCode: 31 },
              { unionName: "KACHUKATA", unionCode: 37 },
              { unionName: "KHOKSHABARI", unionCode: 44 },
              { unionName: "KUNDA PUKUR", unionCode: 50 },
              { unionName: "PANCH PUKUR", unionCode: 63 },
              { unionName: "PALASHBARI", unionCode: 69 },
              { unionName: "LAKSHMI CHAP", unionCode: 73 },
              { unionName: "RAMNAGAR", unionCode: 75 },
              { unionName: "SANGALSHI", unionCode: 82 },
              { unionName: "SONAROY", unionCode: 88 },
              { unionName: "TUPAMARI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SAIDPUR UPAZILA",
            subdistrictCode: 85,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "BANGALIPUR", unionCode: 23 },
              { unionName: "BOTHLAGARI", unionCode: 27 },
              { unionName: "KAMAR PUKUR", unionCode: 40 },
              { unionName: "KHATA MADHUPUR", unionCode: 54 },
              { unionName: "KUSHIRAM BELPUKUR", unionCode: 67 },
              { unionName: "CANTONMENT", unionCode: 98 }
            ]
          }
        ]
      },
      {
        districtName: "PANCHAGARH",
        districtCode: 77,
        subdistricts: [
          {
            subdistrictName: "ATWARI",
            subdistrictCode: 4,
            unions: [
              { unionName: "ALOWA KHOWA", unionCode: 13 },
              { unionName: "BALARAMPUR", unionCode: 27 },
              { unionName: "DHAMOR", unionCode: 40 },
              { unionName: "MIRZAPUR", unionCode: 54 },
              { unionName: "RADHANAGAR", unionCode: 67 },
              { unionName: "TARIA", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "BODA",
            subdistrictCode: 25,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA SHASHI", unionCode: 12 },
              { unionName: "BANAGRAM", unionCode: 14 },
              { unionName: "BODA", unionCode: 21 },
              { unionName: "CHANDANBARI", unionCode: 29 },
              { unionName: "JHALAISHALSIRI", unionCode: 51 },
              { unionName: "KAJAL DIGHI KALIGANJ", unionCode: 58 },
              { unionName: "MAIDAN DIGHI", unionCode: 73 },
              { unionName: "MAREA BAMANHAT", unionCode: 80 },
              { unionName: "PANCHPIR", unionCode: 87 },
              { unionName: "SAKOA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DEBIGANJ",
            subdistrictCode: 34,
            unions: [
              { unionName: "CHILAHATI", unionCode: 19 },
              { unionName: "DANDAPAL", unionCode: 28 },
              { unionName: "DEBIDOBA", unionCode: 38 },
              { unionName: "DEBIGANJ", unionCode: 47 },
              { unionName: "HAZRADANGA", unionCode: 53 },
              { unionName: "PAMULI", unionCode: 57 },
              { unionName: "SALDANGA", unionCode: 66 },
              { unionName: "MALLIKADAHA", unionCode: 76 },
              { unionName: "SUNDAR DIGHI", unionCode: 85 },
              { unionName: "TEPRIGANJ", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "PANCHAGARH SADAR",
            subdistrictCode: 73,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMARKHANA", unionCode: 11 },
              { unionName: "CHAKLARHAT", unionCode: 23 },
              { unionName: "HAFIZABAD", unionCode: 35 },
              { unionName: "DHAKKAMARA", unionCode: 36 },
              { unionName: "GARINABARI", unionCode: 43 },
              { unionName: "HARIBHASA", unionCode: 47 },
              { unionName: "KAMAT KAJAL DIGHI", unionCode: 59 },
              { unionName: "MAGURA", unionCode: 65 },
              { unionName: "PANCHAGARH", unionCode: 71 },
              { unionName: "SATMARA", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "TENTULIA",
            subdistrictCode: 90,
            unions: [
              { unionName: "BANGLABANDHA", unionCode: 13 },
              { unionName: "BHOJANPUR", unionCode: 27 },
              { unionName: "DEBNAGAR", unionCode: 40 },
              { unionName: "BURABURI", unionCode: 54 },
              { unionName: "SALBAHAN", unionCode: 67 },
              { unionName: "TENTULIA", unionCode: 81 },
              { unionName: "TIMAIHAT", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "RANGPUR",
        districtCode: 85,
        subdistricts: [
          {
            subdistrictName: "BADARGANJ",
            subdistrictCode: 3,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "KALU PARA", unionCode: 16 },
              { unionName: "BISHNUPUR", unionCode: 18 },
              { unionName: "DAMODARPUR", unionCode: 25 },
              { unionName: "GOPALPUR", unionCode: 31 },
              { unionName: "GOPINATHPUR", unionCode: 37 },
              { unionName: "KUTUBPUR", unionCode: 63 },
              { unionName: "LOHANI PARA", unionCode: 69 },
              { unionName: "MADHUPUR", unionCode: 75 },
              { unionName: "RADHANAGAR", unionCode: 82 },
              { unionName: "RAMNATHPUR", unionCode: 88 }
            ]
          },
          {
            subdistrictName: "GANGACHARA",
            subdistrictCode: 27,
            unions: [
              { unionName: "ALAM BIDITAR", unionCode: 16 },
              { unionName: "BARABIL", unionCode: 19 },
              { unionName: "BETGARI", unionCode: 21 },
              { unionName: "GANGACHARA", unionCode: 31 },
              { unionName: "GAJAGHANTA", unionCode: 42 },
              { unionName: "KOLKANDA", unionCode: 63 },
              { unionName: "LAKSHMITARI", unionCode: 73 },
              { unionName: "NOHALI", unionCode: 77 },
              { unionName: "MARANIA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "KAUNIA",
            subdistrictCode: 42,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-30 (PART)", unionCode: 55 },
              { unionName: "HARAGACHH", unionCode: 23 },
              { unionName: "KAUNIA BALA PARA", unionCode: 27 },
              { unionName: "KURSHA", unionCode: 40 },
              { unionName: "SARAI", unionCode: 54 },
              { unionName: "SHAHIDBAGH", unionCode: 67 },
              { unionName: "TEPA MADHUPUR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "RANGPUR SADAR",
            subdistrictCode: 49,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 41 },
              { unionName: "WARD NO-17", unionCode: 42 },
              { unionName: "WARD NO-18", unionCode: 43 },
              { unionName: "WARD NO-19", unionCode: 44 },
              { unionName: "WARD NO-20", unionCode: 45 },
              { unionName: "WARD NO-21", unionCode: 46 },
              { unionName: "WARD NO-22", unionCode: 47 },
              { unionName: "WARD NO-23", unionCode: 48 },
              { unionName: "WARD NO-24", unionCode: 49 },
              { unionName: "WARD NO-25", unionCode: 50 },
              { unionName: "WARD NO-26", unionCode: 51 },
              { unionName: "WARD NO-27", unionCode: 52 },
              { unionName: "WARD NO-28", unionCode: 53 },
              { unionName: "WARD NO-29 (PART)", unionCode: 54 },
              { unionName: "WARD NO-30 (PART)", unionCode: 55 },
              { unionName: "WARD NO-31", unionCode: 56 },
              { unionName: "WARD NO-32", unionCode: 57 },
              { unionName: "WARD NO-33", unionCode: 58 },
              { unionName: "CHANDANPAT", unionCode: 17 },
              { unionName: "HARIDEBPUR", unionCode: 23 },
              { unionName: "KHALEYA", unionCode: 27 },
              { unionName: "MOMINPUR", unionCode: 31 },
              { unionName: "SADYA PUSHKARNI", unionCode: 63 },
              { unionName: "CANTONMENT", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "MITHA PUKUR",
            subdistrictCode: 58,
            unions: [
              { unionName: "BALUA MASIMPUR", unionCode: 17 },
              { unionName: "BARABALA", unionCode: 21 },
              { unionName: "BARA HAZRATPUR", unionCode: 26 },
              { unionName: "BALARHAT", unionCode: 32 },
              { unionName: "BHANGNI", unionCode: 37 },
              { unionName: "CHENGMARI", unionCode: 43 },
              { unionName: "DURGAPUR", unionCode: 49 },
              { unionName: "EMADPUR", unionCode: 54 },
              { unionName: "GOPALPUR", unionCode: 60 },
              { unionName: "KAFRIKHAL", unionCode: 62 },
              { unionName: "KHORAGACHH", unionCode: 65 },
              { unionName: "LATIFPUR", unionCode: 67 },
              { unionName: "MILANPUR", unionCode: 72 },
              { unionName: "MIRZAPUR", unionCode: 78 },
              { unionName: "MAYENPUR", unionCode: 83 },
              { unionName: "PAIRABAND", unionCode: 89 },
              { unionName: "RANI PUKUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "PIRGACHHA",
            subdistrictCode: 73,
            unions: [
              { unionName: "WARD NO-29 (PART)", unionCode: 54 },
              { unionName: "ANNADANAGAR", unionCode: 17 },
              { unionName: "CHHAOLA", unionCode: 19 },
              { unionName: "ITAKUMARI", unionCode: 28 },
              { unionName: "KAIKURI", unionCode: 38 },
              { unionName: "KALYANI", unionCode: 47 },
              { unionName: "KANDI", unionCode: 57 },
              { unionName: "PARUL", unionCode: 66 },
              { unionName: "PIRGACHHA", unionCode: 76 },
              { unionName: "TAMBULPUR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "PIRGANJ",
            subdistrictCode: 76,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA ALAMPUR", unionCode: 16 },
              { unionName: "BARA DARGAH", unionCode: 22 },
              { unionName: "BHENDABARI", unionCode: 28 },
              { unionName: "CHAITRAKUL", unionCode: 35 },
              { unionName: "CHATRA", unionCode: 41 },
              { unionName: "KABILPUR", unionCode: 47 },
              { unionName: "KUMEDPUR", unionCode: 54 },
              { unionName: "MADANKHALI", unionCode: 56 },
              { unionName: "MITHAPUR", unionCode: 58 },
              { unionName: "PANCHGACHHA", unionCode: 63 },
              { unionName: "PIRGANJ", unionCode: 69 },
              { unionName: "ROYPUR", unionCode: 75 },
              { unionName: "RAMNATHPUR", unionCode: 82 },
              { unionName: "SHANERHAT", unionCode: 88 },
              { unionName: "TUKURIA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "TARAGANJ",
            subdistrictCode: 92,
            unions: [
              { unionName: "ALAMPUR", unionCode: 25 },
              { unionName: "EKARCHALI", unionCode: 47 },
              { unionName: "HARIARKUTI", unionCode: 63 },
              { unionName: "KURSHA", unionCode: 71 },
              { unionName: "SAYAR", unionCode: 79 }
            ]
          }
        ]
      },
      {
        districtName: "THAKURGAON",
        districtCode: 94,
        subdistricts: [
          {
            subdistrictName: "BALIADANGI",
            subdistrictCode: 8,
            unions: [
              { unionName: "AMJANKHORE", unionCode: 10 },
              { unionName: "BARABARI", unionCode: 21 },
              { unionName: "BARA PALASHBARI", unionCode: 31 },
              { unionName: "BHANOR", unionCode: 42 },
              { unionName: "CHAROL", unionCode: 52 },
              { unionName: "DHANTALA", unionCode: 63 },
              { unionName: "DUOSUO", unionCode: 73 },
              { unionName: "PARIA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "HARIPUR",
            subdistrictCode: 51,
            unions: [
              { unionName: "AMGAON", unionCode: 13 },
              { unionName: "BAKUA", unionCode: 27 },
              { unionName: "BHATURIA", unionCode: 40 },
              { unionName: "DANGI PARA", unionCode: 54 },
              { unionName: "GEDURA", unionCode: 67 },
              { unionName: "HARIPUR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "PIRGANJ",
            subdistrictCode: 82,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAIRCHUNA", unionCode: 23 },
              { unionName: "BHOMRADAHA", unionCode: 25 },
              { unionName: "DAULATPUR", unionCode: 34 },
              { unionName: "HAJIPUR", unionCode: 43 },
              { unionName: "JABARHAT", unionCode: 51 },
              { unionName: "KHANGAON", unionCode: 60 },
              { unionName: "KUSHA RANIGANJ", unionCode: 69 },
              { unionName: "PIRGANJ", unionCode: 77 },
              { unionName: "SAIDPUR", unionCode: 86 },
              { unionName: "SENGAON", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "RANISANKAIL",
            subdistrictCode: 86,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BACHOR", unionCode: 15 },
              { unionName: "DHARMAGARH", unionCode: 31 },
              { unionName: "HOSSAIN GAON", unionCode: 47 },
              { unionName: "KASHIPUR", unionCode: 55 },
              { unionName: "LEHEMBA", unionCode: 63 },
              { unionName: "NONDUAR", unionCode: 71 },
              { unionName: "NEKMARAD", unionCode: 79 },
              { unionName: "RATOR", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "THAKURGAON SADAR",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "AKHANAGAR", unionCode: 13 },
              { unionName: "AKCHA", unionCode: 14 },
              { unionName: "AULIAPUR", unionCode: 15 },
              { unionName: "BEGUNBARI", unionCode: 17 },
              { unionName: "BALIA", unionCode: 21 },
              { unionName: "BARAGAON", unionCode: 26 },
              { unionName: "CHILARANG", unionCode: 31 },
              { unionName: "DEBIPUR", unionCode: 36 },
              { unionName: "DHOLARHAT", unionCode: 39 },
              { unionName: "GAREYA", unionCode: 42 },
              { unionName: "JAGANNATHPUR", unionCode: 47 },
              { unionName: "JAMALPUR", unionCode: 52 },
              { unionName: "MOHAMMADPUR", unionCode: 58 },
              { unionName: "NARGUN", unionCode: 63 },
              { unionName: "RAHIMANPUR", unionCode: 68 },
              { unionName: "ROYPUR", unionCode: 73 },
              { unionName: "RAJAGAON", unionCode: 79 },
              { unionName: "RUHEA", unionCode: 84 },
              { unionName: "RUHEA PASCHIM", unionCode: 86 },
              { unionName: "SALANDAR", unionCode: 89 },
              { unionName: "SUKHANPUKHARI", unionCode: 94 }
            ]
          }
        ]
      }
    ]
  },
  {
    divisionName: "SYLHET",
    divisionCode: 60,
    districts: [
      {
        districtName: "HABIGANJ",
        districtCode: 36,
        subdistricts: [
          {
            subdistrictName: "AJMIRIGANJ",
            subdistrictCode: 2,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AJMIRIGANJ", unionCode: 13 },
              { unionName: "BADALPUR", unionCode: 27 },
              { unionName: "JALSUKA", unionCode: 54 },
              { unionName: "KAKAILSEO", unionCode: 67 },
              { unionName: "SHIBPASHA", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "BAHUBAL",
            subdistrictCode: 5,
            unions: [
              { unionName: "BAHUBAL", unionCode: 11 },
              { unionName: "BHADESHWAR", unionCode: 23 },
              { unionName: "LAMATASHI", unionCode: 35 },
              { unionName: "MIRPUR", unionCode: 47 },
              { unionName: "PUTIJURI", unionCode: 59 },
              { unionName: "SATKAPAN", unionCode: 71 },
              { unionName: "SNANGHAT", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "BANIACHONG",
            subdistrictCode: 11,
            unions: [
              { unionName: "UTTAR PURBA BANIACHANG", unionCode: 11 },
              { unionName: "UTTAR PASCHIM BANIYACHANG", unionCode: 12 },
              { unionName: "DAKSHIN PURBA BANIYACHANG", unionCode: 18 },
              { unionName: "DAKSHIN PASCHIM BANIYACHANG", unionCode: 25 },
              { unionName: "BARAIURI", unionCode: 31 },
              { unionName: "DAULATPUR", unionCode: 37 },
              { unionName: "KAGAPASHA", unionCode: 44 },
              { unionName: "KHAGAURA", unionCode: 50 },
              { unionName: "MAKRAMPUR", unionCode: 56 },
              { unionName: "MANDARI", unionCode: 63 },
              { unionName: "MURADPUR", unionCode: 69 },
              { unionName: "PAILARKANDI", unionCode: 75 },
              { unionName: "PUKHRA", unionCode: 82 },
              { unionName: "SUJATPUR", unionCode: 88 },
              { unionName: "SUBIDPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "CHUNARUGHAT",
            subdistrictCode: 26,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AHMADABAD", unionCode: 13 },
              { unionName: "CHUNARUGHAT", unionCode: 19 },
              { unionName: "DEORGACHH", unionCode: 28 },
              { unionName: "GAZIPUR", unionCode: 38 },
              { unionName: "MIRAHI", unionCode: 47 },
              { unionName: "PAIK PARA", unionCode: 57 },
              { unionName: "RANIGAON", unionCode: 66 },
              { unionName: "SANKHOLA", unionCode: 76 },
              { unionName: "SHATIAJURI", unionCode: 85 },
              { unionName: "UBAHATA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "HABIGANJ SADAR",
            subdistrictCode: 44,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01 (SHAYESTAGANG)", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "GOPAYA", unionCode: 22 },
              { unionName: "LASKARPUR", unionCode: 24 },
              { unionName: "LUKHRA", unionCode: 28 },
              { unionName: "NIZAMPUR", unionCode: 38 },
              { unionName: "NURPUR", unionCode: 47 },
              { unionName: "POIL", unionCode: 57 },
              { unionName: "RAZIURA", unionCode: 66 },
              { unionName: "RICHI", unionCode: 76 },
              { unionName: "SAISTAGANJ", unionCode: 85 },
              { unionName: "TEGHARIA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "LAKHAI",
            subdistrictCode: 68,
            unions: [
              { unionName: "BAMAI", unionCode: 13 },
              { unionName: "BULLA", unionCode: 27 },
              { unionName: "KARAB", unionCode: 40 },
              { unionName: "LAKHAI", unionCode: 54 },
              { unionName: "MURAKARI", unionCode: 67 },
              { unionName: "MURIAUK", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "MADHABPUR",
            subdistrictCode: 71,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ADAIR", unionCode: 16 },
              { unionName: "BAGASURA", unionCode: 17 },
              { unionName: "BAHARA", unionCode: 25 },
              { unionName: "BULLA", unionCode: 34 },
              { unionName: "CHHATIAIN", unionCode: 43 },
              { unionName: "CHOWMOHANI", unionCode: 51 },
              { unionName: "DHARMAGHAR", unionCode: 60 },
              { unionName: "0AGADISHPUR", unionCode: 69 },
              { unionName: "ANDIURAUK", unionCode: 77 },
              { unionName: "NOAPARA", unionCode: 86 },
              { unionName: "SHAHJAHANPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NABIGANJ",
            subdistrictCode: 77,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AUSKANDI", unionCode: 13 },
              { unionName: "BAUSHA", unionCode: 14 },
              { unionName: "DEBPARA", unionCode: 21 },
              { unionName: "DIGHALBAK", unionCode: 29 },
              { unionName: "GAZNAPUR", unionCode: 43 },
              { unionName: "INATHGANJ", unionCode: 51 },
              { unionName: "KALAIR BANGA", unionCode: 58 },
              { unionName: "KARGAON", unionCode: 65 },
              { unionName: "KURSHI", unionCode: 73 },
              { unionName: "NABIGANJ", unionCode: 80 },
              { unionName: "PANIUNDA", unionCode: 87 },
              { unionName: "PURBA BARA BAKHAIR", unionCode: 90 },
              { unionName: "PASCHIM BARA BHAKHAIR", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "MAULVIBAZAR",
        districtCode: 58,
        subdistricts: [
          {
            subdistrictName: "BARLEKHA",
            subdistrictCode: 14,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARLEKHA", unionCode: 13 },
              { unionName: "BARNI", unionCode: 15 },
              { unionName: "DAKSHIN SHAHABAJPUR", unionCode: 29 },
              { unionName: "DAKSHIN DAKSHINBHAGH", unionCode: 31 },
              { unionName: "DASHER BAZAR", unionCode: 39 },
              { unionName: "NIJ BAHADURPUR", unionCode: 63 },
              { unionName: "SUJANAGAR", unionCode: 77 },
              { unionName: "UTTAR DAKSHINBHAG", unionCode: 80 },
              { unionName: "UTTAR SHAHABAJPUR", unionCode: 85 },
              { unionName: "TALIMPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "JURI",
            subdistrictCode: 35,
            unions: [
              { unionName: "FULTALA", unionCode: 23 },
              { unionName: "GOALBARI", unionCode: 29 },
              { unionName: "JAIFARNAGAR", unionCode: 47 },
              { unionName: "PASCHIM JURI", unionCode: 55 },
              { unionName: "PURBA JURI", unionCode: 67 },
              { unionName: "SAGARNAL", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "KAMALGANJ",
            subdistrictCode: 56,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ADAMPUR", unionCode: 17 },
              { unionName: "ALINAGAR", unionCode: 19 },
              { unionName: "ISLAMPUR", unionCode: 28 },
              { unionName: "KAMALGANJ", unionCode: 38 },
              { unionName: "MADHABPUR", unionCode: 47 },
              { unionName: "MUNSHI BAZAR", unionCode: 57 },
              { unionName: "PATANUSHAR", unionCode: 66 },
              { unionName: "RAHIMPUR", unionCode: 76 },
              { unionName: "SHAMSHERNAGAR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "KULAURA",
            subdistrictCode: 65,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHUKSHIMAIL", unionCode: 10 },
              { unionName: "BARAMCHAL", unionCode: 11 },
              { unionName: "BHATERA", unionCode: 13 },
              { unionName: "BRAHMAN BAZAR", unionCode: 17 },
              { unionName: "HAJIPUR", unionCode: 35 },
              { unionName: "JOYCHANDI", unionCode: 41 },
              { unionName: "KADIRPUR", unionCode: 53 },
              { unionName: "KARMADHA", unionCode: 59 },
              { unionName: "KULAURA", unionCode: 65 },
              { unionName: "PRITHIM PASHA", unionCode: 71 },
              { unionName: "ROUTHGAON", unionCode: 77 },
              { unionName: "SHARIFPUR", unionCode: 89 },
              { unionName: "TILAGAON", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "MAULVIBAZAR SADAR",
            subdistrictCode: 74,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AKHAILKURA", unionCode: 13 },
              { unionName: "AMTAIL", unionCode: 14 },
              { unionName: "CHANDIGHAT", unionCode: 21 },
              { unionName: "EKATUNA", unionCode: 29 },
              { unionName: "GIASNAGAR", unionCode: 36 },
              { unionName: "KAMALPUR", unionCode: 43 },
              { unionName: "KANAKPUR", unionCode: 51 },
              { unionName: "KHALILPUR", unionCode: 58 },
              { unionName: "MANUMUKH", unionCode: 65 },
              { unionName: "MOSTAFAPUR", unionCode: 80 },
              { unionName: "NAZIRABAD", unionCode: 87 },
              { unionName: "UPPER KAGABALA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "RAJNAGAR",
            subdistrictCode: 80,
            unions: [
              { unionName: "FATEHPUR", unionCode: 10 },
              { unionName: "KAMAR CHAK", unionCode: 21 },
              { unionName: "MANSURNAGAR", unionCode: 31 },
              { unionName: "MUNSHI BAZAR", unionCode: 42 },
              { unionName: "PANCHGAON", unionCode: 52 },
              { unionName: "RAJNAGAR", unionCode: 63 },
              { unionName: "TENGRA", unionCode: 73 },
              { unionName: "UTTARBHAG", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "SREEMANGAL",
            subdistrictCode: 83,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ASHIDRON", unionCode: 15 },
              { unionName: "BHUNABIR", unionCode: 19 },
              { unionName: "KALAPUR", unionCode: 28 },
              { unionName: "KALIGHAT", unionCode: 38 },
              { unionName: "MIRZAPUR", unionCode: 47 },
              { unionName: "RAJGHAT", unionCode: 57 },
              { unionName: "SATGOAN", unionCode: 66 },
              { unionName: "SINDURKHAN", unionCode: 76 },
              { unionName: "SREEMANGAL", unionCode: 85 }
            ]
          }
        ]
      },
      {
        districtName: "SUNAMGANJ",
        districtCode: 90,
        subdistricts: [
          {
            subdistrictName: "BISHWAMBARPUR",
            subdistrictCode: 18,
            unions: [
              { unionName: "DAKSHIN BADAGHAT", unionCode: 17 },
              { unionName: "DHONPUR", unionCode: 20 },
              { unionName: "FATEHPUR", unionCode: 34 },
              { unionName: "PALASH", unionCode: 77 },
              { unionName: "SHOLUKABAD", unionCode: 80 }
            ]
          },
          {
            subdistrictName: "CHHATAK",
            subdistrictCode: 23,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHATGAON", unionCode: 11 },
              { unionName: "CHHATAK", unionCode: 13 },
              { unionName: "CHARMOHALLA", unionCode: 14 },
              { unionName: "DULAR BAZAR", unionCode: 38 },
              { unionName: "DAKSHIN KHURMA", unionCode: 42 },
              { unionName: "DAKSHIN ISLAMPUR", unionCode: 47 },
              { unionName: "JAWAR BAZAR", unionCode: 52 },
              { unionName: "KALARUKA", unionCode: 57 },
              { unionName: "NOARAI", unionCode: 76 },
              { unionName: "SAIDERGAON", unionCode: 85 },
              { unionName: "SAILA AFZALABAD", unionCode: 90 },
              { unionName: "SING CHAPAIR", unionCode: 92 },
              { unionName: "UTTAR SURMA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DAKSHIN SUNAMGANJ",
            subdistrictCode: 27,
            unions: [
              { unionName: "DURGAPASHA", unionCode: 11 },
              { unionName: "JOYKALAS", unionCode: 33 },
              { unionName: "PATHARIA", unionCode: 61 },
              { unionName: "SHIMULBAK", unionCode: 78 },
              { unionName: "PASCHIM BIRGAON", unionCode: 89 },
              { unionName: "PASCHIM PAGLA", unionCode: 90 },
              { unionName: "PURBA BIRGOAN", unionCode: 92 },
              { unionName: "PURBA PAGLA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DERAI",
            subdistrictCode: 29,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHATI PARA", unionCode: 17 },
              { unionName: "CHARNAR CHAR", unionCode: 19 },
              { unionName: "DERAI SARMANGAL", unionCode: 28 },
              { unionName: "JAGADDAL", unionCode: 38 },
              { unionName: "KARIMPUR", unionCode: 47 },
              { unionName: "KULANJ", unionCode: 57 },
              { unionName: "RAFINAGAR", unionCode: 66 },
              { unionName: "RAJANAGAR", unionCode: 76 },
              { unionName: "TARAL", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "DHARAMPASHA",
            subdistrictCode: 32,
            unions: [
              { unionName: "DAKSHIN SUKHAIRRAJAPUR", unionCode: 21 },
              { unionName: "CHAMARDANI", unionCode: 23 },
              { unionName: "DAKSHIN BONGSHIKUNDA", unionCode: 35 },
              { unionName: "DHARMAPASHA", unionCode: 38 },
              { unionName: "JOYSREE", unionCode: 47 },
              { unionName: "MADHYANAGAR", unionCode: 57 },
              { unionName: "PAIKURATI", unionCode: 66 },
              { unionName: "SELBORASH", unionCode: 76 },
              { unionName: "UTTAR SUKHAIR RAJAPUR", unionCode: 85 },
              { unionName: "UTTAR BANGSHIKUNDA", unionCode: 90 }
            ]
          },
          {
            subdistrictName: "DOWARABAZAR",
            subdistrictCode: 33,
            unions: [
              { unionName: "BOUGLA BAZAR", unionCode: 21 },
              { unionName: "DAKSHIN DOWARABAZAR", unionCode: 27 },
              { unionName: "DUHALIA", unionCode: 32 },
              { unionName: "LAKSHMIPUR", unionCode: 61 },
              { unionName: "MANNARGAON", unionCode: 67 },
              { unionName: "PANDERGAON", unionCode: 78 },
              { unionName: "SURMA", unionCode: 81 },
              { unionName: "BANGLA BAZAR", unionCode: 86 },
              { unionName: "NARSING PUR", unionCode: 92 }
            ]
          },
          {
            subdistrictName: "JAGANNATHPUR",
            subdistrictCode: 47,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ASHARKANDI", unionCode: 17 },
              { unionName: "HALDIPUR", unionCode: 19 },
              { unionName: "KALKALIA", unionCode: 38 },
              { unionName: "MIRPUR", unionCode: 47 },
              { unionName: "PAILGAON", unionCode: 57 },
              { unionName: "PATALI", unionCode: 66 },
              { unionName: "RANIGANJ", unionCode: 76 },
              { unionName: "SYED PUR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "JAMALGANJ",
            subdistrictCode: 50,
            unions: [
              { unionName: "BEHELI", unionCode: 13 },
              { unionName: "VIMKHALI", unionCode: 27 },
              { unionName: "FENARBAK", unionCode: 54 },
              { unionName: "JAMALGANJ", unionCode: 67 },
              { unionName: "SACHNA BAZAR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "SULLA",
            subdistrictCode: 86,
            unions: [
              { unionName: "ATGAON", unionCode: 23 },
              { unionName: "BAHARA", unionCode: 47 },
              { unionName: "HABIBPUR", unionCode: 71 },
              { unionName: "SULLA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "SUNAMGANJ SADAR",
            subdistrictCode: 89,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "KURBANNAGAR(AFTABNAGAR)", unionCode: 10 },
              { unionName: "GOURARARANG", unionCode: 27 },
              { unionName: "JAHANGIRNAGAR", unionCode: 33 },
              { unionName: "KATAIR", unionCode: 36 },
              { unionName: "LAKSHMANSREE", unionCode: 39 },
              { unionName: "MOHANPUR", unionCode: 50 },
              { unionName: "MOLLAH PARA", unionCode: 55 },
              { unionName: "RANGAR CHAR", unionCode: 72 },
              { unionName: "SURMA", unionCode: 77 }
            ]
          },
          {
            subdistrictName: "TAHIRPUR",
            subdistrictCode: 92,
            unions: [
              { unionName: "BALIJURI", unionCode: 10 },
              { unionName: "DAKSHIN SREEPUR", unionCode: 33 },
              { unionName: "DAKSHIN BARADAL", unionCode: 43 },
              { unionName: "TAHIRPUR", unionCode: 64 },
              { unionName: "UTTAR BADAGHAT", unionCode: 71 },
              { unionName: "BARADAL", unionCode: 82 },
              { unionName: "UTTAR SREEPUR", unionCode: 92 }
            ]
          }
        ]
      },
      {
        districtName: "SYLHET",
        districtCode: 91,
        subdistricts: [
          {
            subdistrictName: "BALAGANJ",
            subdistrictCode: 8,
            unions: [
              { unionName: "BALAGANJ", unionCode: 11 },
              { unionName: "BOALJUR BAZAR", unionCode: 13 },
              { unionName: "DEWAN BAZAR", unionCode: 33 },
              { unionName: "PASCHIM GAURIPUR", unionCode: 60 },
              { unionName: "PURBA PAILANPUR", unionCode: 67 },
              { unionName: "PURBA GAURIPUR", unionCode: 70 }
            ]
          },
          {
            subdistrictName: "BEANI BAZAR",
            subdistrictCode: 17,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALINAGAR", unionCode: 11 },
              { unionName: "MOLLAHPUR", unionCode: 14 },
              { unionName: "CHARKHAI", unionCode: 25 },
              { unionName: "DOBHAG", unionCode: 34 },
              { unionName: "KURAR BAZAR", unionCode: 43 },
              { unionName: "LAUTA", unionCode: 51 },
              { unionName: "MATHIURA", unionCode: 60 },
              { unionName: "MURIA", unionCode: 77 },
              { unionName: "SHEOLA", unionCode: 86 },
              { unionName: "TILPARA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BISHWANATH",
            subdistrictCode: 20,
            unions: [
              { unionName: "ALANKARI", unionCode: 10 },
              { unionName: "BISHWANATH", unionCode: 21 },
              { unionName: "DAULATPUR", unionCode: 31 },
              { unionName: "DEOKALAS", unionCode: 42 },
              { unionName: "DASGHAR", unionCode: 52 },
              { unionName: "KHAZANCHIGAON", unionCode: 63 },
              { unionName: "LAMA KAZI", unionCode: 73 },
              { unionName: "RAMPASHA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "COMPANIGANJ",
            subdistrictCode: 27,
            unions: [
              { unionName: "ISLAMPUR PASCHIM", unionCode: 11 },
              { unionName: "ISLAMPUR PURBA", unionCode: 13 },
              { unionName: "ISHAKALAS", unionCode: 17 },
              { unionName: "RANIKHAI DAKSHIN", unionCode: 47 },
              { unionName: "RANIKHAI UTTAR", unionCode: 57 },
              { unionName: "TELIKHAL", unionCode: 71 }
            ]
          },
          {
            subdistrictName: "DAKSHIN SURMA",
            subdistrictCode: 31,
            unions: [
              { unionName: "DAUDPUR", unionCode: 30 },
              { unionName: "BARAIKANDI", unionCode: 36 },
              { unionName: "JALALPUR", unionCode: 37 },
              { unionName: "KAMAL BAZAR", unionCode: 41 },
              { unionName: "KUCHAI", unionCode: 45 },
              { unionName: "LALA BAZAR", unionCode: 50 },
              { unionName: "MOGLA BAZAR", unionCode: 60 },
              { unionName: "MOLLARGAON", unionCode: 65 },
              { unionName: "SILAM", unionCode: 75 },
              { unionName: "TETLI", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "FENCHUGANJ",
            subdistrictCode: 35,
            unions: [
              { unionName: "FENCHUGANJ", unionCode: 23 },
              { unionName: "GHILACHHARA", unionCode: 47 },
              { unionName: "MAIJGAON", unionCode: 71 },
              { unionName: "UTTAR FENCHUGANJ", unionCode: 93 },
              { unionName: "UTTAR KUSHIARA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "GOLAPGANJ",
            subdistrictCode: 38,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMURA", unionCode: 13 },
              { unionName: "BAGHA", unionCode: 15 },
              { unionName: "BHADESHWAR", unionCode: 17 },
              { unionName: "BUDBARI BAZAR", unionCode: 20 },
              { unionName: "DHAKA DAKSHIN", unionCode: 25 },
              { unionName: "SHORIFGONJ", unionCode: 36 },
              { unionName: "FULBARI", unionCode: 43 },
              { unionName: "GOLAPGANJ", unionCode: 51 },
              { unionName: "LAKSHMI PASHA", unionCode: 60 },
              { unionName: "LAKSHANABAND", unionCode: 69 },
              { unionName: "UTTAR BADE PASHA", unionCode: 77 }
            ]
          },
          {
            subdistrictName: "GOWAINGHAT",
            subdistrictCode: 41,
            unions: [
              { unionName: "ALIRGAON", unionCode: 10 },
              { unionName: "DAUBARI", unionCode: 16 },
              { unionName: "FATEHPUR", unionCode: 21 },
              { unionName: "PURBA JAFLONG", unionCode: 31 },
              { unionName: "PASCHIM JAFLONG", unionCode: 42 },
              { unionName: "LENGURA", unionCode: 52 },
              { unionName: "NANDIRGAON", unionCode: 63 },
              { unionName: "RUSTAMPUR", unionCode: 73 },
              { unionName: "TOWAKUL", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "JAINTIAPUR",
            subdistrictCode: 53,
            unions: [
              { unionName: "CHARIKATA", unionCode: 27 },
              { unionName: "CHIKNAGUL", unionCode: 29 },
              { unionName: "DARBASTA", unionCode: 31 },
              { unionName: "FATEHPUR", unionCode: 45 },
              { unionName: "JAINTAPUR", unionCode: 52 },
              { unionName: "NIJPAT", unionCode: 63 }
            ]
          },
          {
            subdistrictName: "KANAIGHAT",
            subdistrictCode: 59,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA CHATUL", unionCode: 13 },
              { unionName: "DAKSHIN BANIGRAM", unionCode: 19 },
              { unionName: "JHINGRABARI", unionCode: 38 },
              { unionName: "KANAIGHAT", unionCode: 47 },
              { unionName: "PASCHIM LAKSHMIP RASAD", unionCode: 66 },
              { unionName: "SATBAK (PASCHIM DIGHIRPAR)", unionCode: 70 },
              { unionName: "PURBA LAKSHMI PRASAD", unionCode: 72 },
              { unionName: "PURBA DIGHIRPAR", unionCode: 76 },
              { unionName: "RAJAGANJ", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "OSMANINAGAR",
            subdistrictCode: 60,
            unions: [
              { unionName: "BURUNGA", unionCode: 20 },
              { unionName: "DAYAMIR", unionCode: 27 },
              { unionName: "GOULA BAZAR", unionCode: 40 },
              { unionName: "OMARPUR", unionCode: 51 },
              { unionName: "OSMANPUR", unionCode: 55 },
              { unionName: "PASCHIM PAILANPUR", unionCode: 64 },
              { unionName: "SADIPUR", unionCode: 81 },
              { unionName: "TAJPUR", unionCode: 88 }
            ]
          },
          {
            subdistrictName: "SYLHET SADAR",
            subdistrictCode: 62,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD BO-18", unionCode: 18 },
              { unionName: "WARD NO-19", unionCode: 19 },
              { unionName: "WARD NO-20", unionCode: 20 },
              { unionName: "WARD NO-21", unionCode: 21 },
              { unionName: "WARD NO-22", unionCode: 22 },
              { unionName: "WARD NO-23", unionCode: 23 },
              { unionName: "WARD NO-24", unionCode: 24 },
              { unionName: "WARD NO-25", unionCode: 25 },
              { unionName: "WARD NO-26", unionCode: 26 },
              { unionName: "WARD NO-27", unionCode: 27 },
              { unionName: "HATKHOLA", unionCode: 32 },
              { unionName: "JALALABAD", unionCode: 34 },
              { unionName: "KANDIGAON", unionCode: 38 },
              { unionName: "KHADIMNAGAR", unionCode: 40 },
              { unionName: "KHADIM PARA", unionCode: 42 },
              { unionName: "MOGALGAON", unionCode: 55 },
              { unionName: "TUKER BAZAR", unionCode: 90 },
              { unionName: "TULTIKAR", unionCode: 95 },
              { unionName: "SYLHET CANTONMENT", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "ZAKIGANJ",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARAHAL", unionCode: 15 },
              { unionName: "BARA THAKURI", unionCode: 19 },
              { unionName: "BIRASREE", unionCode: 28 },
              { unionName: "KASHKANAKPUR", unionCode: 38 },
              { unionName: "KAJALSHAR", unionCode: 47 },
              { unionName: "KHOLACHHARA", unionCode: 57 },
              { unionName: "MANIKPUR", unionCode: 66 },
              { unionName: "SULTANPUR", unionCode: 76 },
              { unionName: "ZAKIGANJ", unionCode: 85 }
            ]
          }
        ]
      }
    ]
  },
  {
    divisionName: "MYMENSINGH",
    divisionCode: 45,
    districts: [
      {
        districtName: "JAMALPUR",
        districtCode: 39,
        subdistricts: [
          {
            subdistrictName: "BAKSHIGANJ",
            subdistrictCode: 7,
            unions: [
              { unionName: "BAGAR CHAR", unionCode: 11 },
              { unionName: "BAKSHIGANJ", unionCode: 23 },
              { unionName: "BATTAJORE", unionCode: 35 },
              { unionName: "DHANUA", unionCode: 47 },
              { unionName: "MERUR CHAR", unionCode: 59 },
              { unionName: "NILAKSHMIA", unionCode: 71 },
              { unionName: "SADHUR PARA", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "DEWANGANJ",
            subdistrictCode: 15,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAHADURABAD", unionCode: 17 },
              { unionName: "CHAR AOMKHAOA", unionCode: 29 },
              { unionName: "CHIKAJANI", unionCode: 36 },
              { unionName: "CHUKAIBARI", unionCode: 43 },
              { unionName: "DANGDHARA", unionCode: 51 },
              { unionName: "DEWANGANJ UNION", unionCode: 58 },
              { unionName: "HATEBHANGA", unionCode: 65 },
              { unionName: "PAR RAMRAMPUR", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "ISLAMPUR",
            subdistrictCode: 29,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BELGACHHA", unionCode: 13 },
              { unionName: "CHAR GOALINI", unionCode: 15 },
              { unionName: "CHAR PUTIMARI", unionCode: 23 },
              { unionName: "CHINADULLI", unionCode: 31 },
              { unionName: "GAIBANDHA", unionCode: 39 },
              { unionName: "GOALER CHAR", unionCode: 47 },
              { unionName: "ISLAMPUR", unionCode: 55 },
              { unionName: "KULKANDI", unionCode: 63 },
              { unionName: "NOARPARA", unionCode: 71 },
              { unionName: "PALBANDHA", unionCode: 79 },
              { unionName: "PATHARSI", unionCode: 87 },
              { unionName: "SAPDHARI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "JAMALPUR SADAR",
            subdistrictCode: 36,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "BANSHCHARA", unionCode: 13 },
              { unionName: "GHORADHAP", unionCode: 17 },
              { unionName: "DIGPAITH", unionCode: 19 },
              { unionName: "ITAIL", unionCode: 23 },
              { unionName: "KENDUA", unionCode: 35 },
              { unionName: "LAKSHMIR CHAR", unionCode: 41 },
              { unionName: "MESHTA", unionCode: 47 },
              { unionName: "NARUNDI", unionCode: 53 },
              { unionName: "RANAGACHHA", unionCode: 59 },
              { unionName: "RASHIDPUR", unionCode: 65 },
              { unionName: "SAHABAJPUR", unionCode: 71 },
              { unionName: "SHARIFPUR", unionCode: 77 },
              { unionName: "SREEPUR", unionCode: 83 },
              { unionName: "TITPALLA", unionCode: 89 },
              { unionName: "TULSIR CHAR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "MADARGANJ",
            subdistrictCode: 58,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ADARBHITA", unionCode: 11 },
              { unionName: "BALIJURI", unionCode: 23 },
              { unionName: "CHAR PAKERDAHA", unionCode: 35 },
              { unionName: "GUNARITALA", unionCode: 47 },
              { unionName: "JOREKHALI UNION", unionCode: 59 },
              { unionName: "KARAICHARA", unionCode: 71 },
              { unionName: "SIDHULI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "MELANDAHA",
            subdistrictCode: 61,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ADRA", unionCode: 17 },
              { unionName: "CHAR BANIPAKURI UNION", unionCode: 19 },
              { unionName: "DURMUT", unionCode: 28 },
              { unionName: "FULKOCHA", unionCode: 38 },
              { unionName: "GHOSHER PARA", unionCode: 47 },
              { unionName: "JHAUGARA", unionCode: 57 },
              { unionName: "KULIA", unionCode: 66 },
              { unionName: "MAHMUDPUR", unionCode: 76 },
              { unionName: "NANGLA", unionCode: 85 },
              { unionName: "NAYANAGAR", unionCode: 95 },
              { unionName: "SHAYMPUR", unionCode: 97 }
            ]
          },
          {
            subdistrictName: "SARISHABARI UPAZILA",
            subdistrictCode: 85,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AONA", unionCode: 10 },
              { unionName: "BHATARA", unionCode: 21 },
              { unionName: "DOAIL", unionCode: 31 },
              { unionName: "KAMRABAD", unionCode: 42 },
              { unionName: "MAHADAN", unionCode: 52 },
              { unionName: "PINGNA", unionCode: 63 },
              { unionName: "POGALDIGHA", unionCode: 73 },
              { unionName: "SATPOA", unionCode: 84 }
            ]
          }
        ]
      },
      {
        districtName: "MYMENSINGH",
        districtCode: 61,
        subdistricts: [
          {
            subdistrictName: "BHALUKA",
            subdistrictCode: 13,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHALUKA", unionCode: 14 },
              { unionName: "BHARADOBA", unionCode: 17 },
              { unionName: "BIRUNIA", unionCode: 25 },
              { unionName: "DAKATIA", unionCode: 34 },
              { unionName: "DHITPUR", unionCode: 43 },
              { unionName: "HABIRBARI", unionCode: 51 },
              { unionName: "KACHINA", unionCode: 60 },
              { unionName: "MALLIKBARI", unionCode: 69 },
              { unionName: "MEDUARY", unionCode: 77 },
              { unionName: "RAJAI", unionCode: 86 },
              { unionName: "UTHURA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DHOBAURA",
            subdistrictCode: 16,
            unions: [
              { unionName: "BAGHBER", unionCode: 10 },
              { unionName: "DAKSHIN MAIJ PARA", unionCode: 31 },
              { unionName: "DOBAURA", unionCode: 36 },
              { unionName: "GAMARITALA", unionCode: 42 },
              { unionName: "GUATALA", unionCode: 47 },
              { unionName: "GHOSHGAON", unionCode: 52 },
              { unionName: "PORA KANDULIA", unionCode: 73 }
            ]
          },
          {
            subdistrictName: "FULBARIA",
            subdistrictCode: 20,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ACHIM PATULI", unionCode: 11 },
              { unionName: "BAKTA", unionCode: 17 },
              { unionName: "BALIAN", unionCode: 23 },
              { unionName: "BHABANIPUR", unionCode: 29 },
              { unionName: "DEOKHOLA", unionCode: 35 },
              { unionName: "ENAYETPUR", unionCode: 41 },
              { unionName: "FULBARIA", unionCode: 47 },
              { unionName: "KALADAHA", unionCode: 53 },
              { unionName: "KUSHMAIL", unionCode: 59 },
              { unionName: "NAOGAON", unionCode: 77 },
              { unionName: "PUTIJANA", unionCode: 83 },
              { unionName: "RADHAKANAI", unionCode: 89 },
              { unionName: "RANGAMATIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "GAFFARGAON",
            subdistrictCode: 22,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARABARIA", unionCode: 11 },
              { unionName: "CHAR ALGI", unionCode: 12 },
              { unionName: "DATTER BAZAR", unionCode: 18 },
              { unionName: "GAFFARGAON", unionCode: 25 },
              { unionName: "JESSORA", unionCode: 31 },
              { unionName: "LANGAIR", unionCode: 37 },
              { unionName: "MASHAKHALI", unionCode: 44 },
              { unionName: "NIGAIR", unionCode: 50 },
              { unionName: "PAITHAL", unionCode: 56 },
              { unionName: "PANCHBHAG", unionCode: 63 },
              { unionName: "RASULPUR", unionCode: 69 },
              { unionName: "RAONA", unionCode: 75 },
              { unionName: "SALTIA", unionCode: 82 },
              { unionName: "TENGABA", unionCode: 88 },
              { unionName: "USTHI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "GAURIPUR",
            subdistrictCode: 23,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ACHINTAPUR", unionCode: 15 },
              { unionName: "BHANGNAMARI", unionCode: 18 },
              { unionName: "BOKAINAGAR", unionCode: 22 },
              { unionName: "DOWHAKHALA", unionCode: 27 },
              { unionName: "GAURIPUR", unionCode: 31 },
              { unionName: "MAILAKANDA", unionCode: 49 },
              { unionName: "MAOHA", unionCode: 58 },
              { unionName: "RAMGOPALPUR", unionCode: 72 },
              { unionName: "SAHANATI", unionCode: 81 },
              { unionName: "SIDHLA", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "HALUAGHAT",
            subdistrictCode: 24,
            unions: [
              { unionName: "AMTAIL", unionCode: 17 },
              { unionName: "BHUBANKURA", unionCode: 20 },
              { unionName: "BILDORA", unionCode: 21 },
              { unionName: "DHARA", unionCode: 33 },
              { unionName: "DHURAIL", unionCode: 40 },
              { unionName: "GAZIR BHITA", unionCode: 47 },
              { unionName: "HALUAGHAT", unionCode: 61 },
              { unionName: "JUGLI", unionCode: 67 },
              { unionName: "KAICHAPUR", unionCode: 74 },
              { unionName: "NARAIL", unionCode: 81 },
              { unionName: "SAKUAI", unionCode: 84 },
              { unionName: "SWADESHI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "ISHWARGANJ",
            subdistrictCode: 31,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ATHARABARI", unionCode: 11 },
              { unionName: "BARAHIT", unionCode: 13 },
              { unionName: "ISHWARGANJ", unionCode: 40 },
              { unionName: "JATIA", unionCode: 45 },
              { unionName: "MAIJBAGH", unionCode: 54 },
              { unionName: "MOGTOLA", unionCode: 63 },
              { unionName: "RAJIBPUR", unionCode: 67 },
              { unionName: "SARISHA", unionCode: 76 },
              { unionName: "SOHAGI", unionCode: 85 },
              { unionName: "TARUNDIA", unionCode: 90 },
              { unionName: "UCHAKHILA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MYMENSINGH SADAR",
            subdistrictCode: 52,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18", unionCode: 18 },
              { unionName: "WARD NO-19", unionCode: 19 },
              { unionName: "WARD NO-20", unionCode: 20 },
              { unionName: "WARD NO-21", unionCode: 21 },
              { unionName: "AKUA", unionCode: 23 },
              { unionName: "ASHTADHAR", unionCode: 25 },
              { unionName: "BAIRA (KEWATKHALI) UNION", unionCode: 27 },
              { unionName: "BHABKHALI UNION", unionCode: 29 },
              { unionName: "BORAR CHAR UNION", unionCode: 31 },
              { unionName: "CHAR ISHWARDIA UNION", unionCode: 33 },
              { unionName: "CHAR NILAKSHMIA UNION", unionCode: 40 },
              { unionName: "DAPUNIA UNION", unionCode: 47 },
              { unionName: "GHAGRA UNION", unionCode: 54 },
              { unionName: "KHAGDAHAR UNION", unionCode: 67 },
              { unionName: "KUSHTIA UNION", unionCode: 74 },
              { unionName: "PARANGANJ UNION", unionCode: 88 },
              { unionName: "SIRTA UNION", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MUKTAGACHHA",
            subdistrictCode: 65,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARAGRAM", unionCode: 16 },
              { unionName: "BASATI", unionCode: 17 },
              { unionName: "DAOGAON", unionCode: 25 },
              { unionName: "DULLA", unionCode: 34 },
              { unionName: "GHOGA", unionCode: 43 },
              { unionName: "KASHIMPUR", unionCode: 51 },
              { unionName: "KHERUAJANI", unionCode: 60 },
              { unionName: "KUMARGHATA", unionCode: 69 },
              { unionName: "MANKON UNION", unionCode: 77 },
              { unionName: "TARATI UNION", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NANDAIL",
            subdistrictCode: 72,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ACHARGAON", unionCode: 13 },
              { unionName: "BETAGAIR", unionCode: 15 },
              { unionName: "CHANDIPASHA", unionCode: 23 },
              { unionName: "GANGAIL", unionCode: 31 },
              { unionName: "JAHANGIRPUR", unionCode: 39 },
              { unionName: "KHARUA", unionCode: 47 },
              { unionName: "MOAZZEMPUR", unionCode: 55 },
              { unionName: "MUSULI", unionCode: 63 },
              { unionName: "NANDAIL", unionCode: 71 },
              { unionName: "RAJGATI", unionCode: 79 },
              { unionName: "SHERPUR", unionCode: 87 },
              { unionName: "SINGRAIL", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "PHULPUR",
            subdistrictCode: 81,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BALIA", unionCode: 11 },
              { unionName: "BHAITKANDI", unionCode: 18 },
              { unionName: "BAOLA", unionCode: 27 },
              { unionName: "PAYARI", unionCode: 54 },
              { unionName: "PHULPUR", unionCode: 58 },
              { unionName: "RAHIMGANJ", unionCode: 63 },
              { unionName: "RAMBHADRAPUR", unionCode: 67 },
              { unionName: "RUPASI", unionCode: 76 },
              { unionName: "SANDHARA", unionCode: 81 },
              { unionName: "SINGHESHWAR", unionCode: 90 }
            ]
          },
          {
            subdistrictName: "TARAKANDA",
            subdistrictCode: 88,
            unions: [
              { unionName: "BALIKHAN", unionCode: 12 },
              { unionName: "BANIHALA", unionCode: 13 },
              { unionName: "BISHKA", unionCode: 22 },
              { unionName: "DHAKUA", unionCode: 31 },
              { unionName: "GALAGAON", unionCode: 36 },
              { unionName: "KAKNI", unionCode: 40 },
              { unionName: "KAMARGAON", unionCode: 45 },
              { unionName: "KAMARIA", unionCode: 49 },
              { unionName: "RAMPUR", unionCode: 72 },
              { unionName: "TARAKANDA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "TRISHAL",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMIRABARI", unionCode: 13 },
              { unionName: "BAILAR", unionCode: 15 },
              { unionName: "BALI PARA", unionCode: 19 },
              { unionName: "DHANIKHOLA", unionCode: 28 },
              { unionName: "HARIRAMPUR", unionCode: 38 },
              { unionName: "KANIHARI", unionCode: 47 },
              { unionName: "KANTHAL", unionCode: 57 },
              { unionName: "MATHBARI", unionCode: 61 },
              { unionName: "MOKSHAPUR", unionCode: 63 },
              { unionName: "RAMPUR", unionCode: 66 },
              { unionName: "SAKHUA", unionCode: 76 },
              { unionName: "TRISHAL", unionCode: 85 }
            ]
          }
        ]
      },
      {
        districtName: "NETRAKONA",
        districtCode: 72,
        subdistricts: [
          {
            subdistrictName: "ATPARA",
            subdistrictCode: 4,
            unions: [
              { unionName: "BANIAJAN", unionCode: 11 },
              { unionName: "DUAZ", unionCode: 23 },
              { unionName: "LONESHWAR", unionCode: 35 },
              { unionName: "SARMAISA", unionCode: 47 },
              { unionName: "SUKHARI", unionCode: 59 },
              { unionName: "SONAI", unionCode: 71 },
              { unionName: "TELIGATI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "BARHATTA",
            subdistrictCode: 9,
            unions: [
              { unionName: "ASMA", unionCode: 11 },
              { unionName: "BARHATTA", unionCode: 23 },
              { unionName: "BAUSHI", unionCode: 35 },
              { unionName: "CHHIRAM", unionCode: 47 },
              { unionName: "ROYPUR", unionCode: 59 },
              { unionName: "SAHATA", unionCode: 71 },
              { unionName: "SINGDHA", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "DURGAPUR",
            subdistrictCode: 18,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAKALJORA", unionCode: 12 },
              { unionName: "BIRISIRI", unionCode: 17 },
              { unionName: "CHANDIGARH", unionCode: 25 },
              { unionName: "DURGAPUR", unionCode: 51 },
              { unionName: "GAOKANDIA", unionCode: 69 },
              { unionName: "KAKAIRGARA", unionCode: 77 },
              { unionName: "KULLAGORA", unionCode: 86 }
            ]
          },
          {
            subdistrictName: "KHALIAJURI",
            subdistrictCode: 38,
            unions: [
              { unionName: "CHAKUA", unionCode: 13 },
              { unionName: "GAZIPUR", unionCode: 27 },
              { unionName: "KHALIAJURI", unionCode: 40 },
              { unionName: "KRISHNAPUR", unionCode: 54 },
              { unionName: "MENDIPUR", unionCode: 67 },
              { unionName: "NAGAR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "KALMAKANDA",
            subdistrictCode: 40,
            unions: [
              { unionName: "BARA KHARPAN", unionCode: 11 },
              { unionName: "KAILATI", unionCode: 23 },
              { unionName: "KALMAKANDA", unionCode: 35 },
              { unionName: "KHARNAI", unionCode: 47 },
              { unionName: "LENGURA", unionCode: 59 },
              { unionName: "NAZIRPUR", unionCode: 71 },
              { unionName: "POGLA", unionCode: 83 },
              { unionName: "RANGCHHATI", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "KENDUA",
            subdistrictCode: 47,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ASUJIA", unionCode: 11 },
              { unionName: "BALAISHIMUL", unionCode: 13 },
              { unionName: "CHIRANG", unionCode: 20 },
              { unionName: "DALPA", unionCode: 27 },
              { unionName: "GARADOBA", unionCode: 33 },
              { unionName: "GANDA", unionCode: 40 },
              { unionName: "KANDIURA", unionCode: 47 },
              { unionName: "MASHKA", unionCode: 61 },
              { unionName: "MUZAFFARPUR", unionCode: 67 },
              { unionName: "NOAPARA", unionCode: 74 },
              { unionName: "PAIKURA", unionCode: 81 },
              { unionName: "ROAILBARI", unionCode: 88 },
              { unionName: "SANDIKONA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MADAN",
            subdistrictCode: 56,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "FATEHPUR", unionCode: 10 },
              { unionName: "GOBINDASREE", unionCode: 21 },
              { unionName: "CHANDGAON", unionCode: 31 },
              { unionName: "KAITAIL", unionCode: 42 },
              { unionName: "MADAN", unionCode: 52 },
              { unionName: "MAGHAN", unionCode: 63 },
              { unionName: "NAYEKPUR", unionCode: 73 },
              { unionName: "TIASREE", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "MOHANGANJ",
            subdistrictCode: 63,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARKASHIA BIRAMP", unionCode: 10 },
              { unionName: "BARATALI BANIHARI", unionCode: 21 },
              { unionName: "GAGLAJUR", unionCode: 31 },
              { unionName: "MAGHAN SIADHAR", unionCode: 42 },
              { unionName: "SAMAJ SAHILDEO", unionCode: 63 },
              { unionName: "SUAIR", unionCode: 73 },
              { unionName: "TENTULIA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "NETROKONA SADAR",
            subdistrictCode: 74,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMTALA", unionCode: 13 },
              { unionName: "CHALLISHA", unionCode: 15 },
              { unionName: "DAKSHIN BISHIURA", unionCode: 23 },
              { unionName: "KAILATI", unionCode: 31 },
              { unionName: "KALIARA GABRAGAT", unionCode: 39 },
              { unionName: "LAKSHMIGANJ", unionCode: 47 },
              { unionName: "MADANPUR", unionCode: 54 },
              { unionName: "MEDNI", unionCode: 55 },
              { unionName: "MAUGATI", unionCode: 63 },
              { unionName: "RAUHA", unionCode: 79 },
              { unionName: "SINGHAR BANGLA", unionCode: 87 },
              { unionName: "THAKURAKONA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "PURBADHALA",
            subdistrictCode: 83,
            unions: [
              { unionName: "AGIA", unionCode: 13 },
              { unionName: "BAIRATI", unionCode: 15 },
              { unionName: "BISHKAKUNI", unionCode: 23 },
              { unionName: "DHALA MULGAON", unionCode: 31 },
              { unionName: "GHAGRA", unionCode: 39 },
              { unionName: "GOHALAKANDA", unionCode: 55 },
              { unionName: "HOGLA", unionCode: 63 },
              { unionName: "JARIA", unionCode: 71 },
              { unionName: "KHALISHAUR", unionCode: 79 },
              { unionName: "NARANDIA", unionCode: 87 },
              { unionName: "PURBADHALA", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "SHERPUR",
        districtCode: 89,
        subdistricts: [
          {
            subdistrictName: "JHENAIGATI",
            subdistrictCode: 37,
            unions: [
              { unionName: "DHANSHAIL", unionCode: 12 },
              { unionName: "GAURIPUR", unionCode: 15 },
              { unionName: "HATIBANDHA MALIJHIKANDA", unionCode: 17 },
              { unionName: "JHENAIGATI", unionCode: 25 },
              { unionName: "KANGSHA", unionCode: 43 },
              { unionName: "MALIJHIKANDA", unionCode: 50 },
              { unionName: "NALKURA", unionCode: 60 }
            ]
          },
          {
            subdistrictName: "NAKLA",
            subdistrictCode: 67,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BANESHWARDI", unionCode: 17 },
              { unionName: "CHANDRAKONA", unionCode: 19 },
              { unionName: "CHAR ASHTADHAR", unionCode: 28 },
              { unionName: "GANAPADDI", unionCode: 38 },
              { unionName: "GOURDWAR", unionCode: 47 },
              { unionName: "NAKLA", unionCode: 57 },
              { unionName: "PATHAKATA", unionCode: 66 },
              { unionName: "TALKI", unionCode: 76 },
              { unionName: "URPHA", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "NALITABARI",
            subdistrictCode: 70,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAGHBER", unionCode: 30 },
              { unionName: "JOGANIA", unionCode: 34 },
              { unionName: "KAKARKANDI", unionCode: 38 },
              { unionName: "KALASPUR", unionCode: 41 },
              { unionName: "NALITABARI", unionCode: 51 },
              { unionName: "NAYABIL", unionCode: 55 },
              { unionName: "NUNNI", unionCode: 69 },
              { unionName: "MARICHPURA", unionCode: 72 },
              { unionName: "PORAGAON", unionCode: 75 },
              { unionName: "RAJNAGAR", unionCode: 77 },
              { unionName: "RAMCHANDRAKURA MANDALIA", unionCode: 86 },
              { unionName: "RUPNARAYANKURA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SHERPUR SADAR",
            subdistrictCode: 88,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAJITKHILA", unionCode: 11 },
              { unionName: "BALAIR CHAR", unionCode: 13 },
              { unionName: "BETMARI GHUGHURAKANDI", unionCode: 15 },
              { unionName: "BHATSALA", unionCode: 20 },
              { unionName: "CHAR MUCHARIA", unionCode: 27 },
              { unionName: "CHAR PAKSHIMARI", unionCode: 33 },
              { unionName: "CHAR SHERPUR", unionCode: 40 },
              { unionName: "DHALA", unionCode: 47 },
              { unionName: "GHAZIR KHAMAR", unionCode: 54 },
              { unionName: "KAMARER CHAR", unionCode: 61 },
              { unionName: "KAMARIA", unionCode: 67 },
              { unionName: "LAKSHMANPUR", unionCode: 74 },
              { unionName: "PAKURIA", unionCode: 81 },
              { unionName: "RAUHA", unionCode: 88 }
            ]
          },
          {
            subdistrictName: "SREEBARDI",
            subdistrictCode: 90,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHELUA", unionCode: 15 },
              { unionName: "GARJARIPA", unionCode: 31 },
              { unionName: "GOSAIPUR", unionCode: 39 },
              { unionName: "KAKILAKURA", unionCode: 47 },
              { unionName: "KHARIA KAZIR CHAR", unionCode: 55 },
              { unionName: "KURIKAHANIA", unionCode: 63 },
              { unionName: "RANI SHIMUL", unionCode: 71 },
              { unionName: "SINGA BARUNA", unionCode: 79 },
              { unionName: "SREEBARDI", unionCode: 86 },
              { unionName: "TANTIHATI", unionCode: 94 }
            ]
          }
        ]
      }
    ]
  },
  {
    divisionName: "KHULNA",
    divisionCode: 40,
    districts: [
      {
        districtName: "BAGERHAT",
        districtCode: 1,
        subdistricts: [
          {
            subdistrictName: "BAGERHAT SADAR",
            subdistrictCode: 8,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARAI PARA", unionCode: 17 },
              { unionName: "BEMARTA", unionCode: 25 },
              { unionName: "BISHNUPUR", unionCode: 34 },
              { unionName: "DEMA", unionCode: 35 },
              { unionName: "GOTA PARA", unionCode: 51 },
              { unionName: "JATRAPUR", unionCode: 60 },
              { unionName: "KARA PARA", unionCode: 69 },
              { unionName: "KHANPUR", unionCode: 77 },
              { unionName: "RAKHALGACHHI", unionCode: 86 },
              { unionName: "SHAT GAMBUJ", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "CHITALMARI",
            subdistrictCode: 14,
            unions: [
              { unionName: "CHAR BANIARI", unionCode: 15 },
              { unionName: "BARA BARIA", unionCode: 19 },
              { unionName: "CHITALMARI", unionCode: 31 },
              { unionName: "HIZLA", unionCode: 47 },
              { unionName: "SANTOSHPUR", unionCode: 63 },
              { unionName: "KALATALA", unionCode: 66 },
              { unionName: "SHIBPUR", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "FAKIRHAT",
            subdistrictCode: 34,
            unions: [
              { unionName: "BAHIRDIA MANSA", unionCode: 10 },
              { unionName: "BETAGA", unionCode: 21 },
              { unionName: "FAKIRHAT", unionCode: 31 },
              { unionName: "LAKHPUR", unionCode: 42 },
              { unionName: "MULGHAR", unionCode: 52 },
              { unionName: "NALDHA MAUBHOG", unionCode: 63 },
              { unionName: "PILJANGA", unionCode: 73 },
              { unionName: "SUBHADIA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "KACHUA",
            subdistrictCode: 38,
            unions: [
              { unionName: "BADHAL", unionCode: 15 },
              { unionName: "DHOPAKHALI", unionCode: 28 },
              { unionName: "GAZALIA", unionCode: 38 },
              { unionName: "GOPALPUR", unionCode: 47 },
              { unionName: "KACHUA", unionCode: 57 },
              { unionName: "MAGHIA", unionCode: 66 },
              { unionName: "RARI PARA", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "MOLLAHAT",
            subdistrictCode: 56,
            unions: [
              { unionName: "ATJURI", unionCode: 17 },
              { unionName: "CHUNKHOLA", unionCode: 28 },
              { unionName: "GANGNI", unionCode: 38 },
              { unionName: "GAOLA", unionCode: 47 },
              { unionName: "KODALIA", unionCode: 76 },
              { unionName: "KULIA", unionCode: 85 },
              { unionName: "UDAYPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "MONGLA",
            subdistrictCode: 58,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHANDPI", unionCode: 23 },
              { unionName: "BURIRDANGA", unionCode: 27 },
              { unionName: "CHILA", unionCode: 29 },
              { unionName: "MITHAKHALI", unionCode: 59 },
              { unionName: "SUNILTALA", unionCode: 83 },
              { unionName: "SUNDARBAN", unionCode: 89 },
              { unionName: "CHANDPAI RANGE", unionCode: 97 }
            ]
          },
          {
            subdistrictName: "MORRELGANJ",
            subdistrictCode: 60,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAHARBUNIA", unionCode: 10 },
              { unionName: "BALAIBUNIA", unionCode: 11 },
              { unionName: "BANAGRAM", unionCode: 17 },
              { unionName: "BARAIKHALI", unionCode: 23 },
              { unionName: "CHINGRAKHALI", unionCode: 29 },
              { unionName: "DAIBAGNYAHATI", unionCode: 35 },
              { unionName: "HOGLABUNIA", unionCode: 41 },
              { unionName: "HOGLA PASHA", unionCode: 47 },
              { unionName: "JIUDHARA", unionCode: 53 },
              { unionName: "KHUOLIA", unionCode: 59 },
              { unionName: "MORRELGANJ", unionCode: 65 },
              { unionName: "NISHANBARIA", unionCode: 71 },
              { unionName: "PANCHAKARAN", unionCode: 77 },
              { unionName: "PUTIKHALI", unionCode: 83 },
              { unionName: "RAMCHANDRAPUR", unionCode: 89 },
              { unionName: "TELIGATI", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "RAMPAL",
            subdistrictCode: 73,
            unions: [
              { unionName: "BAINTALA", unionCode: 10 },
              { unionName: "BANSHTALI", unionCode: 11 },
              { unionName: "BHOJPATIA", unionCode: 17 },
              { unionName: "GAURAMBHA", unionCode: 41 },
              { unionName: "HURKA", unionCode: 47 },
              { unionName: "MALLIKER BER", unionCode: 53 },
              { unionName: "PERIKHALI", unionCode: 71 },
              { unionName: "RAJNAGAR", unionCode: 77 },
              { unionName: "RAMPAL", unionCode: 83 },
              { unionName: "UJALKUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SARANKHOLA",
            subdistrictCode: 77,
            unions: [
              { unionName: "DHANSAGAR", unionCode: 19 },
              { unionName: "KHONTAKATA", unionCode: 38 },
              { unionName: "ROYENDA", unionCode: 57 },
              { unionName: "DAKHIN KHALI", unionCode: 76 },
              { unionName: "SHARANKHOLA RANGE", unionCode: 97 }
            ]
          }
        ]
      },
      {
        districtName: "CHUADANGA",
        districtCode: 18,
        subdistricts: [
          {
            subdistrictName: "ALAMDANGA",
            subdistrictCode: 7,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AILHAS", unionCode: 11 },
              { unionName: "BELGACHHI", unionCode: 13 },
              { unionName: "BARADI", unionCode: 15 },
              { unionName: "BHANGABARIA", unionCode: 23 },
              { unionName: "CHITLA", unionCode: 27 },
              { unionName: "DAUKI", unionCode: 31 },
              { unionName: "GANGNI", unionCode: 39 },
              { unionName: "HARDI", unionCode: 47 },
              { unionName: "KALIDASHPUR", unionCode: 51 },
              { unionName: "JAMJAMI", unionCode: 55 },
              { unionName: "JEHALA", unionCode: 63 },
              { unionName: "KHADIMPUR", unionCode: 71 },
              { unionName: "KHASKARA", unionCode: 79 },
              { unionName: "KUMARI", unionCode: 87 },
              { unionName: "NAGDAHA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "CHUADANGA SADAR",
            subdistrictCode: 23,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALOKDIA", unionCode: 11 },
              { unionName: "BEGAMPUR", unionCode: 23 },
              { unionName: "KUTUBPUR", unionCode: 47 },
              { unionName: "MOMINPUR", unionCode: 59 },
              { unionName: "PADMABILA", unionCode: 62 },
              { unionName: "SHANKAR CHANDRA", unionCode: 71 },
              { unionName: "TITUDAHA", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "DAMURHUDA",
            subdistrictCode: 31,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DAMURHUDA", unionCode: 11 },
              { unionName: "PERKRISHNAPUR MADNA", unionCode: 23 },
              { unionName: "HOWLI", unionCode: 35 },
              { unionName: "JURANPUR", unionCode: 47 },
              { unionName: "KAPASADANGA", unionCode: 59 },
              { unionName: "KURALGACHHI", unionCode: 71 },
              { unionName: "NATIPOTA", unionCode: 83 },
              { unionName: "NATODAHA", unionCode: 88 }
            ]
          },
          {
            subdistrictName: "JIBAN NAGAR",
            subdistrictCode: 55,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ANDULBARIA", unionCode: 19 },
              { unionName: "BANKA", unionCode: 38 },
              { unionName: "HASADAH", unionCode: 42 },
              { unionName: "ROYPUR", unionCode: 50 },
              { unionName: "SIMANTA", unionCode: 57 },
              { unionName: "UTHALI", unionCode: 76 }
            ]
          }
        ]
      },
      {
        districtName: "JESSORE",
        districtCode: 41,
        subdistricts: [
          {
            subdistrictName: "ABHAYNAGAR",
            subdistrictCode: 4,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAGHUTIA", unionCode: 10 },
              { unionName: "PRAMBAG", unionCode: 21 },
              { unionName: "SUNDOLI", unionCode: 27 },
              { unionName: "PAYRA", unionCode: 42 },
              { unionName: "CHALISHIA", unionCode: 52 },
              { unionName: "SIDDHIPASHA", unionCode: 63 },
              { unionName: "SREEDHARPUR", unionCode: 73 },
              { unionName: "SUBHA PARA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "BAGHER PARA",
            subdistrictCode: 9,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BASUARI", unionCode: 15 },
              { unionName: "BANDABILLA", unionCode: 19 },
              { unionName: "DARAJHAT", unionCode: 28 },
              { unionName: "DHALGRAM", unionCode: 38 },
              { unionName: "DOHAKULA", unionCode: 47 },
              { unionName: "JAMDIA", unionCode: 57 },
              { unionName: "JAHARPUR", unionCode: 66 },
              { unionName: "NARIKELBARIA", unionCode: 76 },
              { unionName: "ROYPUR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "CHAUGACHHA",
            subdistrictCode: 11,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHAUGACHHA", unionCode: 14 },
              { unionName: "DHULIANI", unionCode: 17 },
              { unionName: "HAKIMPUR", unionCode: 25 },
              { unionName: "JAGADISHPUR", unionCode: 34 },
              { unionName: "SWARUPDAHA", unionCode: 43 },
              { unionName: "NARAYANPUR", unionCode: 51 },
              { unionName: "PASHAPOLE", unionCode: 60 },
              { unionName: "PATIBILA", unionCode: 69 },
              { unionName: "PHULSARA", unionCode: 77 },
              { unionName: "SINGHAJHULI", unionCode: 86 },
              { unionName: "SUKPUKHURIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "JHIKARGACHHA",
            subdistrictCode: 23,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BANKRA UNION", unionCode: 25 },
              { unionName: "GANGANANDAPUR", unionCode: 29 },
              { unionName: "GADKHALI", unionCode: 35 },
              { unionName: "HAJIRBAGH", unionCode: 41 },
              { unionName: "JHIKARGACHHA", unionCode: 47 },
              { unionName: "MAGURA", unionCode: 53 },
              { unionName: "NABHARAN", unionCode: 59 },
              { unionName: "NIBASKHOLA", unionCode: 65 },
              { unionName: "PANISARA", unionCode: 71 },
              { unionName: "SHANKARPUR", unionCode: 83 },
              { unionName: "SHIMULIA", unionCode: 89 }
            ]
          },
          {
            subdistrictName: "KESHABPUR",
            subdistrictCode: 38,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BIDYANANDAKATI", unionCode: 17 },
              { unionName: "GAURIGHONA", unionCode: 19 },
              { unionName: "HASANPUR", unionCode: 24 },
              { unionName: "KESHABPUR", unionCode: 28 },
              { unionName: "MAJIDPUR", unionCode: 38 },
              { unionName: "MANGALKOT", unionCode: 47 },
              { unionName: "PANJIA", unionCode: 57 },
              { unionName: "SAGARDARI", unionCode: 66 },
              { unionName: "SATBARIA", unionCode: 71 },
              { unionName: "SUFALAKATI", unionCode: 76 },
              { unionName: "TRIMOHINI", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "JESSORE SADAR",
            subdistrictCode: 47,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ARABPUR", unionCode: 10 },
              { unionName: "BASUNDIA", unionCode: 11 },
              { unionName: "CHANCHRA", unionCode: 23 },
              { unionName: "CHURAMANKATI", unionCode: 29 },
              { unionName: "DIARA", unionCode: 35 },
              { unionName: "FATHEHPUR", unionCode: 41 },
              { unionName: "HAIBATPUR", unionCode: 47 },
              { unionName: "ICHHALI", unionCode: 53 },
              { unionName: "KASHIMPUR", unionCode: 65 },
              { unionName: "KACHUA", unionCode: 71 },
              { unionName: "LEBUTALA", unionCode: 77 },
              { unionName: "NARENDRAPUR", unionCode: 83 },
              { unionName: "NOAPARA", unionCode: 89 },
              { unionName: "RAMNAGAR", unionCode: 92 },
              { unionName: "UPASAHAR", unionCode: 95 },
              { unionName: "JESSORE CANTONMENT", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "MANIRAMPUR",
            subdistrictCode: 61,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHOJGATI", unionCode: 10 },
              { unionName: "CHALUAHATI", unionCode: 11 },
              { unionName: "DHAKURIA", unionCode: 16 },
              { unionName: "DURBADANGA", unionCode: 22 },
              { unionName: "HARIDASKATI", unionCode: 27 },
              { unionName: "HARIHARNAGAR", unionCode: 33 },
              { unionName: "JHANPA", unionCode: 39 },
              { unionName: "KASHIMNAGAR", unionCode: 44 },
              { unionName: "KHANPUR", unionCode: 50 },
              { unionName: "KHEDA PARA", unionCode: 55 },
              { unionName: "KULTIA", unionCode: 61 },
              { unionName: "MANIRAMPUR", unionCode: 67 },
              { unionName: "MANOHARPUR", unionCode: 72 },
              { unionName: "MASWIMNAGAR", unionCode: 78 },
              { unionName: "NEHALPUR", unionCode: 83 },
              { unionName: "ROHITA", unionCode: 89 },
              { unionName: "SHYAMKUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SHARSHA",
            subdistrictCode: 90,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAGACHRA", unionCode: 13 },
              { unionName: "BAHADURPUR", unionCode: 17 },
              { unionName: "BENAPOLE", unionCode: 25 },
              { unionName: "DIHI", unionCode: 34 },
              { unionName: "GOGA", unionCode: 43 },
              { unionName: "KAYBA", unionCode: 51 },
              { unionName: "LAKSHMANPUR", unionCode: 60 },
              { unionName: "NIZAMPUR", unionCode: 69 },
              { unionName: "PUTKHALI", unionCode: 77 },
              { unionName: "SHARSHA", unionCode: 86 },
              { unionName: "ULASHI", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "JHENAIDAH",
        districtCode: 44,
        subdistricts: [
          {
            subdistrictName: "HARINAKUNDA",
            subdistrictCode: 14,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHAYNA", unionCode: 10 },
              { unionName: "CHANDPUR", unionCode: 21 },
              { unionName: "DAULATPUR", unionCode: 31 },
              { unionName: "PHALSI", unionCode: 42 },
              { unionName: "JORADAHA", unionCode: 52 },
              { unionName: "KAPASHATI", unionCode: 63 },
              { unionName: "RAGHUNATHPUR", unionCode: 73 },
              { unionName: "TAHERHUDA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "JHENAIDAH SADAR",
            subdistrictCode: 19,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DOGACHHI", unionCode: 11 },
              { unionName: "FURSANDI", unionCode: 13 },
              { unionName: "GANNA", unionCode: 15 },
              { unionName: "GHORSHAL", unionCode: 21 },
              { unionName: "HALIDHANI", unionCode: 26 },
              { unionName: "HARISHANKARPUR", unionCode: 31 },
              { unionName: "KALICHARANPUR", unionCode: 42 },
              { unionName: "KUMRABARIA", unionCode: 47 },
              { unionName: "MADHUHATI", unionCode: 52 },
              { unionName: "MOHARAJPUR", unionCode: 58 },
              { unionName: "NALDANGA", unionCode: 63 },
              { unionName: "PADMAKAR", unionCode: 68 },
              { unionName: "PAGLAKANAI", unionCode: 73 },
              { unionName: "PORAHATI", unionCode: 79 },
              { unionName: "SAGANNA", unionCode: 84 },
              { unionName: "SADHUHATI", unionCode: 89 },
              { unionName: "SURAT", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KALIGANJ",
            subdistrictCode: 33,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA BAZAR", unionCode: 10 },
              { unionName: "JAMAL", unionCode: 20 },
              { unionName: "SUNDARPUR DURGAPUR", unionCode: 33 },
              { unionName: "KASHTABHANGA", unionCode: 40 },
              { unionName: "KOLA", unionCode: 47 },
              { unionName: "MALIAT", unionCode: 54 },
              { unionName: "NIAMATPUR", unionCode: 61 },
              { unionName: "ROYGRAM", unionCode: 74 },
              { unionName: "RAKHALGACHHI", unionCode: 81 },
              { unionName: "SIMLA ROKONPUR", unionCode: 88 },
              { unionName: "TRILOCHANPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KOTCHANDPUR",
            subdistrictCode: 42,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BALUHAR", unionCode: 13 },
              { unionName: "DORA", unionCode: 27 },
              { unionName: "ELANGI", unionCode: 40 },
              { unionName: "KUSHNA", unionCode: 67 },
              { unionName: "SABDALPUR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "MAHESHPUR",
            subdistrictCode: 71,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AZAMPUR", unionCode: 10 },
              { unionName: "BANSHBARIA", unionCode: 11 },
              { unionName: "FATEHPUR", unionCode: 17 },
              { unionName: "JADABPUR", unionCode: 23 },
              { unionName: "KAZIRBER", unionCode: 29 },
              { unionName: "MANDERBARI", unionCode: 35 },
              { unionName: "NATIMA", unionCode: 53 },
              { unionName: "NEPA", unionCode: 59 },
              { unionName: "PANTHA PARA", unionCode: 65 },
              { unionName: "SHYAMKUR", unionCode: 77 },
              { unionName: "S.K.B.", unionCode: 83 },
              { unionName: "SWARUPPUR", unionCode: 89 }
            ]
          },
          {
            subdistrictName: "SHAILKUPA",
            subdistrictCode: 80,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ABAIPUR", unionCode: 10 },
              { unionName: "BAGURA", unionCode: 12 },
              { unionName: "DHALHARA CHANDRA", unionCode: 18 },
              { unionName: "DIGNAGAR", unionCode: 25 },
              { unionName: "DUDHSAR", unionCode: 31 },
              { unionName: "MANOHARPUR", unionCode: 37 },
              { unionName: "FULHARI", unionCode: 44 },
              { unionName: "HAKIMPUR", unionCode: 50 },
              { unionName: "KANCHERKOL", unionCode: 56 },
              { unionName: "MIRZAPUR", unionCode: 63 },
              { unionName: "NITYANANDAPUR", unionCode: 69 },
              { unionName: "SARUTIA", unionCode: 75 },
              { unionName: "TRIBENI", unionCode: 88 },
              { unionName: "UMEDPUR", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "KHULNA",
        districtCode: 47,
        subdistricts: [
          {
            subdistrictName: "BATIAGHATA",
            subdistrictCode: 12,
            unions: [
              { unionName: "AMIRPUR", unionCode: 11 },
              { unionName: "BALIADANGA", unionCode: 23 },
              { unionName: "BATIAGHATA", unionCode: 35 },
              { unionName: "BHANDERKOTE", unionCode: 47 },
              { unionName: "GANGARAMPUR", unionCode: 59 },
              { unionName: "JALMA", unionCode: 71 },
              { unionName: "SURKHALI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "DACOPE",
            subdistrictCode: 17,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAJUA", unionCode: 10 },
              { unionName: "BANISHANTA", unionCode: 13 },
              { unionName: "LAUDUBI", unionCode: 21 },
              { unionName: "DACOPE", unionCode: 42 },
              { unionName: "KAILASGANJ", unionCode: 52 },
              { unionName: "KAMARKHOLA", unionCode: 63 },
              { unionName: "PANKHALI", unionCode: 69 },
              { unionName: "SUTARKHALI", unionCode: 73 },
              { unionName: "TILDANGA", unionCode: 84 },
              { unionName: "KHULNA RANGE", unionCode: 97 }
            ]
          },
          {
            subdistrictName: "DAULATPUR",
            subdistrictCode: 21,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02 (PART)", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "ARANGHATA", unionCode: 10 }
            ]
          },
          {
            subdistrictName: "DUMURIA",
            subdistrictCode: 30,
            unions: [
              { unionName: "ATLIA UNION", unionCode: 11 },
              { unionName: "BHANDAR PARA", unionCode: 13 },
              { unionName: "DHAMALIA", unionCode: 20 },
              { unionName: "DUMURIA", unionCode: 27 },
              { unionName: "GUTUDIA", unionCode: 33 },
              { unionName: "KHARNIA", unionCode: 40 },
              { unionName: "MAGURKHALI", unionCode: 47 },
              { unionName: "MAGURAGHONA", unionCode: 54 },
              { unionName: "RAGHUNATHPUR", unionCode: 61 },
              { unionName: "RANGPUR", unionCode: 67 },
              { unionName: "RUDAGHARA", unionCode: 74 },
              { unionName: "SAHAS", unionCode: 81 },
              { unionName: "SARAPPUR", unionCode: 88 },
              { unionName: "SOBHANA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DIGHALIA",
            subdistrictCode: 40,
            unions: [
              { unionName: "BARAKPUR", unionCode: 17 },
              { unionName: "DIGHALIA", unionCode: 57 },
              { unionName: "GAZIR HAT", unionCode: 66 },
              { unionName: "SENHATI", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "KHALISHPUR",
            subdistrictCode: 45,
            unions: [
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15(PART)", unionCode: 15 }
            ]
          },
          {
            subdistrictName: "KHAN JAHAN ALI",
            subdistrictCode: 48,
            unions: [
              { unionName: "WARD NO-02 (PART)", unionCode: 2 },
              { unionName: "ATRA GILATALA", unionCode: 19 },
              { unionName: "JUGIPOLE", unionCode: 75 },
              { unionName: "GILATALA CANTT.", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "KHULNA SADAR",
            subdistrictCode: 51,
            unions: [
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-21", unionCode: 21 },
              { unionName: "WARD NO-22", unionCode: 22 },
              { unionName: "WARD NO-23", unionCode: 23 },
              { unionName: "WARD NO-24", unionCode: 24 },
              { unionName: "WARD NO-27", unionCode: 27 },
              { unionName: "WARD NO-28", unionCode: 28 },
              { unionName: "WARD NO-29", unionCode: 29 },
              { unionName: "WARD NO-30", unionCode: 30 },
              { unionName: "WARD NO-31", unionCode: 31 }
            ]
          },
          {
            subdistrictName: "KOYRA",
            subdistrictCode: 53,
            unions: [
              { unionName: "AMADI", unionCode: 10 },
              { unionName: "BAGALI", unionCode: 11 },
              { unionName: "DAKSHIN BEDKASHI", unionCode: 22 },
              { unionName: "KOYRA", unionCode: 55 },
              { unionName: "MAHARAJPUR", unionCode: 72 },
              { unionName: "MAHESHWARIPUR", unionCode: 78 },
              { unionName: "UTTAR BEDKASHI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "PAIKGACHHA",
            subdistrictCode: 64,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHANDKHALI", unionCode: 16 },
              { unionName: "DELUTI", unionCode: 27 },
              { unionName: "GADAIPUR", unionCode: 33 },
              { unionName: "GARUIKHALI", unionCode: 39 },
              { unionName: "HARIDHALI", unionCode: 44 },
              { unionName: "KAPILMUNI", unionCode: 50 },
              { unionName: "LASKAR", unionCode: 61 },
              { unionName: "LATA", unionCode: 67 },
              { unionName: "RARULI", unionCode: 83 },
              { unionName: "SHOLADANA", unionCode: 89 }
            ]
          },
          {
            subdistrictName: "PHULTALA",
            subdistrictCode: 69,
            unions: [
              { unionName: "DAMODAR", unionCode: 38 },
              { unionName: "JAMIRA", unionCode: 57 },
              { unionName: "PHULTALA", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "RUPSA",
            subdistrictCode: 75,
            unions: [
              { unionName: "AIJGANTI", unionCode: 13 },
              { unionName: "GHATBHOGH", unionCode: 27 },
              { unionName: "NAIHATI", unionCode: 54 },
              { unionName: "SREEFALTALA", unionCode: 67 },
              { unionName: "T. S. BAHIRDIA", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "SONADANGA",
            subdistrictCode: 85,
            unions: [
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18", unionCode: 18 },
              { unionName: "WARD NO-19", unionCode: 19 },
              { unionName: "WARD NO-20", unionCode: 20 },
              { unionName: "WARD NO-25", unionCode: 25 },
              { unionName: "WARD NO-26", unionCode: 26 }
            ]
          },
          {
            subdistrictName: "TEROKHADA",
            subdistrictCode: 94,
            unions: [
              { unionName: "AJUGARA", unionCode: 13 },
              { unionName: "BARASAT", unionCode: 27 },
              { unionName: "MADHUPUR", unionCode: 40 },
              { unionName: "SACHIADAH", unionCode: 54 },
              { unionName: "SAGLADAH", unionCode: 67 },
              { unionName: "TEROKHADA", unionCode: 81 }
            ]
          }
        ]
      },
      {
        districtName: "KUSHTIA",
        districtCode: 50,
        subdistricts: [
          {
            subdistrictName: "BHERAMARA",
            subdistrictCode: 15,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAHADURPUR", unionCode: 13 },
              { unionName: "BAHIR CHAR", unionCode: 27 },
              { unionName: "CHANDGRAM", unionCode: 40 },
              { unionName: "DHARAMPUR", unionCode: 54 },
              { unionName: "JUNIADAHA", unionCode: 67 },
              { unionName: "MOKARIMPUR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "DAULATPUR",
            subdistrictCode: 39,
            unions: [
              { unionName: "ADABARIA", unionCode: 11 },
              { unionName: "ARIA", unionCode: 13 },
              { unionName: "BOALIA", unionCode: 20 },
              { unionName: "CHILMARI", unionCode: 27 },
              { unionName: "DAULATPUR", unionCode: 33 },
              { unionName: "HOGALBARIA", unionCode: 40 },
              { unionName: "KHALISHAKUNDI", unionCode: 47 },
              { unionName: "MARICHA", unionCode: 54 },
              { unionName: "MATHURAPUR", unionCode: 61 },
              { unionName: "PEARPUR", unionCode: 67 },
              { unionName: "PHILIPNAGAR", unionCode: 74 },
              { unionName: "PRAYAGPUR", unionCode: 81 },
              { unionName: "RAMKRISHNAPUR", unionCode: 88 },
              { unionName: "REFAYETPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KHOKSA",
            subdistrictCode: 63,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMBARIA", unionCode: 17 },
              { unionName: "BETBARIA", unionCode: 25 },
              { unionName: "GOPAGRAM", unionCode: 31 },
              { unionName: "JANIPUR", unionCode: 37 },
              { unionName: "JAYANTI HAJRA", unionCode: 43 },
              { unionName: "KHOKSA", unionCode: 47 },
              { unionName: "OSMANPUR", unionCode: 57 },
              { unionName: "SAMASPUR", unionCode: 71 },
              { unionName: "SHIMULIA", unionCode: 77 }
            ]
          },
          {
            subdistrictName: "KUMARKHALI",
            subdistrictCode: 71,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAGULAT", unionCode: 16 },
              { unionName: "CHANDPUR", unionCode: 17 },
              { unionName: "CHAPRA", unionCode: 25 },
              { unionName: "JADU BOYRA", unionCode: 34 },
              { unionName: "JAGANNATHPUR", unionCode: 43 },
              { unionName: "KAYA", unionCode: 51 },
              { unionName: "NANDALALPUR", unionCode: 69 },
              { unionName: "PANTI", unionCode: 77 },
              { unionName: "SADAKI", unionCode: 86 },
              { unionName: "SADIPUR", unionCode: 90 },
              { unionName: "SHELAIDAHA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KUSHTIA SADAR",
            subdistrictCode: 79,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 65 },
              { unionName: "WARD NO-14", unionCode: 66 },
              { unionName: "WARD NO-15", unionCode: 67 },
              { unionName: "WARD NO-16", unionCode: 68 },
              { unionName: "WARD NO-17", unionCode: 69 },
              { unionName: "WARD NO-18", unionCode: 70 },
              { unionName: "WARD NO-19", unionCode: 71 },
              { unionName: "WARD NO-20", unionCode: 72 },
              { unionName: "WARD NO-21", unionCode: 73 },
              { unionName: "ABDULPUR", unionCode: 16 },
              { unionName: "AILCHARA", unionCode: 17 },
              { unionName: "ALAMPUR", unionCode: 18 },
              { unionName: "BARAKHADA", unionCode: 25 },
              { unionName: "GOSIND DURGAPUR", unionCode: 31 },
              { unionName: "HARINARAYANPUR", unionCode: 37 },
              { unionName: "HATAS HARIPUR", unionCode: 44 },
              { unionName: "JAGATI", unionCode: 50 },
              { unionName: "JIARAKHI", unionCode: 56 },
              { unionName: "JHAUDIA", unionCode: 63 },
              { unionName: "MAZAMPUR", unionCode: 75 },
              { unionName: "MANOHARDIA", unionCode: 82 },
              { unionName: "PAITKABARI", unionCode: 88 },
              { unionName: "UJANGRAM", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MIRPUR",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMBARIA", unionCode: 13 },
              { unionName: "AMLA", unionCode: 14 },
              { unionName: "BAHALBARIA", unionCode: 21 },
              { unionName: "BARUI PARA", unionCode: 29 },
              { unionName: "CHHATIAN", unionCode: 36 },
              { unionName: "CHITHULIA", unionCode: 43 },
              { unionName: "DUBAIL", unionCode: 50 },
              { unionName: "KURSHA", unionCode: 58 },
              { unionName: "MALIHAD", unionCode: 65 },
              { unionName: "FULBARIA", unionCode: 73 },
              { unionName: "PORADAHA", unionCode: 80 },
              { unionName: "SARDARPUR", unionCode: 87 },
              { unionName: "TALBARIA", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "MAGURA",
        districtCode: 55,
        subdistricts: [
          {
            subdistrictName: "MAGURA SADAR",
            subdistrictCode: 57,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ATHARAKHADA", unionCode: 21 },
              { unionName: "BIRAIL PALITA", unionCode: 23 },
              { unionName: "BAGIA", unionCode: 25 },
              { unionName: "CHAULIA", unionCode: 27 },
              { unionName: "GOPALGRAM", unionCode: 33 },
              { unionName: "HAZIPUR", unionCode: 40 },
              { unionName: "HAZRAPUR", unionCode: 47 },
              { unionName: "JAGDAL", unionCode: 54 },
              { unionName: "KASUNDI", unionCode: 61 },
              { unionName: "KUCHIAMORA", unionCode: 67 },
              { unionName: "MAGHI", unionCode: 74 },
              { unionName: "RAGHAB DAIR", unionCode: 88 },
              { unionName: "SATRUJITPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MOHAMMADPUR",
            subdistrictCode: 66,
            unions: [
              { unionName: "BABUKHALI", unionCode: 10 },
              { unionName: "BALIDIA", unionCode: 21 },
              { unionName: "BINODEPUR", unionCode: 31 },
              { unionName: "DIGHA", unionCode: 42 },
              { unionName: "MOHAMMADPUR", unionCode: 52 },
              { unionName: "NAHATA", unionCode: 63 },
              { unionName: "PALASHBARIA", unionCode: 73 },
              { unionName: "RAJAPUR", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "SHALIKHA",
            subdistrictCode: 85,
            unions: [
              { unionName: "ARPARA", unionCode: 11 },
              { unionName: "BUNAGATI", unionCode: 23 },
              { unionName: "DHANESHWARGATI", unionCode: 35 },
              { unionName: "GANGARAMPUR", unionCode: 47 },
              { unionName: "SHALIKHA", unionCode: 59 },
              { unionName: "SHATAKHALI", unionCode: 71 },
              { unionName: "TALKHARI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "SREEPUR",
            subdistrictCode: 95,
            unions: [
              { unionName: "AMALSAR", unionCode: 10 },
              { unionName: "DARIAPUR", unionCode: 21 },
              { unionName: "GAYESHPUR", unionCode: 31 },
              { unionName: "KADIR PARA", unionCode: 42 },
              { unionName: "NAKOL", unionCode: 52 },
              { unionName: "SABDALPUR", unionCode: 63 },
              { unionName: "SREEKOL", unionCode: 73 },
              { unionName: "SREEPUR", unionCode: 84 }
            ]
          }
        ]
      },
      {
        districtName: "MEHERPUR",
        districtCode: 57,
        subdistricts: [
          {
            subdistrictName: "GANGNI",
            subdistrictCode: 47,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAMANDI", unionCode: 10 },
              { unionName: "DHANKHOLA", unionCode: 21 },
              { unionName: "KATHULI", unionCode: 42 },
              { unionName: "KAZIPUR", unionCode: 52 },
              { unionName: "MATMURA", unionCode: 63 },
              { unionName: "ROYPUR", unionCode: 69 },
              { unionName: "SHAHARBATI", unionCode: 73 },
              { unionName: "SHOLA TAKA", unionCode: 84 },
              { unionName: "TENTULBARIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MUJIB NAGAR",
            subdistrictCode: 60,
            unions: [
              { unionName: "BAGOAN", unionCode: 28 },
              { unionName: "DARIAPUR", unionCode: 47 },
              { unionName: "MAHAJANPUR", unionCode: 76 },
              { unionName: "MONAKHALI", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "MEHERPUR SADAR",
            subdistrictCode: 87,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMDA", unionCode: 14 },
              { unionName: "AMJHUPI", unionCode: 19 },
              { unionName: "BURIPOTA", unionCode: 38 },
              { unionName: "KUTUBPUR", unionCode: 57 },
              { unionName: "PIROJPUR", unionCode: 95 }
            ]
          }
        ]
      },
      {
        districtName: "NARAIL",
        districtCode: 65,
        subdistricts: [
          {
            subdistrictName: "KALIA",
            subdistrictCode: 28,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BABRA HACHLA", unionCode: 13 },
              { unionName: "BORANAL ELIASABAD", unionCode: 14 },
              { unionName: "BAUISENA", unionCode: 15 },
              { unionName: "HAMIDPUR", unionCode: 23 },
              { unionName: "CHANCHARI", unionCode: 31 },
              { unionName: "JOYNAGAR", unionCode: 39 },
              { unionName: "KALABARIA", unionCode: 47 },
              { unionName: "KHASIAL", unionCode: 63 },
              { unionName: "MAULI", unionCode: 71 },
              { unionName: "PAHARDANGA", unionCode: 79 },
              { unionName: "PATCHGRAM", unionCode: 83 },
              { unionName: "PERULI", unionCode: 87 },
              { unionName: "PURULIA", unionCode: 94 },
              { unionName: "SALAMABAD", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "LOHAGARA",
            subdistrictCode: 52,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DIGHALIA", unionCode: 15 },
              { unionName: "ITNA", unionCode: 18 },
              { unionName: "JOYPUR", unionCode: 23 },
              { unionName: "KASHIPUR", unionCode: 31 },
              { unionName: "KOTAKUL", unionCode: 39 },
              { unionName: "LAHURIA", unionCode: 47 },
              { unionName: "LAKSHMIPASHA", unionCode: 55 },
              { unionName: "LOHAGARA", unionCode: 63 },
              { unionName: "MALLIKPUR", unionCode: 71 },
              { unionName: "NALDI", unionCode: 79 },
              { unionName: "NOAGRAM", unionCode: 87 },
              { unionName: "SHALNAGAR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NARAIL SADAR",
            subdistrictCode: 76,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AURIA", unionCode: 11 },
              { unionName: "BANSHGRAM", unionCode: 13 },
              { unionName: "BHADRABILA", unionCode: 20 },
              { unionName: "BICHHALI", unionCode: 27 },
              { unionName: "CHANDIBARPUR", unionCode: 33 },
              { unionName: "HABAKHALI", unionCode: 40 },
              { unionName: "KALORA", unionCode: 47 },
              { unionName: "MAIJ PARA", unionCode: 54 },
              { unionName: "MULIA", unionCode: 61 },
              { unionName: "SAHABAD", unionCode: 74 },
              { unionName: "SHAIKHATI", unionCode: 81 },
              { unionName: "SINGASOLPUR", unionCode: 88 },
              { unionName: "TULARAMPUR", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "SATKHIRA",
        districtCode: 87,
        subdistricts: [
          {
            subdistrictName: "ASSASUNI",
            subdistrictCode: 4,
            unions: [
              { unionName: "ANULIA", unionCode: 15 },
              { unionName: "ASSASUNI", unionCode: 17 },
              { unionName: "BARADAL", unionCode: 25 },
              { unionName: "BUDHHATA", unionCode: 34 },
              { unionName: "DURGAPUR", unionCode: 43 },
              { unionName: "KADAKATI", unionCode: 56 },
              { unionName: "KHAJRA", unionCode: 60 },
              { unionName: "KULLA", unionCode: 69 },
              { unionName: "PRATAP NAGAR", unionCode: 77 },
              { unionName: "SOBHNALI", unionCode: 86 },
              { unionName: "SREEULA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DEBHATA",
            subdistrictCode: 25,
            unions: [
              { unionName: "DEBHATA", unionCode: 15 },
              { unionName: "KULIA", unionCode: 31 },
              { unionName: "NOAPARA", unionCode: 47 },
              { unionName: "PARULIA", unionCode: 63 },
              { unionName: "SAKHIPUR", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "KALAROA",
            subdistrictCode: 43,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHANDANPUR", unionCode: 13 },
              { unionName: "DIARA", unionCode: 15 },
              { unionName: "LANGALJHARA", unionCode: 23 },
              { unionName: "HELATALA", unionCode: 31 },
              { unionName: "JALLABAD", unionCode: 39 },
              { unionName: "JOYNAGAR", unionCode: 47 },
              { unionName: "JOGIKHALI", unionCode: 55 },
              { unionName: "KERAGACHHI", unionCode: 63 },
              { unionName: "KERALKATA", unionCode: 71 },
              { unionName: "KUSHADANGA", unionCode: 79 },
              { unionName: "KAILA", unionCode: 87 },
              { unionName: "SONABARIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KALIGANJ",
            subdistrictCode: 47,
            unions: [
              { unionName: "BHARA SIMLA", unionCode: 13 },
              { unionName: "BISHNUPUR", unionCode: 15 },
              { unionName: "CHAMPAPHUL", unionCode: 23 },
              { unionName: "DAKSHIN SREEPUR", unionCode: 31 },
              { unionName: "DHALBARIA", unionCode: 39 },
              { unionName: "KRISHNANAGAR", unionCode: 47 },
              { unionName: "KUSHLIA", unionCode: 55 },
              { unionName: "MATHURESHPUR", unionCode: 63 },
              { unionName: "MAUTALA", unionCode: 71 },
              { unionName: "NALTA", unionCode: 79 },
              { unionName: "RATANPUR", unionCode: 87 },
              { unionName: "TARALI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SATKHIRA SADAR",
            subdistrictCode: 82,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AGARDARI", unionCode: 13 },
              { unionName: "ALIPUR", unionCode: 16 },
              { unionName: "BAIKARI", unionCode: 20 },
              { unionName: "BALLI", unionCode: 27 },
              { unionName: "BANSHDAHA", unionCode: 33 },
              { unionName: "BHOMRA", unionCode: 40 },
              { unionName: "BRAHMA RAJPUR", unionCode: 47 },
              { unionName: "FINGRI", unionCode: 51 },
              { unionName: "DHULIHAR", unionCode: 54 },
              { unionName: "GHONA", unionCode: 61 },
              { unionName: "JHAUDANGA", unionCode: 67 },
              { unionName: "KUSKHALI", unionCode: 74 },
              { unionName: "LABSA", unionCode: 81 },
              { unionName: "SHIBPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SHYAMNAGAR",
            subdistrictCode: 86,
            unions: [
              { unionName: "ATULIA", unionCode: 11 },
              { unionName: "BHURULIA", unionCode: 15 },
              { unionName: "BURI GOALINI", unionCode: 23 },
              { unionName: "GABURA", unionCode: 31 },
              { unionName: "ISHWARIPUR", unionCode: 39 },
              { unionName: "KAIKHALI", unionCode: 47 },
              { unionName: "KASHIMARI", unionCode: 55 },
              { unionName: "MUNSHIGANJ", unionCode: 63 },
              { unionName: "NURNAGAR", unionCode: 71 },
              { unionName: "PADMA PUKUR", unionCode: 79 },
              { unionName: "RAMJAN NAGAR", unionCode: 87 },
              { unionName: "SHYAMNAGAR", unionCode: 94 },
              { unionName: "SATKHIRA RANGE (FLOATIN)", unionCode: 97 }
            ]
          },
          {
            subdistrictName: "TALA",
            subdistrictCode: 90,
            unions: [
              { unionName: "DHANDIA", unionCode: 11 },
              { unionName: "ISLAMKATI", unionCode: 15 },
              { unionName: "JALALPUR", unionCode: 23 },
              { unionName: "KHALILNAGAR", unionCode: 31 },
              { unionName: "KHALISHKHALI", unionCode: 39 },
              { unionName: "KHESRA", unionCode: 47 },
              { unionName: "KUMIRA", unionCode: 55 },
              { unionName: "MAGURA", unionCode: 63 },
              { unionName: "NAGARGHATA", unionCode: 71 },
              { unionName: "SARULIA", unionCode: 79 },
              { unionName: "TALA", unionCode: 87 },
              { unionName: "TENTULIA", unionCode: 94 }
            ]
          }
        ]
      }
    ]
  },
  {
    divisionName: "RAJSHAHI",
    divisionCode: 50,
    districts: [
      {
        districtName: "BOGRA",
        districtCode: 10,
        subdistricts: [
          {
            subdistrictName: "ADAMDIGHI",
            subdistrictCode: 6,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ADAM DIGHI", unionCode: 13 },
              { unionName: "CHAMPAPUR", unionCode: 27 },
              { unionName: "CHHATIANGRAM", unionCode: 40 },
              { unionName: "KUNDAGRAM", unionCode: 54 },
              { unionName: "NASRATPUR", unionCode: 67 },
              { unionName: "SHANTAHAR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "BOGRA SADAR",
            subdistrictCode: 20,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18", unionCode: 18 },
              { unionName: "WARD NO-19", unionCode: 19 },
              { unionName: "WARD NO-20", unionCode: 20 },
              { unionName: "WARD NO-21 (PART)", unionCode: 21 },
              { unionName: "ERULIA", unionCode: 23 },
              { unionName: "FAPORE", unionCode: 25 },
              { unionName: "GOKUL", unionCode: 34 },
              { unionName: "LAHIRI PARA", unionCode: 47 },
              { unionName: "NAMUJA", unionCode: 60 },
              { unionName: "NISHINDARA", unionCode: 64 },
              { unionName: "NOONGOLA", unionCode: 69 },
              { unionName: "RAJAPUR", unionCode: 73 },
              { unionName: "SHABGRAM", unionCode: 77 },
              { unionName: "SHAKHARIA", unionCode: 82 },
              { unionName: "SEKHERKOLA", unionCode: 86 }
            ]
          },
          {
            subdistrictName: "DHUNAT",
            subdistrictCode: 27,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHANDARBARI", unionCode: 15 },
              { unionName: "CHAUKIBARI", unionCode: 19 },
              { unionName: "CHIKASHI", unionCode: 28 },
              { unionName: "DHUNAT", unionCode: 38 },
              { unionName: "ELANGI", unionCode: 47 },
              { unionName: "GOPALNAGAR", unionCode: 57 },
              { unionName: "GOSAINBARI", unionCode: 66 },
              { unionName: "KALER PARA", unionCode: 76 },
              { unionName: "MATHURAPUR", unionCode: 85 },
              { unionName: "NIMGACHHI", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "DHUPCHANCHIA",
            subdistrictCode: 33,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01", unionCode: 41 },
              { unionName: "WARD NO-02", unionCode: 42 },
              { unionName: "WARD NO-03", unionCode: 43 },
              { unionName: "WARD NO-04", unionCode: 44 },
              { unionName: "WARD NO-05", unionCode: 45 },
              { unionName: "WARD NO-06", unionCode: 46 },
              { unionName: "WARD NO-07", unionCode: 47 },
              { unionName: "CHAMRUL UNION", unionCode: 13 },
              { unionName: "DHUPCHANCHIA", unionCode: 27 },
              { unionName: "GOBINDAPUR", unionCode: 40 },
              { unionName: "GUNAHAR", unionCode: 54 },
              { unionName: "TALORA UNION", unionCode: 67 },
              { unionName: "ZIANAGAR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "GABTALI",
            subdistrictCode: 40,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BALIA DIGHI", unionCode: 11 },
              { unionName: "DAKSHINPARA", unionCode: 17 },
              { unionName: "DURGAHATA", unionCode: 27 },
              { unionName: "GABTALI", unionCode: 33 },
              { unionName: "KAGAIL", unionCode: 47 },
              { unionName: "MAHISHABAN", unionCode: 54 },
              { unionName: "NARUAMALA", unionCode: 61 },
              { unionName: "NASIPUR", unionCode: 67 },
              { unionName: "NEPALTALI", unionCode: 74 },
              { unionName: "RAMESHWARPUR", unionCode: 81 },
              { unionName: "SONARAI", unionCode: 88 }
            ]
          },
          {
            subdistrictName: "KAHALOO",
            subdistrictCode: 54,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-08", unionCode: 48 },
              { unionName: "WARD NO-09", unionCode: 49 },
              { unionName: "BIR KEDAR", unionCode: 13 },
              { unionName: "DURGAPUR", unionCode: 19 },
              { unionName: "JAMGAON", unionCode: 28 },
              { unionName: "KAHALOO", unionCode: 38 },
              { unionName: "KALAI MAJH PARA", unionCode: 47 },
              { unionName: "MALANCHA", unionCode: 57 },
              { unionName: "MURAIL", unionCode: 66 },
              { unionName: "NARAHATTA", unionCode: 76 },
              { unionName: "PAIKAR", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "NANDIGRAM",
            subdistrictCode: 67,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHATGRAM", unionCode: 10 },
              { unionName: "BHATRA", unionCode: 21 },
              { unionName: "BURAIL", unionCode: 31 },
              { unionName: "NANDIGRAM", unionCode: 73 },
              { unionName: "THALTA MAJHGRAM", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "SARIAKANDI",
            subdistrictCode: 81,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHELABARI", unionCode: 11 },
              { unionName: "BOHAIL", unionCode: 12 },
              { unionName: "CHALUABARI", unionCode: 19 },
              { unionName: "CHANDAN BAISHA", unionCode: 25 },
              { unionName: "FULBARI UNION", unionCode: 31 },
              { unionName: "HAT SHERPUR", unionCode: 37 },
              { unionName: "KAMALPUR", unionCode: 44 },
              { unionName: "KAZLA", unionCode: 55 },
              { unionName: "KARNIBARI", unionCode: 56 },
              { unionName: "KUTUBPUR", unionCode: 63 },
              { unionName: "NARCHI", unionCode: 75 },
              { unionName: "SARIAKANDI", unionCode: 88 }
            ]
          },
          {
            subdistrictName: "SHAJAHANPUR",
            subdistrictCode: 85,
            unions: [
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14 (PART) BOGRA PSA", unionCode: 14 },
              { unionName: "WARD NO-21 (PART) BOGRA PSA", unionCode: 21 },
              { unionName: "AMROOL", unionCode: 16 },
              { unionName: "ARIA BAZAR", unionCode: 17 },
              { unionName: "ASEKPUR", unionCode: 18 },
              { unionName: "CHOPINAGAR", unionCode: 19 },
              { unionName: "GOHAIL", unionCode: 30 },
              { unionName: "KHARNA", unionCode: 38 },
              { unionName: "KHOTTA PARA", unionCode: 43 },
              { unionName: "MADLA", unionCode: 51 },
              { unionName: "MAJHIRA", unionCode: 56 },
              { unionName: "SULTANGANJ (PART)", unionCode: 90 },
              { unionName: "BOGRA CANTONMENT", unionCode: 98 }
            ]
          },
          {
            subdistrictName: "SHERPUR",
            subdistrictCode: 88,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHABANIPUR", unionCode: 17 },
              { unionName: "BISHALPUR", unionCode: 19 },
              { unionName: "GARIDAHA", unionCode: 28 },
              { unionName: "KHAMARKANDI", unionCode: 38 },
              { unionName: "KHANPUR", unionCode: 47 },
              { unionName: "KUSUMBI", unionCode: 57 },
              { unionName: "MIRZAPUR", unionCode: 66 },
              { unionName: "SHAH-BANDEGI", unionCode: 77 },
              { unionName: "SHIMABARI", unionCode: 85 },
              { unionName: "SUGHAT", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "SHIBGANJ",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ATMUL", unionCode: 11 },
              { unionName: "BIHAR", unionCode: 15 },
              { unionName: "BURIGANJ", unionCode: 23 },
              { unionName: "DEULI", unionCode: 31 },
              { unionName: "KICHAK", unionCode: 39 },
              { unionName: "MAIDANHATA", unionCode: 47 },
              { unionName: "MAJHIHATTA", unionCode: 55 },
              { unionName: "MOKAMTALA", unionCode: 63 },
              { unionName: "PIRAB", unionCode: 71 },
              { unionName: "ROYNAGAR", unionCode: 79 },
              { unionName: "SAIDPUR", unionCode: 87 },
              { unionName: "SHIBGANJ", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SONATOLA",
            subdistrictCode: 95,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BALUA", unionCode: 10 },
              { unionName: "DIGDAIR", unionCode: 31 },
              { unionName: "JORGACHHA", unionCode: 42 },
              { unionName: "MADHUPUR", unionCode: 52 },
              { unionName: "SONATALA", unionCode: 73 },
              { unionName: "PAKULLA", unionCode: 81 },
              { unionName: "TEKANI CHUKAINA", unionCode: 84 }
            ]
          }
        ]
      },
      {
        districtName: "JOYPURHAT",
        districtCode: 38,
        subdistricts: [
          {
            subdistrictName: "AKKELPUR",
            subdistrictCode: 13,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "GOPINATHPUR", unionCode: 15 },
              { unionName: "RAIKALI", unionCode: 31 },
              { unionName: "RUKINDIPUR", unionCode: 47 },
              { unionName: "SONAMUKHI", unionCode: 63 },
              { unionName: "TILAKPUR", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "JOYPURHAT SADAR",
            subdistrictCode: 47,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMDAI", unionCode: 17 },
              { unionName: "BAMBU", unionCode: 19 },
              { unionName: "BHADSA", unionCode: 28 },
              { unionName: "CHAK BARKAT", unionCode: 32 },
              { unionName: "DHALAHAR", unionCode: 38 },
              { unionName: "DOGACHHI", unionCode: 47 },
              { unionName: "JAMALPUR", unionCode: 66 },
              { unionName: "MOHAMMADABAD", unionCode: 76 },
              { unionName: "PURANAPAIL", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "KALAI",
            subdistrictCode: 58,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AHMMEDABAD", unionCode: 38 },
              { unionName: "MATRAI", unionCode: 66 },
              { unionName: "PUNAT", unionCode: 76 },
              { unionName: "UDAYPUR", unionCode: 85 },
              { unionName: "ZINDARPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "KHETLAL",
            subdistrictCode: 61,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALAMPUR", unionCode: 15 },
              { unionName: "BARATARA", unionCode: 19 },
              { unionName: "BARAIL", unionCode: 28 },
              { unionName: "KHETLAL", unionCode: 47 },
              { unionName: "MAMUDPUR", unionCode: 57 }
            ]
          },
          {
            subdistrictName: "PANCHBIBI",
            subdistrictCode: 74,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AOLAI", unionCode: 10 },
              { unionName: "ATAPUR", unionCode: 21 },
              { unionName: "AYMARASULPUR", unionCode: 31 },
              { unionName: "BAGJANA", unionCode: 42 },
              { unionName: "BALIGHATA", unionCode: 52 },
              { unionName: "DHARANJI", unionCode: 63 },
              { unionName: "KUSUMBA", unionCode: 73 },
              { unionName: "MOHAMADPUR", unionCode: 84 }
            ]
          }
        ]
      },
      {
        districtName: "NAOGAON",
        districtCode: 64,
        subdistricts: [
          {
            subdistrictName: "ATRAI",
            subdistrictCode: 3,
            unions: [
              { unionName: "AHSANGANJ", unionCode: 10 },
              { unionName: "BHOPARA", unionCode: 21 },
              { unionName: "BISHA", unionCode: 31 },
              { unionName: "HATKALU PARA", unionCode: 42 },
              { unionName: "KALIKAPUR", unionCode: 52 },
              { unionName: "MANIARI", unionCode: 63 },
              { unionName: "PANCHUPUR", unionCode: 73 },
              { unionName: "SAHAGOLA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "BADALGACHHI",
            subdistrictCode: 6,
            unions: [
              { unionName: "ADHAIPUR", unionCode: 10 },
              { unionName: "BADALGACHHI", unionCode: 21 },
              { unionName: "BALUBHARA", unionCode: 31 },
              { unionName: "BILASBARI", unionCode: 42 },
              { unionName: "PAHAR PUR", unionCode: 52 },
              { unionName: "KOLA", unionCode: 63 },
              { unionName: "MATHURAPUR", unionCode: 73 },
              { unionName: "MITHAPUR", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "DHAMOIRHAT",
            subdistrictCode: 28,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AGRA DIGUN", unionCode: 10 },
              { unionName: "ALAMPUR", unionCode: 21 },
              { unionName: "ARANAGAR", unionCode: 31 },
              { unionName: "DHAMOIRHAT", unionCode: 42 },
              { unionName: "ISABPUR", unionCode: 52 },
              { unionName: "JAHANPUR", unionCode: 63 },
              { unionName: "KHELNA", unionCode: 77 },
              { unionName: "OMAR", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "MANDA",
            subdistrictCode: 47,
            unions: [
              { unionName: "BHALAIN", unionCode: 12 },
              { unionName: "BHARSO", unionCode: 13 },
              { unionName: "BISHNUPUR", unionCode: 20 },
              { unionName: "GANESHPUR", unionCode: 27 },
              { unionName: "KALIKAPUR", unionCode: 33 },
              { unionName: "KANSO PARA", unionCode: 40 },
              { unionName: "KASHAB", unionCode: 47 },
              { unionName: "KUSUMBA", unionCode: 54 },
              { unionName: "MAINANI", unionCode: 61 },
              { unionName: "MANDA", unionCode: 67 },
              { unionName: "NURULLABAD", unionCode: 74 },
              { unionName: "PARANPUR", unionCode: 81 },
              { unionName: "PRASADPUR", unionCode: 88 },
              { unionName: "TENTULIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MAHADEBPUR",
            subdistrictCode: 50,
            unions: [
              { unionName: "BHIMPUR", unionCode: 15 },
              { unionName: "CHANDAS", unionCode: 19 },
              { unionName: "CHERAGPUR", unionCode: 28 },
              { unionName: "ENAYETPUR", unionCode: 38 },
              { unionName: "HATUR", unionCode: 47 },
              { unionName: "KHAJUR", unionCode: 57 },
              { unionName: "MAHADEBPUR", unionCode: 66 },
              { unionName: "ROYGAON", unionCode: 76 },
              { unionName: "SAFAPUR", unionCode: 85 },
              { unionName: "UTTARGRAM", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "NAOGAON SADAR",
            subdistrictCode: 60,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAKTIARPUR", unionCode: 13 },
              { unionName: "BALIHAR", unionCode: 14 },
              { unionName: "BARSHAIL", unionCode: 21 },
              { unionName: "BOALIA", unionCode: 29 },
              { unionName: "SAILGACHHI", unionCode: 36 },
              { unionName: "CHANDIPUR", unionCode: 43 },
              { unionName: "DUBALHATI", unionCode: 51 },
              { unionName: "HAPANIA", unionCode: 58 },
              { unionName: "HASHAIGHARI", unionCode: 65 },
              { unionName: "KIRTIPUR", unionCode: 73 },
              { unionName: "SEKHERPUR", unionCode: 87 },
              { unionName: "TILAKPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NIAMATPUR",
            subdistrictCode: 69,
            unions: [
              { unionName: "BAHADURPUR", unionCode: 10 },
              { unionName: "BHABICHA", unionCode: 21 },
              { unionName: "CHANDAN NAGAR", unionCode: 31 },
              { unionName: "HAJINAGAR", unionCode: 42 },
              { unionName: "NIAMATPUR", unionCode: 52 },
              { unionName: "PARAIL", unionCode: 63 },
              { unionName: "RASULPUR", unionCode: 73 },
              { unionName: "SREEMANTAPUR", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "PATNITALA",
            subdistrictCode: 75,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AKBARPUR", unionCode: 12 },
              { unionName: "AMAIR", unionCode: 17 },
              { unionName: "DIBAR", unionCode: 25 },
              { unionName: "GHOSHNAGAR", unionCode: 34 },
              { unionName: "KRISHNAPUR", unionCode: 43 },
              { unionName: "MATINDHAR", unionCode: 51 },
              { unionName: "NAZIPUR", unionCode: 60 },
              { unionName: "NIRMAIL", unionCode: 69 },
              { unionName: "PATICHARA", unionCode: 77 },
              { unionName: "PATNITALA", unionCode: 86 },
              { unionName: "SHIHARA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "PORSHA",
            subdistrictCode: 79,
            unions: [
              { unionName: "CHHAOR", unionCode: 15 },
              { unionName: "GANGURIA", unionCode: 23 },
              { unionName: "GHATNAGAR", unionCode: 31 },
              { unionName: "MASIDPUR", unionCode: 47 },
              { unionName: "NITHPUR", unionCode: 55 },
              { unionName: "TENTULIA", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "RANINAGAR",
            subdistrictCode: 85,
            unions: [
              { unionName: "BARGACHHA", unionCode: 10 },
              { unionName: "EKDALA", unionCode: 21 },
              { unionName: "GONA", unionCode: 31 },
              { unionName: "KALIGRAM", unionCode: 42 },
              { unionName: "KASHIMPUR", unionCode: 52 },
              { unionName: "MIRAT", unionCode: 63 },
              { unionName: "PARAIL", unionCode: 73 },
              { unionName: "RANINAGAR", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "SAPAHAR",
            subdistrictCode: 86,
            unions: [
              { unionName: "AIHAI", unionCode: 17 },
              { unionName: "GOALA", unionCode: 39 },
              { unionName: "PATHARI", unionCode: 63 },
              { unionName: "SAPAHAR", unionCode: 71 },
              { unionName: "SHIRANTI", unionCode: 79 },
              { unionName: "TILNA", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "NATORE",
        districtCode: 69,
        subdistricts: [
          {
            subdistrictName: "BAGATIPARA",
            subdistrictCode: 9,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAGATIPARA", unionCode: 19 },
              { unionName: "DAYARAMPUR", unionCode: 38 },
              { unionName: "FHAGURADIAR", unionCode: 42 },
              { unionName: "PANKA", unionCode: 57 },
              { unionName: "JAMNAGAR", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "BARAIGRAM",
            subdistrictCode: 15,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "BARAIGRAM", unionCode: 21 },
              { unionName: "CHANDI", unionCode: 23 },
              { unionName: "GOPALPUR", unionCode: 35 },
              { unionName: "JOARI", unionCode: 47 },
              { unionName: "JONAIL", unionCode: 59 },
              { unionName: "MAJGAON", unionCode: 71 },
              { unionName: "NAGAR", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "GURUDASPUR",
            subdistrictCode: 41,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BIAGHAT", unionCode: 13 },
              { unionName: "CHAPILA", unionCode: 27 },
              { unionName: "DHARABARISHA", unionCode: 40 },
              { unionName: "KHUBJIPUR", unionCode: 60 },
              { unionName: "MOSHINDA", unionCode: 67 },
              { unionName: "NAZIRPUR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "LALPUR",
            subdistrictCode: 44,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ARBAB", unionCode: 17 },
              { unionName: "BILMARIA", unionCode: 19 },
              { unionName: "CHANGDHUPAIL", unionCode: 28 },
              { unionName: "DUARIA", unionCode: 38 },
              { unionName: "DURDURIA", unionCode: 47 },
              { unionName: "ARJUNPUR BOROMHATI", unionCode: 57 },
              { unionName: "ISHWARDI", unionCode: 66 },
              { unionName: "KADAM CHILAN", unionCode: 76 },
              { unionName: "LALPUR", unionCode: 85 },
              { unionName: "WALIA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "NALDANGA",
            subdistrictCode: 55,
            unions: [
              { unionName: "WARD NO-01", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "BIPRA BELGHARIA", unionCode: 23 },
              { unionName: "BRAHMAPUR", unionCode: 26 },
              { unionName: "KHAJURIA", unionCode: 58 },
              { unionName: "MADHNAGAR", unionCode: 73 },
              { unionName: "PIPRUL", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "NATORE SADAR",
            subdistrictCode: 63,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA HARISHPUR", unionCode: 20 },
              { unionName: "CHHATNI", unionCode: 29 },
              { unionName: "DIGHAPATIA", unionCode: 36 },
              { unionName: "HALSA", unionCode: 43 },
              { unionName: "KAFURIA", unionCode: 51 },
              { unionName: "LAKSHMIPUR KHOLABA", unionCode: 65 },
              { unionName: "TEBARIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SINGRA",
            subdistrictCode: 91,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 22 },
              { unionName: "CHAMARI", unionCode: 12 },
              { unionName: "CHHATAR DIGHI", unionCode: 15 },
              { unionName: "CHAUGRAM", unionCode: 23 },
              { unionName: "DAHIA", unionCode: 31 },
              { unionName: "HATIANDAHA", unionCode: 39 },
              { unionName: "ITALY", unionCode: 47 },
              { unionName: "KALAM", unionCode: 55 },
              { unionName: "LALORE", unionCode: 63 },
              { unionName: "RAMANANDA KHAJURA", unionCode: 71 },
              { unionName: "SHERKUL", unionCode: 79 },
              { unionName: "TAJPUR", unionCode: 87 },
              { unionName: "SUKASH", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "CHAPAI NABABGANJ",
        districtCode: 70,
        subdistricts: [
          {
            subdistrictName: "BHOLAHAT",
            subdistrictCode: 18,
            unions: [
              { unionName: "BHOLAHAT", unionCode: 18 },
              { unionName: "DALDALI", unionCode: 37 },
              { unionName: "GOHALBARI", unionCode: 56 },
              { unionName: "JAMBARIA", unionCode: 75 }
            ]
          },
          {
            subdistrictName: "GOMASTAPUR",
            subdistrictCode: 37,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALINAGAR", unionCode: 10 },
              { unionName: "BHANGABARIA", unionCode: 21 },
              { unionName: "BOALIA", unionCode: 31 },
              { unionName: "CHOWDALA", unionCode: 42 },
              { unionName: "GOMASTAPUR", unionCode: 52 },
              { unionName: "PARBATIPUR", unionCode: 63 },
              { unionName: "RADHANAGAR", unionCode: 73 },
              { unionName: "ROHANPUR", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "NACHOLE",
            subdistrictCode: 56,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "FATEHPUR", unionCode: 19 },
              { unionName: "KASBA UNION", unionCode: 38 },
              { unionName: "NACHOLE", unionCode: 57 },
              { unionName: "NIZAMPUR", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "CHAPAI NABABGANJ SADAR",
            subdistrictCode: 66,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "ALATULI", unionCode: 17 },
              { unionName: "BALIDANGA", unionCode: 18 },
              { unionName: "BARAGHARIA", unionCode: 19 },
              { unionName: "CHAR ANUPNAGAR", unionCode: 20 },
              { unionName: "CHAR BAGDANGA", unionCode: 22 },
              { unionName: "DEBINAGAR", unionCode: 27 },
              { unionName: "GOBRATALA", unionCode: 33 },
              { unionName: "ISLAMPUR", unionCode: 39 },
              { unionName: "JHILIM", unionCode: 44 },
              { unionName: "MAHARAJPUR", unionCode: 55 },
              { unionName: "NARAYANPUR", unionCode: 67 },
              { unionName: "RANIHATI", unionCode: 83 },
              { unionName: "SHAHJAHANPUR", unionCode: 89 },
              { unionName: "SUNDARPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SHIBGANJ",
            subdistrictCode: 88,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BINODPUR", unionCode: 10 },
              { unionName: "CHAK KIRTI", unionCode: 11 },
              { unionName: "DAIPUKURIA", unionCode: 17 },
              { unionName: "DHAINAGAR", unionCode: 23 },
              { unionName: "DURLABHPUR", unionCode: 29 },
              { unionName: "GHORAPAKHIA", unionCode: 35 },
              { unionName: "KANSAT", unionCode: 41 },
              { unionName: "MOBARAKPUR", unionCode: 47 },
              { unionName: "MANAKOSA", unionCode: 53 },
              { unionName: "NAYA NAOBHANGA", unionCode: 59 },
              { unionName: "PANKA", unionCode: 65 },
              { unionName: "SATRUJITPUR", unionCode: 71 },
              { unionName: "SHAHBAJPUR", unionCode: 77 },
              { unionName: "SHYAMPUR", unionCode: 89 },
              { unionName: "UZIRPUR", unionCode: 95 }
            ]
          }
        ]
      },
      {
        districtName: "PABNA",
        districtCode: 76,
        subdistricts: [
          {
            subdistrictName: "ATGHARIA",
            subdistrictCode: 5,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHANDBA", unionCode: 15 },
              { unionName: "DEBOTTAR", unionCode: 31 },
              { unionName: "EKDANTA", unionCode: 47 },
              { unionName: "LAKSHMIPUR", unionCode: 63 },
              { unionName: "MAJH PARA", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "BERA",
            subdistrictCode: 16,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "CHAKLA", unionCode: 15 },
              { unionName: "DHALAR CHAR", unionCode: 21 },
              { unionName: "HATURIA NAKALIA", unionCode: 31 },
              { unionName: "JATSAKHNI", unionCode: 42 },
              { unionName: "KYTOLA", unionCode: 47 },
              { unionName: "MASUNDIA", unionCode: 52 },
              { unionName: "NUTAN BHARENGA", unionCode: 63 },
              { unionName: "PURAN BHARENGA", unionCode: 73 },
              { unionName: "RUPPUR", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "BHANGURA",
            subdistrictCode: 19,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ASHTA MANISHA", unionCode: 15 },
              { unionName: "BHANGURA", unionCode: 31 },
              { unionName: "DIL PASAR", unionCode: 47 },
              { unionName: "KHAN MARICH", unionCode: 63 },
              { unionName: "MANDATOSH", unionCode: 65 },
              { unionName: "PARBHANGURIA", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "CHATMOHAR",
            subdistrictCode: 22,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHHAIKHOLA", unionCode: 11 },
              { unionName: "BILCHALAN", unionCode: 13 },
              { unionName: "DANTHIA BAMANGRAM", unionCode: 25 },
              { unionName: "FAILJANA", unionCode: 34 },
              { unionName: "GUNAIGACHHA", unionCode: 43 },
              { unionName: "HANDIAL", unionCode: 51 },
              { unionName: "HARIPUR", unionCode: 60 },
              { unionName: "MOTHURAPUR", unionCode: 70 },
              { unionName: "MULGRAM", unionCode: 77 },
              { unionName: "NIMAICHARA", unionCode: 86 },
              { unionName: "PARSHADANGA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "FARIDPUR",
            subdistrictCode: 33,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BANWARINAGAR", unionCode: 10 },
              { unionName: "BRI-LAHIRIBARI", unionCode: 31 },
              { unionName: "DEMRA", unionCode: 42 },
              { unionName: "FARIDPUR", unionCode: 52 },
              { unionName: "HADAL", unionCode: 63 },
              { unionName: "PUNGALI", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "ISHWARDI",
            subdistrictCode: 39,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DASHURIA", unionCode: 21 },
              { unionName: "LAKSHMIKUNDI", unionCode: 31 },
              { unionName: "MULADULI", unionCode: 42 },
              { unionName: "PAKSHI", unionCode: 52 },
              { unionName: "SAHAPUR", unionCode: 63 },
              { unionName: "SILIMPUR", unionCode: 73 },
              { unionName: "SARA", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "PABNA SADAR",
            subdistrictCode: 55,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "ATAIKOLA", unionCode: 16 },
              { unionName: "BHARARA", unionCode: 17 },
              { unionName: "CHAR TARAPUR", unionCode: 25 },
              { unionName: "DAPUNIA", unionCode: 34 },
              { unionName: "DOGACHHI", unionCode: 43 },
              { unionName: "GAYESHPUR", unionCode: 51 },
              { unionName: "HEMAYETPUR", unionCode: 60 },
              { unionName: "MALANCHI", unionCode: 69 },
              { unionName: "MALIGACHHA", unionCode: 77 },
              { unionName: "SADULLAHPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SANTHIA",
            subdistrictCode: 72,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ATAIKOLA", unionCode: 15 },
              { unionName: "BHULBARIA", unionCode: 17 },
              { unionName: "DHOPADAHA", unionCode: 25 },
              { unionName: "DHULAURI", unionCode: 34 },
              { unionName: "GAURIGRAM", unionCode: 43 },
              { unionName: "KARANJA", unionCode: 51 },
              { unionName: "KASHINATHPUR", unionCode: 60 },
              { unionName: "KHATU PARA", unionCode: 69 },
              { unionName: "NAGDEMRA", unionCode: 77 },
              { unionName: "NANDANPUR", unionCode: 86 }
            ]
          },
          {
            subdistrictName: "SUJANAGAR",
            subdistrictCode: 83,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AHAMMEDPUR", unionCode: 17 },
              { unionName: "DULAI", unionCode: 19 },
              { unionName: "HATKHALI", unionCode: 28 },
              { unionName: "MANIKHAT", unionCode: 38 },
              { unionName: "NAZIRGANJ", unionCode: 47 },
              { unionName: "RANINAGAR", unionCode: 57 },
              { unionName: "SAGARKANDI", unionCode: 66 },
              { unionName: "SATBARIA", unionCode: 76 },
              { unionName: "BHAYNA", unionCode: 85 },
              { unionName: "TANTIBANDA", unionCode: 95 }
            ]
          }
        ]
      },
      {
        districtName: "RAJSHAHI",
        districtCode: 81,
        subdistricts: [
          {
            subdistrictName: "BAGHA",
            subdistrictCode: 10,
            unions: [
              { unionName: "WARD NO-01 (ARANI)", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ARANI", unionCode: 21 },
              { unionName: "BAJUBAGHA", unionCode: 22 },
              { unionName: "BAUSA", unionCode: 23 },
              { unionName: "CHAKRAJAPUR", unionCode: 35 },
              { unionName: "GARGARI", unionCode: 55 },
              { unionName: "MANIGRAM", unionCode: 63 },
              { unionName: "PAKURIA", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "BAGHMARA",
            subdistrictCode: 12,
            unions: [
              { unionName: "WARD NO-01 (BHABANIGONJ)", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01 (TAHIRPUR)", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "AUCH PARA", unionCode: 20 },
              { unionName: "BARA BIHANALI", unionCode: 22 },
              { unionName: "BASU PARA", unionCode: 24 },
              { unionName: "DWIPPUR", unionCode: 31 },
              { unionName: "GOALKANDI", unionCode: 37 },
              { unionName: "GOBINDA PARA", unionCode: 44 },
              { unionName: "GANIPUR", unionCode: 50 },
              { unionName: "HAMIR KUTSHA", unionCode: 56 },
              { unionName: "JHIKRA", unionCode: 63 },
              { unionName: "JOGI PARA", unionCode: 69 },
              { unionName: "KACHHARI KAYALI PARA", unionCode: 72 },
              { unionName: "MARIA", unionCode: 75 },
              { unionName: "NARDAS", unionCode: 82 },
              { unionName: "SONADANGA", unionCode: 85 },
              { unionName: "SREEPUR", unionCode: 88 },
              { unionName: "SUBHADANGA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BOALIA",
            subdistrictCode: 22,
            unions: [
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10 (PART)", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14 (PART)", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-18 (PART)", unionCode: 18 },
              { unionName: "WARD NO-19", unionCode: 19 },
              { unionName: "WARD NO-20", unionCode: 20 },
              { unionName: "WARD NO-21", unionCode: 21 },
              { unionName: "WARD NO-22", unionCode: 22 },
              { unionName: "WARD NO-23", unionCode: 23 },
              { unionName: "WARD NO-24", unionCode: 24 },
              { unionName: "WARD NO-25", unionCode: 25 },
              { unionName: "WARD NO-26", unionCode: 26 },
              { unionName: "WARD NO-27 (PART)", unionCode: 27 }
            ]
          },
          {
            subdistrictName: "CHARGHAT",
            subdistrictCode: 25,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHAYA LAKSHMIPUR", unionCode: 31 },
              { unionName: "CHARGHAT", unionCode: 39 },
              { unionName: "YUSUFPUR", unionCode: 47 },
              { unionName: "NIMPARA", unionCode: 71 },
              { unionName: "SALUA", unionCode: 87 },
              { unionName: "SARDAH", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "DURGAPUR",
            subdistrictCode: 31,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DELUABARI", unionCode: 11 },
              { unionName: "DHARMAPUR (PANANAGAR)", unionCode: 23 },
              { unionName: "JHALUKA", unionCode: 35 },
              { unionName: "JOYNAGAR", unionCode: 47 },
              { unionName: "KISMAT GANKAIR", unionCode: 59 },
              { unionName: "MARIA", unionCode: 71 },
              { unionName: "NOAPARA", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "GODAGARI",
            subdistrictCode: 34,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01 (KAKANHAT)", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "BASUDEBPUR", unionCode: 22 },
              { unionName: "CHAR ASHARIADAHA", unionCode: 25 },
              { unionName: "DEOPARA", unionCode: 28 },
              { unionName: "GODAGARI", unionCode: 38 },
              { unionName: "GOGRAM", unionCode: 47 },
              { unionName: "MATIKATA", unionCode: 57 },
              { unionName: "MOHANPUR", unionCode: 66 },
              { unionName: "PAKRI", unionCode: 76 },
              { unionName: "RISHIKUL", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "MATIHAR",
            subdistrictCode: 40,
            unions: [
              { unionName: "WARD NO-28", unionCode: 28 },
              { unionName: "WARD NO-29", unionCode: 29 },
              { unionName: "WARD NO-30", unionCode: 30 }
            ]
          },
          {
            subdistrictName: "MOHANPUR",
            subdistrictCode: 53,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAKSHIMAIL", unionCode: 13 },
              { unionName: "DHURAIL", unionCode: 27 },
              { unionName: "GHASIGRAM", unionCode: 40 },
              { unionName: "JAHANABAD", unionCode: 54 },
              { unionName: "MAUGACHHI", unionCode: 67 },
              { unionName: "ROYGHATI", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "PABA",
            subdistrictCode: 72,
            unions: [
              { unionName: "WARD NO-01 (KATAKHALI)", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01 (NOAHATA)", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "BARAGACHHI", unionCode: 27 },
              { unionName: "DAMKUR", unionCode: 35 },
              { unionName: "DARSHAN PARA", unionCode: 43 },
              { unionName: "HARAGRAM", unionCode: 51 },
              { unionName: "HARIAN", unionCode: 54 },
              { unionName: "HARIPUR", unionCode: 61 },
              { unionName: "HUJURI PARA", unionCode: 65 },
              { unionName: "PARILA", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "PUTHIA",
            subdistrictCode: 82,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BANESHWAR", unionCode: 13 },
              { unionName: "BELPUKURIA", unionCode: 27 },
              { unionName: "BHALUKGACHHI", unionCode: 40 },
              { unionName: "JEOPARA", unionCode: 54 },
              { unionName: "PUTHIA", unionCode: 67 },
              { unionName: "SILMARIA", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "RAJPARA",
            subdistrictCode: 85,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-10 (PART)", unionCode: 10 }
            ]
          },
          {
            subdistrictName: "SHAH MAKHDUM",
            subdistrictCode: 90,
            unions: [
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18 (PART)", unionCode: 18 }
            ]
          },
          {
            subdistrictName: "TANORE",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01 (MUNDUMALA)", unionCode: 11 },
              { unionName: "WARD NO-02", unionCode: 12 },
              { unionName: "WARD NO-03", unionCode: 13 },
              { unionName: "WARD NO-04", unionCode: 14 },
              { unionName: "WARD NO-05", unionCode: 15 },
              { unionName: "WARD NO-06", unionCode: 16 },
              { unionName: "WARD NO-07", unionCode: 17 },
              { unionName: "WARD NO-08", unionCode: 18 },
              { unionName: "WARD NO-09", unionCode: 19 },
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BADHAIR", unionCode: 27 },
              { unionName: "CHANDURIA", unionCode: 40 },
              { unionName: "KALMA", unionCode: 54 },
              { unionName: "KAMARGAON", unionCode: 57 },
              { unionName: "PACHANDAR", unionCode: 70 },
              { unionName: "SARANJAI", unionCode: 77 },
              { unionName: "TALANDA", unionCode: 81 }
            ]
          }
        ]
      },
      {
        districtName: "SIRAJGANJ",
        districtCode: 88,
        subdistricts: [
          {
            subdistrictName: "BELKUCHI",
            subdistrictCode: 11,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA DHUL", unionCode: 13 },
              { unionName: "BELKUCHI", unionCode: 27 },
              { unionName: "BHANGABARI", unionCode: 40 },
              { unionName: "DAULATPUR", unionCode: 54 },
              { unionName: "DHUKARIA BERA", unionCode: 67 },
              { unionName: "RAJAPUR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "CHAUHALI",
            subdistrictCode: 27,
            unions: [
              { unionName: "BAGUTIA", unionCode: 21 },
              { unionName: "GHARJAN", unionCode: 23 },
              { unionName: "KHAS KAULIA", unionCode: 27 },
              { unionName: "KHAS PUKURIA", unionCode: 31 },
              { unionName: "OMARPUR", unionCode: 37 },
              { unionName: "SADIA CHANDPUR", unionCode: 47 },
              { unionName: "STHAL", unionCode: 71 }
            ]
          },
          {
            subdistrictName: "KAMARKHANDA",
            subdistrictCode: 44,
            unions: [
              { unionName: "BHADRAGHAT", unionCode: 19 },
              { unionName: "JAMTAIL", unionCode: 38 },
              { unionName: "JHAWAIL", unionCode: 57 },
              { unionName: "ROY DAULATPUR", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "KAZIPUR",
            subdistrictCode: 50,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHAR GIRISH", unionCode: 15 },
              { unionName: "CHALITADANGA", unionCode: 17 },
              { unionName: "GANDAIL", unionCode: 25 },
              { unionName: "KAZIPUR", unionCode: 34 },
              { unionName: "KHAS RAJBARI", unionCode: 43 },
              { unionName: "MAIJBARI", unionCode: 51 },
              { unionName: "MANSUR NAGAR", unionCode: 56 },
              { unionName: "NATUAR PARA", unionCode: 60 },
              { unionName: "NISHCHINTAPUR", unionCode: 69 },
              { unionName: "SONAMUKHI", unionCode: 77 },
              { unionName: "SUBHAGACHHA", unionCode: 86 },
              { unionName: "TEKANI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "ROYGANJ",
            subdistrictCode: 61,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BRAHMAGACHHA", unionCode: 13 },
              { unionName: "CHANDAIKONA", unionCode: 19 },
              { unionName: "DHAMAINAGAR", unionCode: 28 },
              { unionName: "DHANGARA", unionCode: 38 },
              { unionName: "DHUBIL", unionCode: 47 },
              { unionName: "GHURKA", unionCode: 57 },
              { unionName: "NALKA", unionCode: 66 },
              { unionName: "PANGASHI", unionCode: 76 },
              { unionName: "SONAKHARA", unionCode: 85 }
            ]
          },
          {
            subdistrictName: "SHAHJADPUR",
            subdistrictCode: 67,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BELTAIL", unionCode: 12 },
              { unionName: "GALA", unionCode: 14 },
              { unionName: "GARADAHA", unionCode: 21 },
              { unionName: "JALALPUR", unionCode: 29 },
              { unionName: "KAIJURI", unionCode: 36 },
              { unionName: "KAYEMPUR", unionCode: 43 },
              { unionName: "KHUKNI", unionCode: 51 },
              { unionName: "NARINA", unionCode: 58 },
              { unionName: "PORJANA", unionCode: 65 },
              { unionName: "POTAJIA", unionCode: 73 },
              { unionName: "RUPABATI", unionCode: 80 },
              { unionName: "HABIBULLANAGAR", unionCode: 87 },
              { unionName: "SONATANI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "SIRAJGANJ SADAR",
            subdistrictCode: 78,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "BAGHBATI", unionCode: 16 },
              { unionName: "BAHULI", unionCode: 17 },
              { unionName: "KALIA HARIPUR", unionCode: 25 },
              { unionName: "KAOAKOLA", unionCode: 34 },
              { unionName: "KHOKSABARI", unionCode: 43 },
              { unionName: "MECHHRA", unionCode: 51 },
              { unionName: "RATANKANDI", unionCode: 60 },
              { unionName: "SAIDABAD", unionCode: 69 },
              { unionName: "SHIALKUL", unionCode: 77 },
              { unionName: "CHHANGACHHA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "TARASH",
            subdistrictCode: 89,
            unions: [
              { unionName: "BARUHAS", unionCode: 10 },
              { unionName: "DESHIGRAM", unionCode: 21 },
              { unionName: "MADHAINAGAR", unionCode: 31 },
              { unionName: "MAGURA BINOD", unionCode: 42 },
              { unionName: "NAOGAON", unionCode: 52 },
              { unionName: "SAGUNA", unionCode: 63 },
              { unionName: "TALAM", unionCode: 73 },
              { unionName: "TARASH", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "ULLAH PARA",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BANGALA", unionCode: 12 },
              { unionName: "BARAHAR", unionCode: 14 },
              { unionName: "BARA PANGASHI", unionCode: 21 },
              { unionName: "DURGANAGAR", unionCode: 29 },
              { unionName: "HATIKUMRUL", unionCode: 36 },
              { unionName: "KOYRA", unionCode: 39 },
              { unionName: "MOHANPUR", unionCode: 43 },
              { unionName: "PANCHA KRUSHI", unionCode: 51 },
              { unionName: "PURNIMAGANTI", unionCode: 58 },
              { unionName: "RAMKRISHNAPUR", unionCode: 65 },
              { unionName: "SALANGA", unionCode: 73 },
              { unionName: "SALAP", unionCode: 80 },
              { unionName: "UDHUNIA", unionCode: 87 },
              { unionName: "ULLAH PARA", unionCode: 94 }
            ]
          }
        ]
      }
    ]
  },
  {
    divisionName: "BARISAL",
    divisionCode: 10,
    districts: [
      {
        districtName: "BARGUNA",
        districtCode: 4,
        subdistricts: [
          {
            subdistrictName: "AMTALI",
            subdistrictCode: 9,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMTALI", unionCode: 13 },
              { unionName: "ARPANGASHIA", unionCode: 15 },
              { unionName: "ATHARAGASHIA", unionCode: 23 },
              { unionName: "CHOWRA", unionCode: 47 },
              { unionName: "GULISAKHALI", unionCode: 63 },
              { unionName: "HALDIA", unionCode: 71 },
              { unionName: "KUKUA", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "BAMNA",
            subdistrictCode: 19,
            unions: [
              { unionName: "BAMNA", unionCode: 23 },
              { unionName: "BUKABUNIA", unionCode: 47 },
              { unionName: "DAUATALA", unionCode: 71 },
              { unionName: "RAMNA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "BARGUNA SADAR",
            subdistrictCode: 28,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AYLA PATAKATA", unionCode: 17 },
              { unionName: "BADARKHALI", unionCode: 19 },
              { unionName: "BARGUNA", unionCode: 28 },
              { unionName: "BURIR CHAR", unionCode: 38 },
              { unionName: "DHALUA", unionCode: 47 },
              { unionName: "PHULJHURY", unionCode: 57 },
              { unionName: "GAURICHANNA", unionCode: 66 },
              { unionName: "KEORABUNIA", unionCode: 76 },
              { unionName: "M.BALIATALI", unionCode: 85 },
              { unionName: "NALTONA", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "BETAGI",
            subdistrictCode: 47,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BETAGI", unionCode: 11 },
              { unionName: "BIBICHINI", unionCode: 23 },
              { unionName: "BURA MAZUMDAR", unionCode: 35 },
              { unionName: "HOSNABAD", unionCode: 47 },
              { unionName: "KAZIRABAD", unionCode: 59 },
              { unionName: "MOKAMIA", unionCode: 71 },
              { unionName: "SARISHAMURI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "PATHARGHATA",
            subdistrictCode: 85,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "CHAR DUANI", unionCode: 11 },
              { unionName: "KAKCHIRA", unionCode: 23 },
              { unionName: "KALMEGHA", unionCode: 35 },
              { unionName: "KANTHALTALI", unionCode: 47 },
              { unionName: "NACHNA PARA", unionCode: 59 },
              { unionName: "PATHARGHATA", unionCode: 71 },
              { unionName: "RAIHANPUR", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "TALTALI",
            subdistrictCode: 90,
            unions: [
              { unionName: "BARABAGI", unionCode: 39 },
              { unionName: "CHHOTA BAGI", unionCode: 43 },
              { unionName: "KARAIBARIA", unionCode: 79 },
              { unionName: "NISHANBARIA", unionCode: 91 },
              { unionName: "PONCHA KORALIA", unionCode: 94 },
              { unionName: "SARIKKHALI", unionCode: 95 },
              { unionName: "SONAKATA", unionCode: 96 }
            ]
          }
        ]
      },
      {
        districtName: "BARISAL",
        districtCode: 6,
        subdistricts: [
          {
            subdistrictName: "AGAILJHARA",
            subdistrictCode: 2,
            unions: [
              { unionName: "BAGDHA", unionCode: 13 },
              { unionName: "BAKAL", unionCode: 15 },
              { unionName: "GAILA", unionCode: 47 },
              { unionName: "RAJIHER", unionCode: 79 },
              { unionName: "RATNAPUR", unionCode: 87 }
            ]
          },
          {
            subdistrictName: "BABUGANJ",
            subdistrictCode: 3,
            unions: [
              { unionName: "CHANDPASHA", unionCode: 27 },
              { unionName: "DEHERGATI", unionCode: 40 },
              { unionName: "KEDARPUR", unionCode: 54 },
              { unionName: "MADHAB PASHA", unionCode: 67 },
              { unionName: "RAHMATPUR", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "BAKERGANJ",
            subdistrictCode: 7,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHAR PASHA", unionCode: 12 },
              { unionName: "CHARADI", unionCode: 13 },
              { unionName: "CHAR AMADDI", unionCode: 20 },
              { unionName: "DARIAL", unionCode: 27 },
              { unionName: "DUDHAL", unionCode: 33 },
              { unionName: "DURGA PASHA", unionCode: 40 },
              { unionName: "FARIDPUR", unionCode: 47 },
              { unionName: "GARURIA", unionCode: 54 },
              { unionName: "KABAI", unionCode: 61 },
              { unionName: "KALASKATI", unionCode: 67 },
              { unionName: "NALUA", unionCode: 74 },
              { unionName: "NIAMATI", unionCode: 81 },
              { unionName: "PADRI SHIBPUR", unionCode: 88 },
              { unionName: "RANGASREE", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BANARI PARA",
            subdistrictCode: 10,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BANARIPARA", unionCode: 10 },
              { unionName: "BAISARI", unionCode: 21 },
              { unionName: "BISARKANDI", unionCode: 31 },
              { unionName: "CHAKHAR", unionCode: 42 },
              { unionName: "ILUHAR", unionCode: 52 },
              { unionName: "SALIA BAKPUR", unionCode: 63 },
              { unionName: "SAIDKATI", unionCode: 73 },
              { unionName: "UDAYKATI", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "GAURNADI",
            subdistrictCode: 32,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARTHI", unionCode: 23 },
              { unionName: "BATAJORE", unionCode: 31 },
              { unionName: "CHANDSHI", unionCode: 38 },
              { unionName: "KHANJAPUR", unionCode: 55 },
              { unionName: "MAHILARA", unionCode: 63 },
              { unionName: "NALCHIRA", unionCode: 71 },
              { unionName: "SARIKAL", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "HIZLA",
            subdistrictCode: 36,
            unions: [
              { unionName: "BARA JALIA", unionCode: 13 },
              { unionName: "DHULKHOLA", unionCode: 27 },
              { unionName: "GUABARIA", unionCode: 54 },
              { unionName: "HARINATHPUR", unionCode: 67 },
              { unionName: "HIZLA GAURABDI", unionCode: 81 },
              { unionName: "MEMANIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BARISAL SADAR (KOTWALI)",
            subdistrictCode: 51,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "WARD NO-13", unionCode: 13 },
              { unionName: "WARD NO-14", unionCode: 14 },
              { unionName: "WARD NO-15", unionCode: 15 },
              { unionName: "WARD NO-16", unionCode: 16 },
              { unionName: "WARD NO-17", unionCode: 17 },
              { unionName: "WARD NO-18", unionCode: 18 },
              { unionName: "WARD NO-19", unionCode: 19 },
              { unionName: "WARD NO-20", unionCode: 20 },
              { unionName: "WARD NO-21", unionCode: 21 },
              { unionName: "WARD NO-22", unionCode: 22 },
              { unionName: "WARD NO-23", unionCode: 23 },
              { unionName: "WARD NO-24", unionCode: 24 },
              { unionName: "WARD NO-25", unionCode: 25 },
              { unionName: "WARD NO-26", unionCode: 26 },
              { unionName: "WARD NO-27", unionCode: 27 },
              { unionName: "WARD NO-28", unionCode: 28 },
              { unionName: "WARD NO-29", unionCode: 29 },
              { unionName: "WARD NO-30", unionCode: 30 },
              { unionName: "CHANDPURA", unionCode: 32 },
              { unionName: "CHANDRA MOHAN", unionCode: 33 },
              { unionName: "CHAR BARIA", unionCode: 34 },
              { unionName: "CHAR KOWA", unionCode: 43 },
              { unionName: "CHAR MONAI", unionCode: 51 },
              { unionName: "JAGUA", unionCode: 60 },
              { unionName: "KASHIPUR", unionCode: 69 },
              { unionName: "ROY PASHA KARAPUR", unionCode: 77 },
              { unionName: "SHAYESTABAD", unionCode: 86 },
              { unionName: "TUNGIBARIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MEHENDIGANJ",
            subdistrictCode: 62,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALIMABAD", unionCode: 13 },
              { unionName: "ANDHAR MANIK", unionCode: 15 },
              { unionName: "BHASAN CHAR", unionCode: 23 },
              { unionName: "BIDYANANDAPUR", unionCode: 31 },
              { unionName: "CHANDPUR", unionCode: 39 },
              { unionName: "GOBINDAPUR", unionCode: 40 },
              { unionName: "CHAR EKKARIA", unionCode: 47 },
              { unionName: "CHAR GOPALPUR", unionCode: 55 },
              { unionName: "DARI CHAR KHAJURIA", unionCode: 63 },
              { unionName: "JANGALIA", unionCode: 71 },
              { unionName: "JOYNAGAR", unionCode: 75 },
              { unionName: "LATA", unionCode: 79 },
              { unionName: "MEHENDIGANJ", unionCode: 87 },
              { unionName: "SREEPUR", unionCode: 90 },
              { unionName: "ULANIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "MULADI",
            subdistrictCode: 69,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BATAMARA", unionCode: 11 },
              { unionName: "CHAR KALEKHAN", unionCode: 23 },
              { unionName: "GACHHUA", unionCode: 35 },
              { unionName: "KAZIR CHAR", unionCode: 47 },
              { unionName: "MULADI", unionCode: 59 },
              { unionName: "NAZIRPUR", unionCode: 71 },
              { unionName: "SAFIPUR", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "WAZIRPUR",
            subdistrictCode: 94,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BAMRAIL", unionCode: 10 },
              { unionName: "BARA KOTHA", unionCode: 21 },
              { unionName: "GUTHIA", unionCode: 31 },
              { unionName: "HARTA", unionCode: 42 },
              { unionName: "JALLA", unionCode: 52 },
              { unionName: "OTRA", unionCode: 63 },
              { unionName: "SATLA", unionCode: 73 },
              { unionName: "SHIKARPUR", unionCode: 84 },
              { unionName: "SHOLAK", unionCode: 94 }
            ]
          }
        ]
      },
      {
        districtName: "BHOLA",
        districtCode: 9,
        subdistricts: [
          {
            subdistrictName: "BHOLA SADAR",
            subdistrictCode: 18,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ALINAGAR", unionCode: 12 },
              { unionName: "BAPTA", unionCode: 14 },
              { unionName: "CHAR SAMAIA", unionCode: 21 },
              { unionName: "CHAR SHIBPUR", unionCode: 29 },
              { unionName: "DHANIA", unionCode: 36 },
              { unionName: "ILLISHA", unionCode: 51 },
              { unionName: "PASCHIM ILISHA", unionCode: 55 },
              { unionName: "KACHIA", unionCode: 58 },
              { unionName: "UTTAR DIGHALDI", unionCode: 65 },
              { unionName: "RAJAPUR", unionCode: 73 },
              { unionName: "DAKSHIN DIGHALDI", unionCode: 80 },
              { unionName: "VEDURIA", unionCode: 87 },
              { unionName: "BHELU MIAH", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "BURHANUDDIN",
            subdistrictCode: 21,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BARA MANIKA", unionCode: 16 },
              { unionName: "DEULA", unionCode: 19 },
              { unionName: "GANGAPUR", unionCode: 28 },
              { unionName: "HASSAN NAGAR", unionCode: 38 },
              { unionName: "KACHIA", unionCode: 47 },
              { unionName: "KUTBA", unionCode: 57 },
              { unionName: "PAKSHIA", unionCode: 66 },
              { unionName: "SACHRA", unionCode: 76 },
              { unionName: "TABGI", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "CHAR FASSON",
            subdistrictCode: 25,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ABU BAKARPUR", unionCode: 11 },
              { unionName: "ABDULLAPUR", unionCode: 13 },
              { unionName: "ADHAKHA NAZRUL NAGAR", unionCode: 14 },
              { unionName: "AMINABAD", unionCode: 15 },
              { unionName: "AHAMMADPUR", unionCode: 17 },
              { unionName: "ASLAMPUR", unionCode: 19 },
              { unionName: "CHAR KALMI", unionCode: 28 },
              { unionName: "CHAR MADRAS", unionCode: 38 },
              { unionName: "CHAR MANIKA", unionCode: 47 },
              { unionName: "DHAL CHAR", unionCode: 51 },
              { unionName: "EWAJPUR", unionCode: 53 },
              { unionName: "HAZARIGANJ", unionCode: 57 },
              { unionName: "JAHANPUR", unionCode: 63 },
              { unionName: "JINNAGHAR", unionCode: 66 },
              { unionName: "CHAR KUKRI MUKRI", unionCode: 70 },
              { unionName: "MUJIBNAGAR", unionCode: 73 },
              { unionName: "NILKAMAL", unionCode: 76 },
              { unionName: "NURABAD", unionCode: 85 },
              { unionName: "RASULPUR", unionCode: 88 },
              { unionName: "OMARPUR", unionCode: 92 },
              { unionName: "OSMANGANJ", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "DAULAT KHAN",
            subdistrictCode: 29,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHABANIPUR", unionCode: 16 },
              { unionName: "CHAR KHALIFA", unionCode: 19 },
              { unionName: "CHAR PATA", unionCode: 28 },
              { unionName: "HAJIPUR", unionCode: 38 },
              { unionName: "MADANPUR", unionCode: 47 },
              { unionName: "MEDUA", unionCode: 57 },
              { unionName: "UTTAR JOYNAGAR", unionCode: 76 },
              { unionName: "DAKSHIN JOYNAGAR", unionCode: 85 },
              { unionName: "SAIDPUR", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "LALMOHAN",
            subdistrictCode: 54,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-10", unionCode: 10 },
              { unionName: "WARD NO-11", unionCode: 11 },
              { unionName: "WARD NO-12", unionCode: 12 },
              { unionName: "BADARPUR", unionCode: 16 },
              { unionName: "CHAR BHUTA", unionCode: 19 },
              { unionName: "DHALI GAURNAGAR", unionCode: 28 },
              { unionName: "FARAZGANJ", unionCode: 38 },
              { unionName: "KALMA", unionCode: 47 },
              { unionName: "LALMOHAN", unionCode: 57 },
              { unionName: "LORD HARDINJE", unionCode: 66 },
              { unionName: "PASCHIM CHAR UMED", unionCode: 70 },
              { unionName: "RAMAGANJ", unionCode: 76 }
            ]
          },
          {
            subdistrictName: "MANPURA",
            subdistrictCode: 65,
            unions: [
              { unionName: "DAKSHIN SAKUCHIA", unionCode: 21 },
              { unionName: "HAJIRHAT", unionCode: 23 },
              { unionName: "MANPURA", unionCode: 47 },
              { unionName: "UTTAR SAKUCHIA", unionCode: 71 }
            ]
          },
          {
            subdistrictName: "TAZUMUDDIN",
            subdistrictCode: 91,
            unions: [
              { unionName: "BARA MALANCHA", unionCode: 19 },
              { unionName: "CHANCHRA", unionCode: 38 },
              { unionName: "CHANDPUR", unionCode: 57 },
              { unionName: "SONAPUR", unionCode: 76 },
              { unionName: "SHAMBHUPUR", unionCode: 85 }
            ]
          }
        ]
      },
      {
        districtName: "JHALOKATI",
        districtCode: 42,
        subdistricts: [
          {
            subdistrictName: "JHALOKATI SADAR",
            subdistrictCode: 40,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BASANDA", unionCode: 17 },
              { unionName: "BINOYKATI", unionCode: 19 },
              { unionName: "GABKHAN DHANSIRI", unionCode: 28 },
              { unionName: "GABHA RAMCHANDRAPUR", unionCode: 38 },
              { unionName: "KEORA", unionCode: 57 },
              { unionName: "NATHULLABAD", unionCode: 63 },
              { unionName: "KIRTIPASHA", unionCode: 66 },
              { unionName: "NABAGRAM", unionCode: 76 },
              { unionName: "PONABALIA", unionCode: 85 },
              { unionName: "SEKHERHAT", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "KANTHALIA",
            subdistrictCode: 43,
            unions: [
              { unionName: "AMUA", unionCode: 15 },
              { unionName: "AWRABUNIA", unionCode: 31 },
              { unionName: "CHENCHRI RAMPUR", unionCode: 47 },
              { unionName: "KANTHALIA", unionCode: 63 },
              { unionName: "PATKHALGHATA", unionCode: 79 },
              { unionName: "SAULAJALIA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NALCHITY",
            subdistrictCode: 73,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHARABPASHA", unionCode: 13 },
              { unionName: "DAPDAPIA", unionCode: 15 },
              { unionName: "KULKATI", unionCode: 21 },
              { unionName: "KUSANGHAL", unionCode: 31 },
              { unionName: "MOLLAHAT", unionCode: 36 },
              { unionName: "MAGAR", unionCode: 42 },
              { unionName: "NACHAN MOHAL", unionCode: 52 },
              { unionName: "RANAPASHA", unionCode: 73 },
              { unionName: "SIDDHAKATI", unionCode: 84 },
              { unionName: "SUBIDPUR", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "RAJAPUR",
            subdistrictCode: 84,
            unions: [
              { unionName: "BARAMAIA", unionCode: 13 },
              { unionName: "GALUA", unionCode: 27 },
              { unionName: "MATHBARI", unionCode: 40 },
              { unionName: "RAJAPUR", unionCode: 54 },
              { unionName: "SATURIA", unionCode: 67 },
              { unionName: "SUKTAGARH", unionCode: 81 }
            ]
          }
        ]
      },
      {
        districtName: "PATUAKHALI",
        districtCode: 78,
        subdistricts: [
          {
            subdistrictName: "BAUPHAL",
            subdistrictCode: 38,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ADABARIA", unionCode: 10 },
              { unionName: "BAGA", unionCode: 11 },
              { unionName: "BAUPHAL", unionCode: 29 },
              { unionName: "CHANDRADWIP", unionCode: 32 },
              { unionName: "DASPARA", unionCode: 35 },
              { unionName: "DHULIA", unionCode: 41 },
              { unionName: "KANCHI PARA", unionCode: 47 },
              { unionName: "KALAIYA", unionCode: 53 },
              { unionName: "KALISURI", unionCode: 59 },
              { unionName: "KANAKDIA", unionCode: 65 },
              { unionName: "KESHABPUR", unionCode: 71 },
              { unionName: "MADANPURA", unionCode: 77 },
              { unionName: "NOAMALA", unionCode: 83 },
              { unionName: "NAZIRPUR", unionCode: 89 },
              { unionName: "SURJYAMANI", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "DASHMINA",
            subdistrictCode: 52,
            unions: [
              { unionName: "ALIPUR", unionCode: 10 },
              { unionName: "BAHRAMPUR", unionCode: 21 },
              { unionName: "BANSHBARIA", unionCode: 31 },
              { unionName: "BETAGI SANKIPURA", unionCode: 42 },
              { unionName: "CHAR BORHAN", unionCode: 47 },
              { unionName: "DASHMINA", unionCode: 52 },
              { unionName: "RANGOPALDI", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "DUMKI",
            subdistrictCode: 55,
            unions: [
              { unionName: "ANGARIA", unionCode: 19 },
              { unionName: "LEBUKHALI", unionCode: 47 },
              { unionName: "MURADIA", unionCode: 81 },
              { unionName: "PANGASHIA", unionCode: 88 },
              { unionName: "SREERAMPUR", unionCode: 93 }
            ]
          },
          {
            subdistrictName: "GALACHIPA",
            subdistrictCode: 57,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMKHOLA", unionCode: 11 },
              { unionName: "BAKULBARIA", unionCode: 22 },
              { unionName: "CHAR BISWAS", unionCode: 37 },
              { unionName: "CHAR KAJAL", unionCode: 39 },
              { unionName: "CHIKNIKANDI", unionCode: 50 },
              { unionName: "DAKUA", unionCode: 55 },
              { unionName: "GALACHIPA", unionCode: 67 },
              { unionName: "GAZALIA", unionCode: 69 },
              { unionName: "GOLKHALI", unionCode: 72 },
              { unionName: "KALAGACHHIA", unionCode: 75 },
              { unionName: "PANPATTY", unionCode: 78 },
              { unionName: "RATANDI TALTALI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "KALAPARA",
            subdistrictCode: 66,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "WARD NO-01", unionCode: 61 },
              { unionName: "WARD NO-02", unionCode: 62 },
              { unionName: "WARD NO-03", unionCode: 63 },
              { unionName: "WARD NO-04", unionCode: 64 },
              { unionName: "WARD NO-05", unionCode: 65 },
              { unionName: "WARD NO-06", unionCode: 66 },
              { unionName: "WARD NO-07", unionCode: 67 },
              { unionName: "WARD NO-08", unionCode: 68 },
              { unionName: "WARD NO-09", unionCode: 69 },
              { unionName: "BALIATALI", unionCode: 10 },
              { unionName: "CHAKAMAIYA", unionCode: 11 },
              { unionName: "CHAMPAPUR", unionCode: 13 },
              { unionName: "DALBUGANJ", unionCode: 15 },
              { unionName: "MOHIPUR", unionCode: 23 },
              { unionName: "DHULASAR", unionCode: 29 },
              { unionName: "LALUA", unionCode: 35 },
              { unionName: "LATA CHAPLI", unionCode: 47 },
              { unionName: "DHANKHALI", unionCode: 55 },
              { unionName: "MITHAGANJ", unionCode: 59 },
              { unionName: "NILGANJ", unionCode: 71 },
              { unionName: "TIAKHALI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "MIRZAGANJ",
            subdistrictCode: 76,
            unions: [
              { unionName: "AMRAGACHHIA", unionCode: 13 },
              { unionName: "DEULI SUBIDKHALI", unionCode: 27 },
              { unionName: "KAKRABUNIA", unionCode: 40 },
              { unionName: "MADHABKHALI", unionCode: 54 },
              { unionName: "MAJIDBARI", unionCode: 67 },
              { unionName: "MIRZAGANJ", unionCode: 81 }
            ]
          },
          {
            subdistrictName: "PATUAKHALI SADAR",
            subdistrictCode: 95,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AULIAPUR", unionCode: 10 },
              { unionName: "BADARPUR", unionCode: 11 },
              { unionName: "BHURIA", unionCode: 12 },
              { unionName: "CHHOTA BIGHAI", unionCode: 13 },
              { unionName: "ITABARIA", unionCode: 20 },
              { unionName: "JAINKATI", unionCode: 27 },
              { unionName: "BARA BIGHAI", unionCode: 31 },
              { unionName: "KALIKAPUR", unionCode: 33 },
              { unionName: "KAMALAPUR", unionCode: 40 },
              { unionName: "LOHALIA", unionCode: 54 },
              { unionName: "LAUKATI", unionCode: 61 },
              { unionName: "MADARBUNIA", unionCode: 67 },
              { unionName: "MARICHBUNIA", unionCode: 74 },
              { unionName: "MAUKARAN", unionCode: 78 }
            ]
          },
          {
            subdistrictName: "RANGABALI",
            subdistrictCode: 97,
            unions: [
              { unionName: "BARA BAISDIA", unionCode: 27 },
              { unionName: "CHALITABUNIA", unionCode: 32 },
              { unionName: "CHAR MONTAZ", unionCode: 34 },
              { unionName: "CHHOTA BAISDIA", unionCode: 44 },
              { unionName: "RANGABALI", unionCode: 83 }
            ]
          }
        ]
      },
      {
        districtName: "PIROJPUR",
        districtCode: 79,
        subdistricts: [
          {
            subdistrictName: "BHANDARIA",
            subdistrictCode: 14,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "BHITABARIA", unionCode: 23 },
              { unionName: "DHAOA", unionCode: 35 },
              { unionName: "GAURIPUR", unionCode: 47 },
              { unionName: "IKRI", unionCode: 59 },
              { unionName: "NUDMULLA", unionCode: 71 },
              { unionName: "TELIKHALI", unionCode: 83 }
            ]
          },
          {
            subdistrictName: "KAWKHALI",
            subdistrictCode: 47,
            unions: [
              { unionName: "AMRAJURI", unionCode: 15 },
              { unionName: "CHIRA PARA PARSATURIA", unionCode: 31 },
              { unionName: "KAWKHALI", unionCode: 47 },
              { unionName: "SAYNA RAGHUNATHPUR", unionCode: 63 },
              { unionName: "SHIALKATI", unionCode: 79 }
            ]
          },
          {
            subdistrictName: "MATHBARIA",
            subdistrictCode: 58,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "AMRAGACHHIA", unionCode: 15 },
              { unionName: "BARA MACHHUA", unionCode: 17 },
              { unionName: "BETMORE RAJPARA", unionCode: 25 },
              { unionName: "DAUDKHALI", unionCode: 34 },
              { unionName: "DHANISAFA", unionCode: 43 },
              { unionName: "GULISHAKHALI", unionCode: 51 },
              { unionName: "MATHBARIA", unionCode: 60 },
              { unionName: "MIRUKHALI", unionCode: 69 },
              { unionName: "SAPLEZA", unionCode: 77 },
              { unionName: "TIKIKATA", unionCode: 86 },
              { unionName: "TUSHKHALI", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NAZIRPUR",
            subdistrictCode: 76,
            unions: [
              { unionName: "DIRGHA UNION", unionCode: 10 },
              { unionName: "DEULBARI DOBRA", unionCode: 21 },
              { unionName: "KOLARDOANIA", unionCode: 25 },
              { unionName: "MALIKHALI", unionCode: 31 },
              { unionName: "MATIBHANGA", unionCode: 42 },
              { unionName: "NAZIRPUR", unionCode: 52 },
              { unionName: "SEKHMATIA", unionCode: 63 },
              { unionName: "SHANKHARIKATI", unionCode: 73 },
              { unionName: "SREERAMKATI UNION", unionCode: 84 }
            ]
          },
          {
            subdistrictName: "PIROJPUR SADAR",
            subdistrictCode: 80,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "DURGAPUR", unionCode: 17 },
              { unionName: "KADAMTALA UNION", unionCode: 25 },
              { unionName: "KALAKHALI", unionCode: 34 },
              { unionName: "SHANKARPASHA", unionCode: 69 },
              { unionName: "SARIKTALA DUMRITALA", unionCode: 77 },
              { unionName: "SIKDAR MALLIK", unionCode: 86 },
              { unionName: "TONA", unionCode: 94 }
            ]
          },
          {
            subdistrictName: "NESARABAD (SWARUPKATI)",
            subdistrictCode: 87,
            unions: [
              { unionName: "WARD NO-01", unionCode: 1 },
              { unionName: "WARD NO-02", unionCode: 2 },
              { unionName: "WARD NO-03", unionCode: 3 },
              { unionName: "WARD NO-04", unionCode: 4 },
              { unionName: "WARD NO-05", unionCode: 5 },
              { unionName: "WARD NO-06", unionCode: 6 },
              { unionName: "WARD NO-07", unionCode: 7 },
              { unionName: "WARD NO-08", unionCode: 8 },
              { unionName: "WARD NO-09", unionCode: 9 },
              { unionName: "ATGHAR KURIANA UNION", unionCode: 17 },
              { unionName: "BALDIA", unionCode: 19 },
              { unionName: "DAIHARI", unionCode: 28 },
              { unionName: "GUAREKHA UNION", unionCode: 38 },
              { unionName: "JALABARI", unionCode: 47 },
              { unionName: "SAMUDAYKATI", unionCode: 57 },
              { unionName: "SARENGKATI", unionCode: 66 },
              { unionName: "SOHAGDAL", unionCode: 76 },
              { unionName: "SUTIAKATI", unionCode: 85 },
              { unionName: "NESARABAD (SWARUPKATI)", unionCode: 95 }
            ]
          },
          {
            subdistrictName: "ZIANAGAR",
            subdistrictCode: 90,
            unions: [
              { unionName: "BALI PARA", unionCode: 15 },
              { unionName: "PARERHAT", unionCode: 43 },
              { unionName: "PATTASHI", unionCode: 51 }
            ]
          }
        ]
      }
    ]
  }
];
