import React from "react";
import {
  Center,
  Heading,
  Stack,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";

interface DeliveryPolicyComponentProps {}

export const DeliveryPolicyComponent: React.FC<DeliveryPolicyComponentProps> = (
  props: DeliveryPolicyComponentProps
) => {
  return (
    <Stack>
      <Heading>Delivery Policy</Heading>
      <Stack textAlign={"justify"}>
        <UnorderedList>
          <ListItem>
            Upon successful payment, your appointment with the chosen healthcare
            provider will be confirmed instantly.
          </ListItem>
          <ListItem>
            A confirmation email or SMS will be sent to you with the appointment
            details.
          </ListItem>
          <ListItem>
            It is your responsibility to ensure that the contact information
            provided is accurate.
          </ListItem>
          <ListItem>
            No physical goods are delivered; the service is considered delivered
            once the appointment is confirmed.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Stack>
  );
};
